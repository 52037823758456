import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { getSessionHistory, getTeamInfo } from '../api/adminPanel';
import SessionCard from '../components/pages/sessionHistory/sessionCard';
import { Breadcrumbs, Button, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../styles/globalStyles';
import { ReactComponent as DotDivider } from '../images/dot_divider.svg';
import SessionCreateModal from '../components/pages/adminPanel/sessionCreateModal';
import { useRecoilState } from 'recoil';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';
import { Loader } from '../components/loaders/loader';
import { userInfoAtomArr } from '../recoil/atoms/userAtom';
import { getUserData } from '../api/auth';

export const LoaderWrapper = styled('div')(() => ({
    width: '100%',
    height: 300,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StyledBreadcrumbs = styled(Breadcrumbs)({
    '& a.light': {
        opacity: 0.4,
        color: colors.darkBlue,
    },

    '& a.transparent': {
        opacity: 0.4,
        color: colors.white,
    },

    '& a.current.light': {
        opacity: 1,
        color: colors.main,
    },

    '& a.current.transparent': {
        opacity: 1,
        color: colors.white,
    },
});

const List = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 17,
});

const EmptyMessage = styled(Typography)({
    alignSelf: 'flex-start',
    textAlign: 'center',
    fontSize: 21,
    opacity: 0.6,
});

const MessageWrap = styled('div')({
    width: '100%',
    flexGrow: 2,
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const Header = styled(Typography)({
    fontSize: 28,
    paddingLeft: 15,
    fontWeight: 600,
});

const StyledButton = styled(Button)({
    width: '70%',
    padding: '14px 17px',
});

const SessionHistory = () => {
    const { teamId, companyId } = useParams();
    const navigate = useNavigate();
    const [current, setCurrent] = useState({});
    const [history, setHistory] = useState([]);
    const [futureSessions, setFutureSessions] = useState([]);
    const [sessionModalData, setSessionModalData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [breadcrumbsData, setBreadcrumbsData] = useState({});
    const [, setAdminUser] = useRecoilState(AdminUserAtom);
    const [, setUserData] = useRecoilState(userInfoAtomArr);
    const [areSessionLoading, setAreSessionLoading] = useState(true);

    useEffect(() => {
        setAreSessionLoading(true);

        getSessionHistory(teamId)
            .then((r) => {
                const sessionCurrent = r.data.current;
                const sessionHistory = r.data.history;
                const sessionFuture = r.data.future;

                setCurrent(sessionCurrent);
                setHistory(sessionHistory);
                setFutureSessions(sessionFuture);
                setAreSessionLoading(false);
            })
            .catch((err) => {
                console.error('Ошибка получения истории сессии', err);
                setAreSessionLoading(false);
            });
    }, []);

    useEffect(() => {
        getTeamInfo(teamId)
            .then((r) => {
                setBreadcrumbsData(r.data);
            })
            .catch((err) => {
                console.error('Ошибка получения информации о команде', err);
            });
    }, []);

    const updateUserData = useCallback(() => {
        getUserData()
            .then((res) => {
                var newInfo = {
                    username: res.data.username,
                    firstName: res.data.first_name,
                    userId: res.data.id,
                    avatar: '',
                    teamName: res.data.team.name,
                    teamId: res.data.team.id,
                    areValuesApproved: res.data.team.are_values_approved,
                    isSessionCreated: res.data.team.is_session_created,
                };

                setUserData(newInfo);

                localStorage.setItem('user-id', res.data.id);
                localStorage.setItem('team-id', res.data.team.id);
                localStorage.setItem('username', res.data.username);

                if (res.data.is_staff || res.data.app_admin_panel === true) {
                    setAdminUser({
                        isStaff: res.data.is_staff,
                        appAdminPanel: res.data.app_admin_panel,
                        areValuesApproved: res.data.team.are_values_approved,
                        isSessionCreated: res.data.team.is_session_created,
                        avaliableSteps: res.data.team.avaliable_steps,
                    });
                }
            })
            .catch((error) => {
                console.error('Ошибка получения пользователя', error);
            });
    }, [setUserData, setAdminUser]);

    const handleClickCard = (e, data) => {
        e.preventDefault();
        setSessionModalData(data);
        setModalOpen(true);
    };

    function handleDeleteSession(id) {
        setHistory((prev) => prev.filter((session) => session.id !== id));
        setCurrent((prev) => prev.filter((session) => session.id !== id));
        setFutureSessions((prev) => prev.filter((session) => session.id !== id));

        setModalOpen(false);
    }

    function handleAdminPanelButtonClick() {
        localStorage.removeItem('user-id-for-admin-panel');
        setAdminUser((prev) => ({ ...prev, userIdForAdminPanel: null }));
        navigate('/admin');
    }

    function handleCloseSessionPopup(response) {
        if (response) {
            const { data: session } = response;

            const sessionStart = new Date(session.start);
            const sessionEnd = new Date(session.end);
            const now = new Date();

            function updateSession(sessionToUpdate) {
                return sessionToUpdate.id === session.id ? session : sessionToUpdate;
            }

            if (sessionStart < now && sessionEnd > now) {
                setCurrent(current.map(updateSession));
            } else if (sessionStart < now && sessionEnd < now) {
                setHistory(history.map(updateSession));
            } else if (sessionStart > now && sessionEnd > now) {
                setFutureSessions(futureSessions.map(updateSession));
            }

            updateUserData();
        }

        setModalOpen(false);
    }

    function handleCreateSession(response) {
        if (response) {
            const { data: session } = response;

            const sessionStart = new Date(session.start);
            const sessionEnd = new Date(session.end);
            const now = new Date();

            if (sessionStart < now && sessionEnd > now) {
                setCurrent([session, ...current]);
            } else if (sessionStart < now && sessionEnd < now) {
                setHistory([session, ...history]);
            } else if (sessionStart > now && sessionEnd > now) {
                setFutureSessions([session, ...futureSessions]);
            }

            updateUserData();
        }

        setModalOpen(false);
    }

    function handleCreateSessionClick(e) {
        e.preventDefault();
        setSessionModalData({});
        setModalOpen(true);
    }

    if (areSessionLoading) {
        return (
            <Layout link={`/admin/company/${companyId}/team/${teamId}`}>
                <LoaderWrapper>
                    <Loader size="large" color={colors.main} />
                </LoaderWrapper>
            </Layout>
        );
    }

    return (
        <Layout link={`/admin/company/${companyId}/team/${teamId}`}>
            <StyledBreadcrumbs separator={<DotDivider style={{ width: 2, height: 2 }} />}>
                <Link className="light" onClick={handleAdminPanelButtonClick}>
                    Админ панель
                </Link>
                <Link>{breadcrumbsData?.team?.name}</Link>
                <Link className="current">Сессии</Link>
            </StyledBreadcrumbs>
            {current.length > 0 ? (
                <>
                    <Header>Актуальные сессии</Header>
                    <List>
                        {current.map((sessionData) => (
                            <SessionCard data={sessionData} active={true} onClick={handleClickCard} />
                        ))}
                    </List>
                </>
            ) : null}
            {futureSessions.length > 0 ? (
                <>
                    <Header>Будущие сессии</Header>
                    <List>
                        {futureSessions.map((sessionData) => (
                            <SessionCard data={sessionData} onClick={handleClickCard} />
                        ))}
                    </List>
                </>
            ) : null}
            {history.length > 0 ? (
                <>
                    <Header>Прошлые сессии</Header>
                    <List>
                        {history.map((sessionData) => (
                            <SessionCard data={sessionData} onClick={handleClickCard} />
                        ))}
                    </List>
                </>
            ) : null}
            {Object.keys(current).length === 0 && history.length === 0 ? (
                <MessageWrap>
                    <EmptyMessage>Кажется, у этой команды ещё не прошло ни одной сессии</EmptyMessage>

                    <StyledButton variant={'colored'} onClick={handleCreateSessionClick}>
                        Создать сессию
                    </StyledButton>
                </MessageWrap>
            ) : null}
            <SessionCreateModal
                open={modalOpen}
                sessionData={sessionModalData}
                teamId={teamId}
                onClose={handleCloseSessionPopup}
                onDelete={handleDeleteSession}
                onCreate={handleCreateSession}
            />
        </Layout>
    );
};

export default SessionHistory;
