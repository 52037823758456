export function isTodayOrYesterdayBefore7am(date) {
    const currentDate = new Date();
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    const dateToCheck = new Date(date);

    if (dateToCheck.toDateString() === currentDate.toDateString()) {
        return true;
    }

    if (dateToCheck.toDateString() === yesterday.toDateString() && currentDate.getHours() < 7) {
        return true;
    }

    return false;
}
