import React, { useState } from 'react';
import {
    Avatar,
    AvatarGroup,
    Box,
    Card,
    CardActionArea,
    IconButton,
    InputBase,
    styled,
    Typography,
} from '@mui/material';
import { inputBaseStyles, InputWrap } from './inputs/decoratedInput';
import { colors } from '../styles/globalStyles';
import { ReactComponent as ArrowRight } from '../images/arrow_right.svg';
import { ReactComponent as SearchIcon } from '../images/search.svg';
import SearchUserProgress from './searchUserProgress';
import { useNavigate } from 'react-router-dom';
import RootModal from './modals/rootModal';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userInfoAtomArr } from '../recoil/atoms/userAtom';
import { searchModalOpenAtom } from '../recoil/atoms/mySessionPageAtoms';
import { teamMembersAtom } from '../recoil/atoms/teamMembersAtom';

const Header = styled('header')({
    padding: 17,
    borderBottom: '0.33px solid rgba(0, 0, 0, 0.3)',
});

const Title = styled(Typography)({
    fontWeight: 800,
    fontSize: 52,
    lineHeight: '100%',
});

const StyledAvatarGroup = styled(AvatarGroup)({
    '& div:first-child': {
        backgroundColor: 'unset',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '100%',
        color: colors.main,
    },

    '& div': {
        width: 32,
        height: 32,
    },
});

const MeDiv = styled(CardActionArea)({
    marginBottom: 17,
    paddingInline: '17px 10px',
    height: 72,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,

    background: 'rgba(59, 122, 246, 0.07)',
    borderRadius: 23,
});

const TypographyMe = styled(Typography)({
    marginRight: 'auto',

    fontWeight: 700,
    fontSize: 20,
    lineHeight: '125%',
});

const MeButton = styled(IconButton)({
    marginLeft: 30,

    width: 52,
    height: 52,

    background: colors.transparentMain,
    borderRadius: 13,
});

const SearchField = styled('div')({
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    gap: 2,
});

const SearchFor = styled(Typography)({
    paddingInline: 10,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '100%',

    '& span': {
        padding: 4,
        fontSize: 14,
        background: 'rgba(59, 122, 246, 0.14)',
        color: colors.main,
        borderRadius: 6,
    },
});

const SearchModal = () => {
    const { avatar } = useRecoilValue(userInfoAtomArr);
    const dropdownOptions = [{ title: 'Team' }, { title: 'User' }];
    const [value, setValue] = useState('');
    const navigate = useNavigate();
    const [searchModalOpen, setSearchModalOpen] = useRecoilState(searchModalOpenAtom);

    const membersArr = useRecoilValue(teamMembersAtom);

    const breadcrumbsData = [
        {
            uri: '',
            label: 'Мои сессии',
        },
        {
            uri: '',
            label: 'Поиск',
        },
    ];
    return (
        <RootModal open={searchModalOpen} onClose={() => setSearchModalOpen(false)} breadcrumbsData={breadcrumbsData}>
            <Header>
                <Box mb={'27px'} px={'10px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Title>Поиск</Title>
                    {/* <StyledAvatarGroup max={5}>
                        <Avatar src={AvatarIcon} alt={'avatar'}/>
                        <Avatar src={AvatarIcon} alt={'avatar'}/>
                        <Avatar src={AvatarIcon} alt={'avatar'}/>
                        <Avatar src={AvatarIcon} alt={'avatar'}/>
                        <Avatar src={AvatarIcon} alt={'avatar'}/>
                        <Avatar src={AvatarIcon} alt={'avatar'}/>
                        <Avatar src={AvatarIcon} alt={'avatar'}/>
                    </StyledAvatarGroup> */}
                </Box>
                <Card>
                    <MeDiv onClick={() => navigate(`/teammate-behaviors/${localStorage.getItem('user-id')}`)}>
                        <Avatar src={avatar} alt={'avatar'} />
                        <TypographyMe>Себе</TypographyMe>
                        <MeButton>
                            <ArrowRight fill={colors.main} />
                        </MeButton>
                    </MeDiv>
                </Card>
                <SearchField>
                    <InputWrap
                        sx={{
                            paddingInline: '10px',
                            height: 52,
                            border: '2px solid rgba(59, 122, 246, 0.24)',
                            flexGrow: 1,
                        }}
                    >
                        <SearchIcon />
                        <InputBase
                            placeholder={'Or search for teammates'}
                            sx={{ ...inputBaseStyles, color: colors.darkBlue }}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </InputWrap>
                    {/*<Dropdown options={dropdownOptions} width={100} height={52}/>*/}
                </SearchField>
                <SearchFor>
                    Искать по: <span>Команде</span>
                </SearchFor>
            </Header>
            <Box padding={'17px'} display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={'2px'}>
                {membersArr.map((member) => (
                    <SearchUserProgress userData={member} key={`team-member-${member.id}`} />
                ))}
            </Box>
        </RootModal>
    );
};

export default SearchModal;
