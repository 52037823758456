import { styled } from '@mui/system';
import { colors } from '../styles/globalStyles';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const PageWrapper = styled('div')({
    background: colors.white,
    padding: '2px 17px 17px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '0 1 auto',
    gap: 17,
    minHeight: '100vh',
    maxWidth: '390px',
    margin: '0 auto',
});

const ContentWrapper = styled('div')({
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
});

const Title = styled(Typography)({
    lineHeight: 1,
    letterSpacing: '0.01rem',
    fontWeight: 800,
    fontSize: 30,
    textAlign: 'center',
    color: colors.darkBlue,
    marginLeft: 'auto',
    marginRight: 'auto',
});

const Button = styled(Link)({
    background: colors.transparentWhite,
    borderRadius: 23,
    color: 'white',
    padding: '18px 8px',
    flex: 1,
    textDecoration: 'none',

    '&.light': {
        background: colors.main,
        color: colors.white,
    },
});

const TrialEndPage = () => {
    return (
        <PageWrapper>
            <ContentWrapper>
                <Title>Ознакомительный период завершён</Title>
                <Typography variant={'body1'}>
                    Ознакомительный период работы приложения завершён. <br />
                    Для получения полноценного доступа к трекеру - свяжитесь с нами
                </Typography>
                <Button className={'light'} to={'https://t.me/DmitryShiryaev'}>
                    <Typography variant={'body1'} sx={{ color: 'white' }}>
                        Получить доступ
                    </Typography>
                </Button>
            </ContentWrapper>
        </PageWrapper>
    );
};

export default TrialEndPage;
