import { atom } from 'recoil';

export const timeHoursAtom = atom({
    key: 'timeHours',
    default: '',
});

export const timeDateAtom = atom({
    key: 'timeDate',
    default: '',
});

export const progressDataAtom = atom({
    key: 'progressData',
    default: {},
});

export const avgBehaviorsRateAtom = atom({
    key: 'avgBehaviorsRate',
    default: {},
});
