import { Breadcrumbs, Link, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Layout from '../components/Layout';
import Selector from '../components/pages/trackerPage/selector';
import SliderCard from '../components/pages/trackerPage/sliderCard';
import Timeline from '../components/pages/trackerPage/timeline';
import useElementOnScreen from '../components/pages/trackerPage/useElementOnScreen';
import { kssTrackerDataAtom, kssTrackerDataSelector, timeLineDataAtom } from '../recoil/atoms/trackerPageAtoms';
import { ReactComponent as DotDivider } from '../images/dot_divider.svg';
import { bgColors, colors } from '../styles/globalStyles';
import FeedbackModal from '../components/feedbackModal';
import RateModal from '../components/modals/rateModal';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';
import {
    kssDataAtom,
    userForAdminPanelAtom,
    userInfoAtomArr,
    userTrackerForAdminPanelAtom,
} from '../recoil/atoms/userAtom';

const TrackerWrap = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'flex-start',
    gap: 17,
});

export const getColorType = (type) => {
    if (!type) return null;
    var lowerCaseType = type.toLowerCase();

    switch (lowerCaseType) {
        case 'keep':
            return bgColors.purpleBlue;
        case 'stop':
            return bgColors.orangePink;
        case 'start':
            return bgColors.green;
    }
};

const Header = styled('div')({
    padding: '0 10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
});

const Slider = styled('div')({
    height: '75vh',
    display: 'flex',
    gap: 6,
    overflow: 'auto',
    scrollSnapType: 'x mandatory',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    margin: '0 -17px',

    // 'div:first-child': {
    //     marginLeft: 17,
    // },

    // '&:nth-last-of-type(1)': {
    //     paddingRight: '17px',
    // },
});

const breadcrumbsData = [
    {
        uri: '/main',
        label: 'Главная',
    },
    {
        uri: '',
        label: 'Оценить',
    },
];

const getTimelineKSSByDay = (timeLineData, cardId) => {
    const selectedDayKSS = timeLineData.periods.reduce((acc, period) => {
        if (acc) return acc;

        return period.days.reduce((innerAcc, day) => {
            if (innerAcc) return innerAcc;

            if (day.date.startsWith(cardId)) {
                return day.scores;
            }

            return null;
        }, null);
    }, null);

    return selectedDayKSS;
};

const TrackerPage = () => {
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.7,
    };

    const navigate = useNavigate();
    const sliderRef = useRef(null);
    const [selectedType, setSelectedType] = useState(null);
    const [kssTrackerData2, setKssTrackerData] = useRecoilState(kssTrackerDataAtom);
    const setData = useSetRecoilState(kssTrackerDataSelector);
    const [open, setOpen] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);

    const [keepRef, isKeepVisible] = useElementOnScreen(options);
    const [stopRef, isStopVisible] = useElementOnScreen(options);
    const [startRef, isStartVisible] = useElementOnScreen(options);
    const [canScore, setCanScore] = useState(false);
    const [selectedDateKSS, setSelectedDateKSS] = useState(null);

    const userInfo = useRecoilValue(userInfoAtomArr);
    const timeLineData = useRecoilValue(timeLineDataAtom);

    const adminUser = useRecoilValue(AdminUserAtom);
    const userTrackerForAdminPanel = useRecoilValue(userTrackerForAdminPanelAtom);
    const userForAdminPanel = useRecoilValue(userForAdminPanelAtom);
    const userKssData = useRecoilValue(kssDataAtom);

    const kssTrackerData = adminUser.userIdForAdminPanel ? userTrackerForAdminPanel : userKssData;
    const userData = adminUser.userIdForAdminPanel ? userForAdminPanel : userInfo;

    useEffect(() => {
        if (selectedType) {
            const targetIndex = kssTrackerData.findIndex((i) => i?.id === selectedType.id);
            const newData = Object.assign([], kssTrackerData);
            newData[targetIndex] = selectedType.score?.value === -1 ? { ...selectedType, score: null } : selectedType;
            setData(newData);
        }
    }, [selectedType]);

    useEffect(() => {
        const selectedDayKSS = getTimelineKSSByDay(timeLineData, new Date().toISOString().substring(0, 10));
        setSelectedDateKSS(selectedDayKSS);
    }, []);

    if (kssTrackerData.length === 0) return <></>;

    const kssVisible = (data) => {
        const visibleKSSList = data.filter((item) => {
            return item && !item.skip;
        });

        return visibleKSSList;
    };

    function setSelectedKss(behaviorData) {
        setSelectedType(behaviorData);

        // Обновляю текст обратной связи в текущих поведениях
        setKssTrackerData((prev) => {
            return prev.map((behavior) =>
                behavior.id === behaviorData.id ? { ...behavior, feedback_text: behaviorData.feedback_text } : behavior,
            );
        });
    }

    function handleDateBlockClick(cardId) {
        const selectedDayKSS = getTimelineKSSByDay(timeLineData, cardId);
        setSelectedDateKSS(selectedDayKSS);
    }

    return (
        <Layout link={'/main'} style={{ justifyContent: 'space-between', padding: '0 17px', marginBottom: 0 }}>
            <TrackerWrap>
                <Header>
                    <Breadcrumbs separator={<DotDivider style={{ width: 2, height: 2 }} />}>
                        <Link
                            sx={{ color: colors.darkBlue, opacity: 0.4, cursor: 'pointer' }}
                            onClick={() => navigate('/main')}
                        >
                            Главная
                        </Link>
                        <Link>Ежедневный трекер</Link>
                    </Breadcrumbs>
                    <Selector
                        ioVars={{ isKeepVisible, isStopVisible, isStartVisible }}
                        sliderRef={sliderRef}
                        kssVisible={kssVisible(kssTrackerData)}
                    />
                </Header>
                <Slider ref={sliderRef}>
                    {kssTrackerData.map((item) => {
                        const type = item?.status;
                        let ref;
                        let scores = selectedDateKSS?.[type];

                        switch (type) {
                            case 'keep':
                                ref = keepRef;
                                break;
                            case 'stop':
                                ref = stopRef;
                                break;
                            case 'start':
                                ref = startRef;
                        }

                        return (
                            <SliderCard
                                key={`tracker-page-slide-${type}`}
                                style={{
                                    paddingBottom: '50%',
                                    // marginLeft: index === 0 ? 17 : 0,
                                }}
                                kssTrackerCardData={item}
                                reference={ref}
                                setOpen={setOpen}
                                setOpenInfo={setInfoOpen}
                                setSelected={setSelectedType}
                                scores={scores}
                                canScore={
                                    canScore && new Date(item.created_at).toDateString() !== new Date().toDateString()
                                }
                            />
                        );
                    })}
                </Slider>
            </TrackerWrap>
            <Timeline
                setCanScore={setCanScore}
                userData={userData}
                adminUser={adminUser}
                handleDateBlockClick={handleDateBlockClick}
            />
            <RateModal
                setSelectedData={setSelectedKss}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                selectedType={selectedType}
            />
            {infoOpen ? (
                <FeedbackModal
                    behaviorData={selectedType}
                    open={infoOpen}
                    setIsOpen={setInfoOpen}
                    background={getColorType(selectedType?.status)}
                />
            ) : null}
        </Layout>
    );
};

export default TrackerPage;
