import React, { useEffect, useState } from 'react';
import RootModal from '../../modals/rootModal';
import { Button, styled, TextField, Typography } from '@mui/material';
import { changeUser, createClientLog, createUser, disableUser } from '../../../api/adminPanel';
import { colors } from '../../../styles/globalStyles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AdminUserAtom } from '../../../recoil/atoms/adminPanelAtoms';
import { useNavigate } from 'react-router-dom';
import {
    userForAdminPanelAtom,
    userKssDataForAdminPanelAtom,
    userKssTrackerDataForAdminPanelAtom,
    userTrackerForAdminPanelAtom,
    userValuesForAdminPanelAtom,
} from '../../../recoil/atoms/userAtom';
import { getCardsData } from '../../../api/session';
import { getFeedbackValuesData } from '../../../api/user';
import { getKssTrackerData } from '../../../util/kssTracker';
import { getTeamMembers } from '../../../api/search';
import { teamMembersForAdminPanelAtom } from '../../../recoil/atoms/teamMembersAtom';
import { getKssData } from '../../../util/kssData';

const Wrap = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 17,
    padding: 17,
    justifyContent: 'flex-start',
    height: '100%',
});

const InputLabel = styled(Typography)({
    fontSize: 30,
    lineHeight: '40.8px',
});

const Input = styled(TextField)({
    width: '100%',
});

const InputWrap = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
});

const ButtonsBar = styled('div')({
    display: 'flex',
    gap: 2,
    width: '100%',
    paddingBottom: 17,
});

const SaveButton = styled(Button)({
    width: '50%',
    padding: '14px 17.5px',
    borderRadius: 23,
});

const UserCreateModal = ({ open, onClose, teamId, user }) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [validating, setValidating] = useState(false);

    const [username, setUsername] = useState(user?.username ?? '');
    const [password, setPassword] = useState(user?.password ?? '');
    const [firstName, setFirstName] = useState(user?.firstName ?? '');
    const [lastName, setLastName] = useState(user?.lastName ?? '');
    const [isSuccess, setIsSuccess] = useState(false);

    const [adminUser, setAdminUser] = useRecoilState(AdminUserAtom);
    const [, setUserForAdminPanel] = useRecoilState(userForAdminPanelAtom);
    const [, setUserKssForAdminPanel] = useRecoilState(userKssTrackerDataForAdminPanelAtom);
    const [, setUserValuesForAdminPanel] = useRecoilState(userValuesForAdminPanelAtom);
    const [, setUserTrackerForAdminPanel] = useRecoilState(userTrackerForAdminPanelAtom);
    const [, setTeamMembersForAdminPanel] = useRecoilState(teamMembersForAdminPanelAtom);
    const [, setUserKssDataForAdminPanel] = useRecoilState(userKssDataForAdminPanelAtom);

    useEffect(() => {
        if (user !== null) {
            setUsername(user.username);
            setPassword('8********');
            setFirstName(user.first_name);
            setLastName(user.last_name);
        } else {
            setUsername('');
            setPassword('');
            setFirstName('');
            setLastName('');
        }
    }, [user]);

    useEffect(() => {
        if (open !== true) {
            setIsSuccess(false);
        }
    }, [open]);

    const [errorMessage, setErrorMessage] = useState(null);
    const [errors, setErrors] = useState({
        username: true,
        password: true,
        firstName: true,
        lastName: true,
    });

    const handleSubmit = (e) => {
        setValidating(true);
        const usernameRegEx = /^[a-zA-Zа-яА-Я0-9.@+_-]{1,150}$/;
        const passwordRegEx = /^[A-Za-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\-=/]{8,20}$/;
        const nameRegEx = /^([А-Яа-яA-Za-z]+(-[А-Яа-яA-Za-z]+)*)$/;

        const newErrors = errors;

        newErrors.username = username === '' || !usernameRegEx.test(username);
        newErrors.password = !passwordRegEx.test(password);
        newErrors.firstName = firstName === '' || !nameRegEx.test(firstName);
        newErrors.lastName = lastName === '' || !nameRegEx.test(lastName);

        let errorExist = Object.values(newErrors).includes(true);

        setErrors({ ...newErrors });

        if (!errorExist) {
            if (user !== null) {
                if (password === '8********') {
                    changeUser({
                        id: user.id,
                        username: username,
                        first_name: firstName,
                        last_name: lastName,
                        team: teamId,
                    })
                        .then((res) => {
                            setErrorMessage(null);
                            if (e.target.name === 'save-and-exit') {
                                onClose();
                            } else {
                                setValidating(false);
                            }
                        })
                        .catch((e) => {
                            setErrorMessage('Пользователь с таким username уже существует');
                            setErrors({ ...newErrors, username: true });
                        });
                } else {
                    changeUser({
                        id: user.id,
                        username: username,
                        password: password,
                        first_name: firstName,
                        last_name: lastName,
                        team: teamId,
                    })
                        .then((res) => {
                            setErrorMessage(null);
                            if (e.target.name === 'save-and-exit') {
                                onClose();
                            } else {
                                setValidating(false);
                            }

                            setIsSuccess(true);
                        })
                        .catch((e) => {
                            if (e.response.status === 400) {
                                setErrorMessage('Пользователь с таким username уже существует');
                                setErrors({ ...newErrors, username: true });
                            } else {
                                createClientLog(e);
                                setErrorMessage('Ошибка сети');
                            }
                            setIsSuccess(false);
                        });
                }
            } else {
                createUser({
                    username: username,
                    password: password,
                    first_name: firstName,
                    last_name: lastName,
                    team: teamId,
                })
                    .then((r) => {
                        setErrorMessage(null);
                        if (e.target.name === 'save-and-exit') {
                            onClose();
                        } else {
                            setValidating(false);
                            setErrors({
                                username: false,
                                password: false,
                                firstName: false,
                                lastName: false,
                            });
                            setUsername('');
                            setPassword('');
                            setFirstName('');
                            setLastName('');
                        }

                        setIsSuccess(true);
                    })
                    .catch((e) => {
                        if (e.response.status === 400) {
                            setErrorMessage('Пользователь с таким username уже существует');
                            setErrors({ ...newErrors, username: true });
                        } else {
                            createClientLog(e);
                            setErrorMessage('Ошибка сети');
                        }

                        setIsSuccess(false);
                    });
            }
        } else {
            setIsSuccess(false);
            setErrorMessage('Поля заполнены некорректно');
        }
    };

    const deleteSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        disableUser(user.id)
            .then((res) => {
                setLoading(false);
                onClose();
            })
            .catch((e) => {
                createClientLog(e);
                setErrorMessage('Ошибка сети');
            });
    };

    function handleUserPanelButtonClick() {
        setUserForAdminPanel(user);
        setAdminUser((prev) => ({ ...prev, userIdForAdminPanel: user.id }));
        localStorage.setItem('user-id-for-admin-panel', user.id);

        getCardsData(user.id, user.teams?.[0].id)
            .then((response) => {
                const kss = response.data;

                if (kss.length === 0) {
                    setUserKssForAdminPanel([]);
                    return;
                }

                const getItemByStatus = (status) => kss.filter((i) => i.status === status)[0];
                const kssData = [getItemByStatus('keep'), getItemByStatus('stop'), getItemByStatus('start')];

                setUserKssForAdminPanel(kssData);
            })
            .catch((error) => {
                console.error('Ошибка получения КСС', error);
            });

        getFeedbackValuesData(user.id, user.id)
            .then((response) => {
                setUserValuesForAdminPanel(response.data.values);
            })
            .catch((error) => {
                console.error('Ошибка получения ценностей', error);
            });

        getKssTrackerData(user.id)
            .then((kssTrackerData) => {
                setUserTrackerForAdminPanel(kssTrackerData);
            })
            .catch((error) => {
                console.error('Ошибка получения данных КСС трекера', error);
            });

        getTeamMembers(user.id)
            .then((teamMembers) => {
                const teamMembersWithNames = teamMembers.data.map((teamMember) => ({
                    ...teamMember,
                    name: `${teamMember.first_name} ${teamMember.last_name[0]}.`,
                }));

                setTeamMembersForAdminPanel(teamMembersWithNames);
            })
            .catch((error) => {
                console.error('Ошибка получения участников команды', error);
            });

        getKssData(user.id)
            .then((kssData) => {
                setUserKssDataForAdminPanel(kssData);
            })
            .catch((error) => {
                console.error('Ошибка получения КСС', error);
            });

        navigate('/');
    }

    return (
        <RootModal open={open} onClose={onClose}>
            <Wrap>
                <InputWrap>
                    <InputLabel variant={'h1'}>Username</InputLabel>
                    <Input
                        placeholder={'Введите username'}
                        value={username}
                        helperText={
                            errors.username && validating
                                ? 'Username должен состоять из букв латиницы/кирллицы и следующих символов: @, ., +, _ и -'
                                : ''
                        }
                        error={errors.username && validating}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </InputWrap>
                <InputWrap>
                    <InputLabel variant={'h1'}>Password</InputLabel>
                    <Input
                        placeholder={'Введите пароль'}
                        value={password}
                        type={password === '8********' ? 'password' : null}
                        helperText={
                            errors.password && validating
                                ? 'Пароль должен быть длинной 8-20 символов, содержащий буквы латинского алфавита. Может содержать спецсимволы'
                                : ''
                        }
                        error={errors.password && validating}
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={(e) => (password === '8********' ? setPassword('') : null)}
                    />
                </InputWrap>
                <InputWrap>
                    <InputLabel variant={'h1'}>Имя</InputLabel>
                    <Input
                        placeholder={'Иван'}
                        value={firstName}
                        helperText={
                            errors.firstName && validating
                                ? 'Имя должно состоять только из букв латиницы/кириллицы'
                                : ''
                        }
                        error={errors.firstName && validating}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </InputWrap>
                <InputWrap>
                    <InputLabel variant={'h1'}>Фамилия</InputLabel>
                    <Input
                        placeholder={'Иванов'}
                        value={lastName}
                        helperText={
                            errors.lastName && validating
                                ? 'Фамилия должна состоять только из букв латиницы/кириллицы'
                                : ''
                        }
                        error={errors.lastName && validating}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </InputWrap>
                <Typography style={{ color: colors.red }}>{errorMessage}</Typography>
                {isSuccess ? (
                    <Typography style={{ color: colors.green }}>
                        Пользователь успешо {user === null ? 'создан' : 'изменен'}
                    </Typography>
                ) : null}
                <ButtonsBar>
                    {user !== null ? (
                        <SaveButton
                            disabled={loading}
                            name={'delete-and-exit'}
                            variant={'coloredLight'}
                            onClick={(e) => deleteSubmit(e)}
                            style={{ width: '100%', background: 'rgb(245, 66, 111)' }}
                        >
                            Удалить
                        </SaveButton>
                    ) : null}

                    <SaveButton
                        disabled={loading}
                        name={'save-and-exit'}
                        variant={'coloredLight'}
                        onClick={(e) => handleSubmit(e)}
                        style={user !== null ? { width: '100%', height: 72 } : null}
                    >
                        Сохранить
                    </SaveButton>
                    {user === null ? (
                        <SaveButton
                            disabled={loading}
                            name={'save-and-add-more'}
                            variant={'colored'}
                            onClick={(e) => handleSubmit(e)}
                        >
                            Сохранить и добавить еще
                        </SaveButton>
                    ) : null}
                </ButtonsBar>
                <ButtonsBar>
                    {user !== null && adminUser.appAdminPanel && (
                        <SaveButton
                            disabled={loading}
                            name={'user-panel'}
                            variant={'colored'}
                            onClick={handleUserPanelButtonClick}
                            sx={{
                                width: '100%',
                                height: 72,
                            }}
                        >
                            Перейти в профиль
                        </SaveButton>
                    )}
                </ButtonsBar>
            </Wrap>
        </RootModal>
    );
};

export default UserCreateModal;
