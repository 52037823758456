import React from 'react';
import { colors, theme } from '../styles/globalStyles';
import { styled, Typography, Chip } from '@mui/material';
import StarOrMessageChip from './chips/starOrMessageChip';

const Layout = styled('article')({
    padding: 17,
    width: '100%',
    flexGrow: 2,

    background: colors.white,
    color: colors.darkBlue,

    borderRadius: 23,
});

const Header = styled('header')({
    marginBottom: 10,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
});

const StyledAvatar = styled('img')({
    width: 35,
    height: 35,

    borderRadius: 1000,
});

const TypographyName = styled(Typography)({
    marginBottom: 5,

    fontSize: 14,
    fontWeight: 700,
    lineHeight: '100%',
});

const TypographySubName = styled(Typography)({
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '130%',

    opacity: 0.6,
});

const TypographyMain = styled(Typography)({
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '150%',
});

const ChipLayout = styled('div')({
    margin: '10px -7px -7px -7px',
});

const UserContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 10,
});

const FeedbackReviewsComment = ({ user, subname, feedback, result, avatar, date, isGray }) => {
    return (
        <Layout>
            <Header>
                <UserContainer>
                    <StyledAvatar src={`https://app.intg101.work${avatar}`} alt="avatar" />
                    <div>
                        <TypographyName>{user}</TypographyName>
                        <TypographySubName>{subname}</TypographySubName>
                    </div>
                </UserContainer>
                <Chip label={date} variant={isGray ? 'gray' : 'lightBlue'} size="small" />
            </Header>
            {feedback.length !== 0 ? <TypographyMain>{feedback[0].text}</TypographyMain> : null}
            <ChipLayout>
                <StarOrMessageChip
                    number={result}
                    background={colors.transparentMain}
                    text_color={colors.main}
                    radius={13}
                    isGray={isGray}
                />
            </ChipLayout>
        </Layout>
    );
};

export default FeedbackReviewsComment;
