import { Typography } from '@mui/material';
import { colors } from '../../../../styles/globalStyles';
import { Bar } from './elements/Bar';
import { AverageValue } from './elements/AverageValue';
import { AnswerContainer, Graph, GraphWrapper } from './styles.css';

export const SliderAnswer = ({ question }) => {
    const answersRange = [0, 5];
    const height = 180;
    const avgValue = question.answers.avg || 0;
    let maxAnswerValue = 0;

    for (let answer in question.answers) {
        if (Number.isInteger(+answer) && question.answers[answer] > maxAnswerValue) {
            maxAnswerValue = question.answers[answer];
        }
    }

    const bars = Array(answersRange[1] - answersRange[0] + 1)
        .fill()
        .map((_, index) => {
            const mark = answersRange[0] + index;

            return (
                <>
                    <Bar markValue={question.answers[mark]} maxAnswerValue={maxAnswerValue} />
                    <Typography
                        color={colors.darkBlue}
                        sx={{
                            fontSize: 14,
                            fontWeight: 700,
                        }}
                    >
                        {mark}
                    </Typography>
                </>
            );
        });

    return (
        <AnswerContainer height={height}>
            <GraphWrapper>
                <Graph answersRange={answersRange}>
                    {bars}
                    <AverageValue avgValue={avgValue} maxQuestionValue={answersRange[1]} />
                </Graph>
            </GraphWrapper>
        </AnswerContainer>
    );
};
