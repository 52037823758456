import RootModal from './rootModal';
import QuizInstructions from '../QuizInstructions';
import { styled } from '@mui/system';

const InstructionWrapper = styled('div')(() => ({
    flex: 1,
    padding: '0 20px 20px 20px',

    '& > div': {
        maxHeight: 'calc(100vh - 110px)',
    },
}));

const InstructionsModal = ({ open, onClose }) => {
    return (
        <RootModal open={open} onClose={onClose}>
            <InstructionWrapper>
                <QuizInstructions isLeader={true} isFirstInstruction={true} />
            </InstructionWrapper>
        </RootModal>
    );
};

export default InstructionsModal;
