import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Avatar, Box, Button, Card, CardActionArea, Skeleton, styled, Typography } from '@mui/material';

import { ReactComponent as ColoredDot } from '../images/colored_dot.svg';
import { ReactComponent as MessagesIcon } from '../images/messages_icon.svg';

import StarOrMessageChip from './chips/starOrMessageChip';
import { colors } from '../styles/globalStyles';
import { dateFormat, timeFormat } from '../functions/nextSessionFormat';

import Dropdown from './dropdowns/dropdown';
import FeedbackModal from './feedbackModal';

import { userInfoAtomArr, userValuesForAdminPanelAtom } from '../recoil/atoms/userAtom';
import { behaviorsDataAtom, nextSessionAtom } from '../recoil/atoms/mySessionPageAtoms';
import { teamMembersAtom, teamMembersForAdminPanelAtom } from '../recoil/atoms/teamMembersAtom';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';

const Wrapper = styled('div')({
    padding: 17,
    background: colors.transparentMain,
    borderRadius: 40,
});

const Header = styled('header')({
    padding: '10px 10px 27px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const HeaderLeft = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 5,

    '& div:last-child': {
        marginLeft: 'auto',
    },
}));

const TypographyHeader = styled(Typography)({
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '100%',
});

export const Buttons = styled('nav')({
    marginBottom: 17,
    padding: 3,

    display: 'flex',

    background: colors.transparentMain,
    borderRadius: 23,
});

export const StyledButton = styled(Button)({
    width: '100%',
    height: 52,

    fontSize: 12,
    fontWeight: 700,
    lineHeight: '100%',
    color: colors.main,

    borderRadius: 20,

    '&.active': {
        background: colors.main,
        color: colors.white,
        '&:hover': {
            backgroundColor: colors.main,
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0 0)',
        },
    },
    '&:hover': {
        background: colors.transparentMain,
    },
});

export const CardsSection = styled('section')({
    // [theme.breakpoints.up('sm')]: {
    //     display: 'grid',
    //     gridTemplateColumns: '1fr 1fr',
    //     gap: 2,
    // },
});

export const Behavior = styled('div')({
    padding: 10,
    marginBottom: 2,

    background: colors.transparentMain,
    borderRadius: 23,
});

export const Value = styled(Typography)({
    padding: 10,
    paddingBottom: 20,

    fontSize: 16,
    fontWeight: 700,
    lineHeight: '125%',

    '& svg': {
        marginRight: 6,
    },
});

export const BehaviorItems = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
});

const BehaviorItem = styled(Card)({
    background: colors.white,
    borderRadius: 13,
});

const TypographyBehaviorItem = styled(Typography)({
    padding: 10,

    fontSize: 14,
    fontWeight: 500,
    lineHeight: '128%',
});

const BehaviorStub = styled('div')({
    width: '100%',
    background: colors.transparentMain,
    borderRadius: 6,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    letterSpacing: '0.01em',

    '& .caption': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '15px',
    },

    '& .date': {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '20.4px',
    },
});

const ProgressBehaviors = () => {
    const { avatar } = useRecoilValue(userInfoAtomArr);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [positive, setPositive] = useState(true);
    const [selectedBehavior, setSelectedBehavior] = useState({});
    const [selectedRatedUser, setSelectedRatedUser] = useState('all');

    const data = useRecoilValue(behaviorsDataAtom);
    const nextSession = useRecoilValue(nextSessionAtom);
    const teamMembers = useRecoilValue(teamMembersAtom);
    const userValuesForAdminPanel = useRecoilValue(userValuesForAdminPanelAtom);
    const teamMembersForAdminPanel = useRecoilValue(teamMembersForAdminPanelAtom);
    const adminUser = useRecoilValue(AdminUserAtom);

    const nextDate = dateFormat(nextSession);
    const nextTime = timeFormat(nextSession);

    const handleClick = (behavior, isPositive, behaviorValue) => {
        if (behavior.score['avg-rating'][selectedRatedUser.id] !== null || behavior.score['my-rating'] !== null) {
            setSelectedBehavior({ ...behavior, is_positive: isPositive, value: behaviorValue });
            setIsModalOpen(true);
        }
    };

    return (
        <Wrapper>
            <Header>
                <HeaderLeft>
                    <Avatar src={avatar} alt="avatar" sx={{ width: 24, height: 24 }} />
                    <TypographyHeader>Поведение</TypographyHeader>
                </HeaderLeft>
                {(adminUser.userIdForAdminPanel ? teamMembersForAdminPanel : teamMembers).length !== 0 && (
                    <Dropdown
                        // options={Object.keys(data[0].behaviors[0].score['avg-rating']).map((userId) => ({name: userId}))}
                        options={[
                            { name: 'От всех коллег', id: 'all' },
                            ...(adminUser.userIdForAdminPanel ? teamMembersForAdminPanel : teamMembers),
                        ]}
                        setSelectedOption={(option) => setSelectedRatedUser(option)}
                    />
                )}
                {/* <Chip variant="lightBlue" size="small" label="Session 1" /> */}
            </Header>
            <Buttons onClick={() => setPositive(!positive)}>
                <StyledButton className={positive ? 'active' : null}>Положительные</StyledButton>
                <StyledButton className={positive ? null : 'active'}>Отрицательные</StyledButton>
            </Buttons>
            <CardsSection>
                {(adminUser.userIdForAdminPanel ? userValuesForAdminPanel : data).length > 0 ? (
                    (adminUser.userIdForAdminPanel ? userValuesForAdminPanel : data)
                        .filter((item) => item['is_positive'] === positive)
                        .map((behaviorsList, index) => (
                            <Behavior key={`behavior-${index}`}>
                                <BehaviorItems>
                                    <Value>
                                        <ColoredDot fill={behaviorsList['is_positive'] ? colors.green : colors.red} />
                                        {behaviorsList.value}
                                    </Value>
                                    <BehaviorItems>
                                        {behaviorsList.behaviors.map((item, index) => (
                                            <BehaviorItem
                                                key={`${behaviorsList.value}-${index}`}
                                                onClick={() =>
                                                    handleClick(item, behaviorsList['is_positive'], behaviorsList.value)
                                                }
                                            >
                                                <CardActionArea style={{ padding: 10, borderRadius: 13 }}>
                                                    <TypographyBehaviorItem>{item['title']}</TypographyBehaviorItem>
                                                    {item.score['avg-rating'][selectedRatedUser.id] !== null ||
                                                    item.score['my-rating'] !== null ? (
                                                        <div style={{ display: 'flex', gap: 2 }}>
                                                            <StarOrMessageChip
                                                                radius={6}
                                                                background={colors.transparentMain}
                                                                title={'Мои оценки:'}
                                                                number={
                                                                    item.score['my-rating'] !== null
                                                                        ? item.score['my-rating']
                                                                        : '–'
                                                                }
                                                            />
                                                            <StarOrMessageChip
                                                                radius={6}
                                                                background={colors.transparentMain}
                                                                title={'Оценки от коллег:'}
                                                                number={
                                                                    item.score['avg-rating'][selectedRatedUser.id] !==
                                                                    null
                                                                        ? item.score['avg-rating'][selectedRatedUser.id]
                                                                        : '–'
                                                                }
                                                            />
                                                            <StarOrMessageChip
                                                                radius={6}
                                                                background={colors.transparentMain}
                                                                title={'Фидбэк:'}
                                                                number={
                                                                    item.score['feedback'][selectedRatedUser.id] ?? 0
                                                                }
                                                                messageIcon={MessagesIcon}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <BehaviorStub>
                                                            <Typography className={'caption'}>Следующая:</Typography>
                                                            <Typography className={'date'}>
                                                                {`${nextDate}, ${nextTime}`}
                                                            </Typography>
                                                        </BehaviorStub>
                                                    )}
                                                </CardActionArea>
                                            </BehaviorItem>
                                        ))}
                                    </BehaviorItems>
                                </BehaviorItems>
                            </Behavior>
                        ))
                ) : (
                    <Skeleton variant={'rectangular'} sx={{ width: '100%', height: 135, borderRadius: '23px' }} />
                )}
            </CardsSection>
            {isModalOpen ? (
                <FeedbackModal
                    behaviorData={selectedBehavior}
                    open={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    background={colors.gradient.bg.keep}
                />
            ) : null}
        </Wrapper>
    );
};

export default ProgressBehaviors;
