import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Button, IconButton, InputBase, Typography, styled } from '@mui/material';
import { colors } from '../styles/globalStyles';
import { ReactComponent as ColoredDot } from '../images/colored_dot.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate, useParams } from 'react-router-dom';
import { createClientLog, getTeamValues, postTeamValues, updateTeamValues } from '../api/adminPanel';
import ResultsValue from '../components/resultsValue';
import { useSetRecoilState } from 'recoil';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';
import { StatusBlock } from '../components/behaviorManagementStatus';
import { deepCopy } from '../util/deepCopy';
import { Loader } from '../components/loaders/loader';

export const LoaderWrapper = styled('div')(() => ({
    width: '100%',
    height: 300,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Buttons = styled('nav')({
    marginBottom: 17,
    padding: 3,

    display: 'flex',

    background: colors.transparentMain,
    borderRadius: 23,
});

const StyledButton = styled(Button)({
    width: '100%',
    height: 52,

    fontSize: 12,
    fontWeight: 700,
    lineHeight: '100%',
    color: colors.main,

    borderRadius: 20,

    '&.active': {
        background: colors.main,
        color: colors.white,
        '&:hover': {
            backgroundColor: colors.main,
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0 0)',
        },
    },
    '&:hover': {
        background: colors.transparentMain,
    },
});

const LargeButton = styled(Button)({
    padding: 22,
    borderRadius: 23,
    transition: 'all 0.3s ease',
    textTransform: 'none',

    '&:disabled': {
        color: '#FFFFFF',
        opacity: 0.5,
    },
});

const ValueCard = styled('div')({
    padding: 10,
    borderRadius: 23,
    backgroundColor: colors.transparentMain,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
});

const Title = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    paddingLeft: '7px',
});

const StyledTextInput = styled(InputBase)({
    width: '100%',
    fontSize: 20,

    '& input': {
        border: '2px solid transparent',
        paddingLeft: 5,
        borderRadius: 10,
    },

    '& :first-child': {
        border: `2px solid transparent`,
        paddingLeft: 5,
        '& :focus': {
            border: `2px solid ${colors.darkBlue}`,
            borderRadius: 10,
        },
    },

    '& :focus': {
        border: `2px solid ${colors.darkBlue}`,
        borderRadius: 10,
    },
});

const Description = styled(InputBase)({
    width: '100%',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: 400,

    '& :first-child': {
        border: `2px solid transparent`,
        paddingLeft: 5,
        '& :focus': {
            border: `2px solid ${colors.darkBlue}`,
            borderRadius: 10,
        },
    },

    '& input': {
        border: '2px solid transparent',
        paddingLeft: 5,
        borderRadius: 10,
    },

    '& :focus': {
        border: `2px solid ${colors.darkBlue}`,
        borderRadius: 10,
    },
});

const BehaviorList = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
});

const BehaviorItem = styled('div')({
    padding: 20,
    borderRadius: 23,
    backgroundColor: colors.white,
    display: 'flex',
    alignItems: 'flex-start',
    gap: 5,
});

const SurveyValueTitle = styled(Typography)({
    lineHeight: 1,
    letterSpacing: '0.01rem',
    fontWeight: 800,
    fontSize: 34,
    textAlign: 'center',
    color: colors.darkBlue,
    marginLeft: 'auto',
    marginRight: 'auto',
    textTransform: 'capitalize',
});

const Caption = styled(Typography)(() => ({
    padding: '0 20px',
    fontSize: 14,
    opacity: 0.8,
    textAlign: 'center',
    lineHeight: 1.5,
}));

const CaptionLeft = styled(Caption)(() => ({
    textAlign: 'left',
}));

const BehaviorManagementPage = () => {
    const setAdminUser = useSetRecoilState(AdminUserAtom);

    const navigate = useNavigate();
    const [isPositive, setIsPositive] = useState(true);
    const [values, setValues] = useState([]);
    const [surveyValues, setSurveyValues] = useState([]);
    const { teamId, companyId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [areValuesLoading, setAreValuesLoading] = useState(true);
    const [status, setStatus] = useState('');

    const [ownValues, setOwnValues] = useState([]);
    const [areOwnValuesValid, setAreOwnValuesValid] = useState(false);

    const [checkedBehaviors, setCheckedBehaviors] = useState({});
    const [negativeBehaviors, setNegativeBehaviors] = useState({});
    const [positiveBehaviors, setPositiveBehaviors] = useState({});

    const [clue, setClue] = useState('Всё круто, можно утверждать!');

    const [haveEnoughPositiveBehaviors, setHaveEnoughPositiveBehaviors] = useState(true);
    const [haveEnoughNegativeBehaviors, setHaveEnoughNegativeBehaviors] = useState(true);

    const isUserLoggedFromTelegram = Boolean(window.Telegram?.WebApp?.initDataUnsafe?.user);

    const addValue = () => {
        setValues((prev) => {
            return [{ id: '-1', name: '', behavior: [], is_positive: isPositive }, ...prev];
        });
    };

    const addOwnValue = () => {
        setOwnValues((prev) => {
            return [{ id: '-1', name: '', behavior: [], is_positive: isPositive }, ...prev];
        });
    };

    const addBehavior = (values, valueIndex, setBehavior) => {
        const newValues = deepCopy(values);

        newValues[valueIndex].behavior.push({
            id: '-1',
            title: '',
            description: '',
        });

        setBehavior(newValues);
    };

    const deleteBehavior = (valueIndex, setBehavior, index) => {
        setBehavior((prev) => {
            const newBehaviors = deepCopy(prev);
            newBehaviors[valueIndex].behavior.splice(index, 1);
            return newBehaviors;
        });
    };

    const deleteValue = (setValues, index) => {
        setValues((prev) => {
            const newArr = [...prev];
            newArr.splice(index, 1);
            return newArr;
        });
    };

    const onTextFieldChange = (setValues, value, index) => {
        setValues((prev) => {
            const newArr = deepCopy(prev);
            newArr[index].name = value;
            return newArr;
        });
    };

    const onBehaviorTextFieldChange = (values, valueIndex, setValues, index, value, key) => {
        const newValues = deepCopy(values);
        newValues[valueIndex].behavior[index][key] = value;

        setValues(newValues);
    };

    const onSaveData = (valuesArray) => {
        if (validateData(valuesArray)) {
            setIsLoading(true);
            updateTeamValues(valuesArray, teamId)
                .then((r) => {
                    const sortedValues = deepCopy(r.data).sort((a, b) => a.id - b.id);
                    sortedValues.forEach((obj) => {
                        obj.behavior.sort((a, b) => a.id - b.id);
                    });
                    setValues(sortedValues);
                    setIsLoading(false);
                    setStatus('SUCCESS');
                    setTimeout(() => setStatus(''), 2500);
                })
                .catch((e) => {
                    createClientLog(e);
                    setStatus('ERROR_NET');
                });
        } else {
            setStatus('ERROR_VAL');
        }
    };

    const mapValues = (values, isPositive, setValues) => {
        return values
            ?.filter((value) => value.is_positive === isPositive)
            .map((value, index) => (
                <ValueCard key={index}>
                    <Title>
                        <ColoredDot fill={isPositive ? colors.green : colors.red} />
                        <StyledTextInput
                            placeholder={'Введи название ценности'}
                            value={value.name}
                            multiline
                            onChange={(e) => onTextFieldChange(setValues, e.target.value, values.indexOf(value))}
                        />
                        <IconButton onClick={() => deleteValue(setValues, values.indexOf(value))}>
                            <DeleteIcon sx={{ color: colors.darkBlue }} />
                        </IconButton>
                    </Title>
                    <BehaviorList>
                        {mapBehaviors(values, values.indexOf(value), setValues)}
                        <LargeButton
                            variant={'light'}
                            onClick={() => addBehavior(values, values.indexOf(value), setValues)}
                        >
                            + Добавить
                        </LargeButton>
                    </BehaviorList>
                </ValueCard>
            ));
    };

    const mapBehaviors = (values, valueIndex, setValues) => {
        return (
            <>
                {values[valueIndex]?.behavior.map((behavior, index) => (
                    <div>
                        <Behavior
                            title={behavior.title}
                            description={behavior.description}
                            onBehaviorNameChange={(e) =>
                                onBehaviorTextFieldChange(
                                    values,
                                    valueIndex,
                                    setValues,
                                    index,
                                    e.target.value,
                                    e.target.name,
                                )
                            }
                            onDelete={() => deleteBehavior(valueIndex, setValues, index)}
                            key={index}
                        />
                    </div>
                ))}
            </>
        );
    };

    const handleSubmitBehaviors = () => {
        if (Object.entries(checkedBehaviors).every(([_, isChecked]) => !isChecked)) {
            return;
        }

        if (!validateData(ownValues)) {
            setStatus('ERROR_VAL');
            return;
        }

        setIsLoading(true);

        const data = {
            team_id: teamId,
            behaviors: checkedBehaviors,
            own_values: ownValues,
            survey_values: surveyValues,
        };

        postTeamValues(data)
            .then((res) => {
                const sortedValues = deepCopy(res.data?.values).sort((a, b) => a.id - b.id);
                sortedValues.forEach((obj) => {
                    obj.behavior.sort((a, b) => a.id - b.id);
                });
                setValues(sortedValues);
                setSurveyValues([]);
                setAdminUser((prev) => ({
                    ...prev,
                    areValuesApproved: res.data.are_values_approved,
                }));
                navigate(`/admin/company/${companyId}/team/${teamId}`);
            })
            .catch((err) => {
                setIsLoading(false);
                console.error('Ошибка получения информации о команде', err);
            });
    };

    const handleSelectedBehaviorChange = (behaviorId) => {
        const newBehaviorsData = deepCopy(checkedBehaviors);
        newBehaviorsData[behaviorId] = !newBehaviorsData[behaviorId];
        setCheckedBehaviors(newBehaviorsData);
    };

    const handleGettingSurveyValues = (surveyValues) => {
        setCheckedBehaviors(
            surveyValues.flatMap((item) => item.behavior).reduce((acc, { id }) => ({ ...acc, [id]: false }), {}),
        );
        setNegativeBehaviors(
            surveyValues
                .filter((item) => !item.is_positive)
                .flatMap((item) => item.behavior)
                .reduce((acc, { id }) => ({ ...acc, [id]: false }), {}),
        );
        setPositiveBehaviors(
            surveyValues
                .filter((item) => item.is_positive)
                .flatMap((item) => item.behavior)
                .reduce((acc, { id }) => ({ ...acc, [id]: false }), {}),
        );
    };

    useEffect(() => {
        getTeamValues(teamId)
            .then((r) => {
                if (r.data?.values?.length !== 0) {
                    const sortedValues = deepCopy(r.data?.values).sort((a, b) => a.id - b.id);
                    sortedValues.forEach((obj) => {
                        obj.behavior.sort((a, b) => a.id - b.id);
                    });
                    setValues(sortedValues);
                } else if (r.data?.values_from_survey.length !== 0) {
                    setSurveyValues(r.data?.values_from_survey);
                    handleGettingSurveyValues(r.data?.values_from_survey);
                }
                setAreValuesLoading(false);
            })
            .catch((err) => {
                setAreValuesLoading(false);
                console.error('Ошибка получения ценностей команды', err);
            });
    }, []);

    useEffect(() => {
        Object.entries(checkedBehaviors).forEach(([id, isChecked]) => {
            id = Number(id);

            if (id in positiveBehaviors) {
                setPositiveBehaviors((prev) => ({
                    ...prev,
                    [id]: isChecked,
                }));
            } else if (id in negativeBehaviors) {
                setNegativeBehaviors((prev) => ({
                    ...prev,
                    [id]: isChecked,
                }));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedBehaviors]);

    useEffect(() => {
        const positiveOwnBehaviorsCount = ownValues
            .filter((value) => value.is_positive)
            .flatMap((value) => value.behavior).length;
        const negativeOwnBehaviorsCount = ownValues
            .filter((value) => !value.is_positive)
            .flatMap((value) => value.behavior).length;

        const selectedPositiveBehaviorsCount = Object.values(positiveBehaviors).filter(Boolean).length;
        const selectedNegativeBehaviorsCount = Object.values(negativeBehaviors).filter(Boolean).length;

        const totalPositive = positiveOwnBehaviorsCount + selectedPositiveBehaviorsCount;
        const totalNegative = negativeOwnBehaviorsCount + selectedNegativeBehaviorsCount;

        const isDataValid = validateData(ownValues);

        setHaveEnoughPositiveBehaviors(totalPositive >= 5);
        setHaveEnoughNegativeBehaviors(totalNegative >= 5);
        setAreOwnValuesValid(isDataValid);

        if ((isPositive && totalPositive < 5) || (!isPositive && totalNegative < 5)) {
            setClue('Рекомендуем выбрать от 5 до 7 способов поведений');
        } else if ((isPositive && totalPositive > 7) || (!isPositive && totalNegative > 7)) {
            setClue('🍁 Рекомендуем начать с меньшего числа поведений');
        } else if (totalPositive >= 5 && totalPositive <= 7 && isPositive && totalNegative < 5) {
            setClue('🍀 Можно перейти к отрицательным поведениям');
        } else if (totalNegative >= 5 && totalNegative <= 7 && !isPositive && totalPositive < 5) {
            setClue('🍀 Можно перейти к положительным поведениям');
        } else if (!isDataValid) {
            setClue('🚩 Некоторые способы поведения заполнены неверно');
        } else {
            setClue('🎉 Всё круто, можно утверждать!');
        }
    }, [negativeBehaviors, positiveBehaviors, ownValues, isPositive]);

    if (areValuesLoading) {
        return (
            <Layout link={`/admin/company/${companyId}/team/${teamId}`}>
                <LoaderWrapper>
                    <Loader size="large" color={colors.main} />
                </LoaderWrapper>
            </Layout>
        );
    }

    return (
        <Layout link={`/admin/company/${companyId}/team/${teamId}`}>
            <Buttons onClick={() => setIsPositive(!isPositive)}>
                <StyledButton className={isPositive ? 'active' : null}>Положительные</StyledButton>
                <StyledButton className={isPositive ? null : 'active'}>Отрицательные</StyledButton>
            </Buttons>
            {values.length === 0 && surveyValues?.length !== 0 ? (
                <>
                    <CaptionLeft>
                        Поставить галочку напротив тех способов поведения, с&nbsp;которыми вы
                        хотели&nbsp;бы&nbsp;работать
                    </CaptionLeft>
                    {surveyValues
                        .filter((value) => value.is_positive === isPositive)
                        .map((value) => (
                            <ResultsValue
                                onBehaviorNameChange={(behaviorIndex, inputValue, inputKey) =>
                                    onBehaviorTextFieldChange(
                                        surveyValues,
                                        surveyValues.indexOf(value),
                                        setSurveyValues,
                                        behaviorIndex,
                                        inputValue,
                                        inputKey,
                                    )
                                }
                                onValueNameChange={(e) =>
                                    onTextFieldChange(setSurveyValues, e.target.value, surveyValues.indexOf(value))
                                }
                                checkedBehaviors={checkedBehaviors}
                                handleChange={handleSelectedBehaviorChange}
                                key={value.id}
                                value={value}
                            />
                        ))}

                    <LargeButton variant={'colored'} onClick={() => addOwnValue(isPositive)}>
                        + Добавить свой вариант
                    </LargeButton>
                    {mapValues(ownValues, isPositive, setOwnValues)}

                    <CaptionLeft>{clue}</CaptionLeft>

                    {haveEnoughPositiveBehaviors && haveEnoughNegativeBehaviors ? (
                        <LargeButton
                            variant={'colored'}
                            onClick={handleSubmitBehaviors}
                            disabled={!areOwnValuesValid || isLoading}
                        >
                            {isLoading ? <Loader size="medium" /> : 'Утвердить ценности для команды'}
                        </LargeButton>
                    ) : (
                        <LargeButton
                            variant={'colored'}
                            onClick={() => setIsPositive(!isPositive)}
                            disabled={
                                (isPositive && !haveEnoughPositiveBehaviors) ||
                                (!isPositive && !haveEnoughNegativeBehaviors)
                            }
                        >
                            {isPositive ? 'Перейти к отрицательным' : 'Перейти к положительным'}
                        </LargeButton>
                    )}
                </>
            ) : (
                <>
                    <LargeButton variant={'colored'} onClick={() => addValue(isPositive)}>
                        + Добавить
                    </LargeButton>
                    {mapValues(values, isPositive, setValues)}

                    <StatusBlock status={status} />

                    <LargeButton disabled={isLoading} variant={'colored'} onClick={() => onSaveData(values)}>
                        {isLoading ? <Loader size="medium" /> : 'Сохранить'}
                    </LargeButton>
                </>
            )}

            {values.length === 0 && surveyValues?.length === 0 && isUserLoggedFromTelegram && (
                <Caption>Рекомендованные ценности появятся после прохождения опроса участниками команды</Caption>
            )}
        </Layout>
    );
};

const Behavior = ({ title, description, onBehaviorNameChange, onDelete }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <BehaviorItem>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <StyledTextInput
                    value={title}
                    name={'title'}
                    multiline
                    onChange={onBehaviorNameChange}
                    placeholder={'Введи название поведения'}
                />
                {isOpen ? (
                    <Description
                        value={description}
                        name={'description'}
                        multiline
                        onChange={onBehaviorNameChange}
                        placeholder={'Описание'}
                    />
                ) : null}
            </div>
            <IconButton onClick={() => setIsOpen(!isOpen)}>
                <InfoIcon sx={{ color: colors.darkBlue }} />
            </IconButton>
            <IconButton onClick={onDelete}>
                <DeleteIcon sx={{ color: colors.darkBlue }} />
            </IconButton>
        </BehaviorItem>
    );
};

function validateData(data) {
    for (const obj of data) {
        if (!obj.name.trim()) {
            return false;
        }
        for (const behaviorObj of obj.behavior) {
            if (!behaviorObj.title.trim()) {
                return false;
            }
        }
    }
    return true;
}

export default BehaviorManagementPage;
