import { atom, selector } from 'recoil';
import { getMyWeekProgress } from '../../api/stats';
import { getFeedbackValuesData } from '../../api/user';

export const searchModalOpenAtom = atom({
    key: 'searchModalOpen',
    default: false,
});

export const weekProgressSelector = selector({
    key: 'weekProgressSelector',
    get: async () => {
        const userIdForAdminPanel = localStorage.getItem('user-id-for-admin-panel');
        const response = await getMyWeekProgress(userIdForAdminPanel || null);
        return response.data;
    },
});

export const weekProgressDataAtom = atom({
    key: 'weekProgressData',
    default: weekProgressSelector,
});

export const behaviorsSelector = selector({
    key: 'behaviorsSelector',
    get: async () => {
        const response = await getFeedbackValuesData();
        return response.data.values;
    },
});

export const behaviorsDataAtom = atom({
    key: 'behaviorsData',
    default: behaviorsSelector,
});

export const nextSessionSelector = selector({
    key: 'nextSessionSelector',
    get: async () => {
        const response = await getFeedbackValuesData();
        return response.data['next-session'];
    },
});

export const nextSessionAtom = atom({
    key: 'nextSession',
    default: nextSessionSelector,
});
