import { AnswerItem, AnswerText, Title } from '../../styles.css';
import { Answer } from '../Answer';
import { SliderAnswer } from '../SliderAnswer';
import { AnswerList, QuestionCard } from './styles.css';

export const Question = ({ question }) => {
    const questionType = question.type;
    let answers;

    switch (questionType) {
        case 'number':
            answers = <SliderAnswer question={question} />;
            break;

        case 'options':
            const answerOptionsItems = question.answers?.map((answer) => <Answer key={answer.id} answer={answer} />);
            answers = <AnswerList>{answerOptionsItems}</AnswerList>;
            break;

        default:
            if (!question.answers?.length) {
                answers = <AnswerText sx={{ textAlign: 'center' }}>Пока никто не ответил</AnswerText>;
                break;
            }

            const answerTextItems = question.answers?.map((answer, index) => (
                <AnswerItem key={index}>
                    <AnswerText>{answer}</AnswerText>
                </AnswerItem>
            ));
            answers = <AnswerList>{answerTextItems}</AnswerList>;
    }

    return (
        <QuestionCard>
            <Title>{question.title}</Title>
            {answers}
        </QuestionCard>
    );
};
