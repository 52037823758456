import { AnswerItem, AnswerText } from '../../styles.css';
import { AnswerCounter } from './styles.css';

export const Answer = ({ answer }) => {
    return (
        <AnswerItem>
            <AnswerText>{answer.title}</AnswerText>
            <AnswerCounter>{answer.count}</AnswerCounter>
        </AnswerItem>
    );
};
