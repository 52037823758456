import { Button, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import cross from './../../images/whiteCross.svg';
import doubleArrow from './../../images/doubleArrow.svg';
import { colors } from '../../styles/globalStyles';
import { putWatchedSet } from '../../api/stories';

const Wrapper = styled(Button)({
    background: 'linear-gradient(white, white) padding-box, linear-gradient(red, blue) border-box',
    borderWidth: '2px',
    padding: '2px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: '100%',
    cursor: 'pointer',
    minWidth: '64px',

    '&:hover': {
        background: 'linear-gradient(white, white) padding-box, linear-gradient(red, blue) border-box',
    },
});

const StoryPreview = styled('img')({
    aspectRatio: '1/1',
    width: '64px',
    objectFit: 'cover',
    borderRadius: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const StoryWrapper = styled('div')({
    width: '100%',
    margin: '0 auto',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    '&:focus-visible': {
        outline: 'none',
    },
    '@media (min-width: 415px)': {
        maxWidth: '390px',
    },
});

const CloseButton = styled(Button)({
    display: 'flex',
    position: 'absolute',
    top: '39px',
    right: '16px',
    zIndex: 2,
    minWidth: '24px',
    padding: 0,
    '&:hover': {
        background: 'transparent',
    },
});

const CloseIcon = styled('img')({
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const Story = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const StoriesTopShadow = styled('div')({
    background: 'linear-gradient(180deg, rgba(0,0,0,0.12) 0%, rgba(0,0,0,0) 100%)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '51px',
});

const StoriesBreadcrumbs = styled('div')({
    position: 'absolute',
    top: '10px',
    left: 0,
    right: 0,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    zIndex: 2,
});

const Breadcrumb = styled('div')({
    height: '3px',
    background: 'rgba(255, 255, 255, 0.35)',
    width: '100%',
    borderRadius: '3px',
    backdropFilter: 'blur(10px)',
    '&.active': {
        background: 'rgba(255, 255, 255, 1)',
    },
});

const StoryContentWrapper = styled('div')({
    overflowY: 'auto',
    position: 'absolute',
    background: '#FFF',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '16px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    zIndex: 2,
});

const Title = styled(Typography)({
    color: '#212121',
    fontFamily: 'Onest',
    fontSize: '24px',
    lineHeight: '30.6px',
    letterSpacing: 0.24,
    fontWeight: '800',
    margin: '0',
});

const StoryContent = styled('div')({
    fontFamily: 'Onest',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '-0.24px',
    margin: '12px 0 16px',
    color: '#212121',
    '& p': {
        margin: 0,
    },
    '& strong': {
        fontWeight: 500,
        color: '#3B7AF6',
    },
    '& span': {
        fontWeight: '600',
    },
    '& ol': {
        paddingLeft: '23px',
    },
    '& ul': {
        paddingLeft: '27px',
    },
});

const StoryNextButton = styled(Button)({
    height: '58px',
    background: colors.transparentWhite,
    borderRadius: 23,
    color: 'white',
    padding: '13px 15px',
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '18px',

    '&.light': {
        background: colors.main,
        color: colors.white,
    },

    '&:disabled': {
        opacity: 0.5,
    },
});

const Counter = styled('div')({
    width: '32px',
    height: '32px',
    background: '#FFFFFF24',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: '13px',
    top: '15px',
    fontSize: '10px',
    lineHeight: '10px',
    fontWeight: 400,
    borderRadius: '100px',
});

const StoryModal = ({ storiesGroup, allStoriesList, isAutoOpen = false }) => {
    const [open, setOpen] = useState(isAutoOpen);
    const [currentStoriesGroup, setCurrentStoriesGroup] = useState(storiesGroup);
    const [currentStoryIndex, setCurrentStoryIndex] = useState(0);

    useEffect(() => {
        if (currentStoriesGroup?.slides) {
            preLoadImages(currentStoriesGroup.slides);
        }
    }, [currentStoriesGroup]);

    const preLoadImages = (slides) => {
        slides.forEach((story) => {
            const img = new Image();
            img.src = story.image;
        });
    };

    const breadcrumbsItems = currentStoriesGroup?.slides?.map((story) => (
        <Breadcrumb
            key={story.id}
            className={story.id === currentStoriesGroup?.slides[currentStoryIndex]?.id ? 'active' : ''}
        />
    ));

    const buttonText = currentStoryIndex === breadcrumbsItems.length - 1 ? 'Завершить' : 'Продолжить';

    const handleOpenStory = () => {
        setCurrentStoriesGroup(storiesGroup);
        setCurrentStoryIndex(0);
        setOpen(true);
    };

    const handleClickNextButton = () => {
        if (currentStoryIndex !== currentStoriesGroup?.slides?.length - 1) {
            setCurrentStoryIndex(currentStoryIndex + 1);
            return;
        }

        const data = {
            user_id: window.Telegram?.WebApp?.initDataUnsafe?.user?.id,
            story_id: currentStoriesGroup.id,
        };

        putWatchedSet(data).catch((err) => {
            console.error(err);
        });

        setOpen(false);
    };

    return (
        <>
            {!isAutoOpen && (
                <Wrapper>
                    <StoryPreview src={storiesGroup.image} alt={storiesGroup.title} onClick={handleOpenStory} />
                </Wrapper>
            )}

            <Modal open={open}>
                <StoryWrapper>
                    <StoriesTopShadow />
                    <StoriesBreadcrumbs>{breadcrumbsItems}</StoriesBreadcrumbs>
                    <CloseButton onClick={() => setOpen(false)}>
                        <CloseIcon src={cross} alt={'Закрыть'} />
                    </CloseButton>
                    <Story src={currentStoriesGroup?.slides[currentStoryIndex]?.image} />
                    <StoryContentWrapper>
                        <Title as={'h2'} variant={'body1'}>
                            {currentStoriesGroup?.slides[currentStoryIndex]?.title}
                        </Title>
                        <StoryContent
                            dangerouslySetInnerHTML={{
                                __html: currentStoriesGroup?.slides[currentStoryIndex]?.content,
                            }}
                        />
                        <StoryNextButton className={'light'} onClick={handleClickNextButton}>
                            <span>{buttonText}</span>
                            <img src={doubleArrow} alt="Продолжить" />
                            <Counter>
                                {currentStoryIndex + 1}/{breadcrumbsItems.length}
                            </Counter>
                        </StoryNextButton>
                    </StoryContentWrapper>
                </StoryWrapper>
            </Modal>
        </>
    );
};

export default StoryModal;
