import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import BgImage from '../../images/bg.png';
import { ReactComponent as CrossIcon } from '../../images/crossIconFilled.svg';
import { ReactComponent as InfoIcon } from '../../images/infoIconFilled.svg';
import { Breadcrumbs, IconButton, Link, Modal } from '@mui/material';
import { colors } from '../../styles/globalStyles';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DotDivider } from '../../images/dot_divider.svg';

const Window = styled('div')(({ bgColor }) => ({
    background: bgColor ? `${bgColor}, url(${BgImage})` : 'white',
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    borderRadius: '40px 40px 0 0',

    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,

    margin: '0 auto',
    maxWidth: 390,
    height: '98%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    overflowY: 'auto',

    '@keyframes fade-in': {
        from: {
            bottom: '-100%',
        },
        to: {
            bottom: 0,
        },
    },
    '@keyframes fade-out': {
        from: {
            bottom: 0,
        },
        to: {
            bottom: '-100%',
        },
    },
    '&.fade-in': {
        animation: '0.25s ease fade-in',
    },
    '&.fade-out': {
        animation: '0.25s ease fade-out',
    },
}));

const Header = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '17px 27px',
});

const IconContainer = styled('div')({
    display: 'flex',
    gap: 10,
});

const CloseButton = styled(IconButton)({
    padding: 0,
    marginRight: -10,

    '&.light': {
        color: colors.main,
        background: colors.transparentMain,
    },

    '&.transparent': {
        color: colors.white,
        background: colors.transparentWhite,
    },

    '&:hover': {
        background: 'rgba(59, 122, 246, 0.3)',
    },
});

const StyledBreadcrumbs = styled(Breadcrumbs)({
    '& a.light': {
        opacity: 0.4,
        color: colors.darkBlue,
    },

    '& a.transparent': {
        opacity: 0.4,
        color: colors.white,
    },

    '& a.current.light': {
        opacity: 1,
        color: colors.main,
    },

    '& a.current.transparent': {
        opacity: 1,
        color: colors.white,
    },
});

const RootModal = ({ open, onClose, color, breadcrumbsData, children, style, info = false, setInfoOpen }) => {
    const navigate = useNavigate();

    const [transitionState, setTransitionState] = useState('fade-in');

    const handleClose = () => {
        setTransitionState('fade-out');
        setTimeout(onClose, 240);
    };

    useEffect(() => {
        setTransitionState('fade-in');
    }, [children]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Window bgColor={color} style={style} className={transitionState}>
                <Header>
                    <StyledBreadcrumbs separator={<DotDivider style={{ width: 2, height: 2 }} />}>
                        {breadcrumbsData?.map((item) => {
                            if (item.uri !== '') {
                                return (
                                    <Link
                                        key={item.label}
                                        className={color ? 'transparent' : 'color'}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => navigate(item.uri)}
                                    >
                                        {item.label}
                                    </Link>
                                );
                            } else {
                                return (
                                    <Link key={item.label} className={`current ${color ? 'transparent' : 'color'}`}>
                                        {item.label}
                                    </Link>
                                );
                            }
                        })}
                    </StyledBreadcrumbs>
                    <IconContainer>
                        {info ? (
                            <IconButton
                                sx={{ width: 36, height: 36 }}
                                style={{ padding: 0 }}
                                onClick={() => {
                                    setInfoOpen(true);
                                }}
                            >
                                <InfoIcon />
                            </IconButton>
                        ) : null}
                        <CloseButton onClick={handleClose} sx={{ width: 36, height: 36 }}>
                            <CrossIcon />
                        </CloseButton>
                    </IconContainer>
                </Header>
                {children}
            </Window>
        </Modal>
    );
};

export default RootModal;
