import React from 'react';
import { styled } from '@mui/system';
import { Button, Box } from '@mui/material';

const CommentInput = styled('textarea')({
    marginBottom: 2,
    padding: 17,
    width: '100%',
    height: '44.5vh',

    border: '2px solid rgba(59, 122, 246, 0.24)',
    borderRadius: 23,

    fontFamily: 'Onest',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '150%',
});

const StyledButton = styled(Button)({
    borderRadius: 23,
});

const CommentWindow = ({ inputValue, setInputValue, onClick, selfFeedback = false }) => {
    return (
        <div style={{ padding: 17 }}>
            <CommentInput
                placeholder={
                    // `Leave some advice, lifehack, guide, comment or your observations of a colleague` + (userData && userData["first-name"] ? ` for ${userData["first-name"]}...` : "")
                    selfFeedback
                        ? `Опишите почему вы поставили такую оценку себе`
                        : `Поделитесь почему вы поставили такую оценку коллеге, например, опишите конкретный случай, в котором наблюдали, как коллега проявляет данный способ поведения`
                }
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />
            <Box height={'72px'} display={'flex'} gap={'2px'}>
                <StyledButton fullWidth onClick={() => onClick(true)} className={'active'} variant={'colored'}>
                    Отправить
                </StyledButton>
            </Box>
        </div>
    );
};

export default CommentWindow;
