import { getUserData } from '../api/auth';
import { getAvatar } from '../api/user';

export default async function getUserDataWithAvatar() {
    const { data } = await getUserData();

    const userData = {
        username: data.username,
        firstName: data.first_name,
        userId: data.id,
        teamName: data.team.name,
        teamId: data.team.id,
        isStaff: data['is_staff'],
        isSuperuser: data['is_superuser'],
        areValuesApproved: data?.team?.are_values_approved,
        isSessionCreated: data?.team?.is_session_created,
        numbersOfUsers: data?.team?.number_of_users,
        currentUsersNumber: data?.team?.current_users_number,
    };

    const { data: data_1 } = await getAvatar();

    const avatar = `https://app.intg101.work${data_1}`;
    localStorage.setItem('avatar', avatar);
    userData.avatar = avatar;

    return userData;
}
