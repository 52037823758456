import { Box, styled } from '@mui/material';
import { colors } from '../../../../styles/globalStyles';
import { AnswerCounter } from '../Answer/styles.css';

export const AnswerSliderWrapper = styled('div')({
    borderRadius: 8,
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '40px',
    minHeight: '40px',
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingBottom: '10px',
    position: 'relative',
});

export const BarAnswerCounter = styled(AnswerCounter)({
    color: colors.darkBlue,
    position: 'absolute',
    top: -20,
    left: '50%',
    transform: 'translateX(-50%)',
    fontWeight: 500,
    fontSize: 10,
});

export const BarBackground = styled(Box)(({ count, maxAnswerValue }) => {
    const barHeight = maxAnswerValue === 0 ? 14 : (count / maxAnswerValue) * 76 + 14;
    const barRounding = count > 0 ? 4 : 0;

    return {
        width: '100%',
        height: `${barHeight}%`,
        borderRadius: `${barRounding}px ${barRounding}px 8px 8px`,

        position: 'absolute',
        bottom: 0,

        backgroundColor: colors.green,
        opacity: 0.1,
    };
});

export const AnswerContainer = styled(Box)(({ height }) => ({
    width: '100%',
    height: height,
}));

export const GraphWrapper = styled(Box)({
    width: 'calc(100% - 56px)',
    height: '100%',
});

export const Graph = styled(Box)(({ answersRange }) => ({
    position: 'relative',
    height: '100%',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateRows: '1fr 24px',
    gridTemplateColumns: `repeat(${answersRange[1] - answersRange[0] + 1}, 1fr)`,

    alignItems: 'end',
    justifyItems: 'center',

    gap: 4,
}));
