import { Box, Typography } from '@mui/material';

export const Bar = ({ markValue, maxAnswerValue }) => {
    const barHeight = markValue === 0 ? '6px' : (markValue / maxAnswerValue) * 80 + '%';

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: barHeight,
                zIndex: 1,

                background: 'linear-gradient(180deg, #F0D857 0%, #12C6AC 100%)',
                borderRadius: '12px 12px 4px 4px',
            }}
        >
            <Typography
                sx={{
                    transform: 'translateX(-50%)',
                    position: 'absolute',
                    top: -20,
                    left: '50%',

                    fontSize: 11,
                    fontWeight: 500,
                }}
            >
                {markValue || ''}
            </Typography>
        </Box>
    );
};
