import React from 'react';
import { Button, styled } from '@mui/material';
import { colors } from '../../../styles/globalStyles';

const SelectorWrap = styled('div')({
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
});

const SelectorButton = styled(Button)(({ activecolor }) => ({
    fontSize: 44,
    fontWeight: 400,
    color: colors.main,
    opacity: 0.2,
    height: 56,
    transition: 'all 0.25s ease',
    '&.active': {
        fontWeight: 800,
        color: activecolor,
        opacity: 1,
    },
    '&:hover': {
        background: 'unset',
    },
}));

const handleSelect = (percentage, sliderRef) => {
    const sliderWidth = sliderRef.current.scrollWidth;
    sliderRef.current?.scrollTo({
        left: sliderWidth * (percentage / 100),
        behavior: 'smooth',
    });
};

// IOVARS – intersection observer state vars
const Selector = ({ ioVars, sliderRef, kssVisible }) => {
    const { isKeepVisible, isStopVisible, isStartVisible } = ioVars;

    const keep = kssVisible.includes('keep');
    const stop = kssVisible.includes('stop');
    const start = kssVisible.includes('start');

    const getPercentage = (kssVisible, kssType) => {
        if (kssVisible.length === 3) {
            if (kssType === 'stop') {
                return 33;
            }
            if (kssType === 'start') {
                return 66;
            }
        }
        if (kssVisible.length === 2) {
            if (kssType === 'stop') {
                if (kssVisible.includes('keep')) return 50;
                return 0;
            }
            if (kssType === 'start') {
                return 50;
            }
        }
    };

    return (
        <SelectorWrap>
            {keep ? (
                <SelectorButton
                    activecolor={colors.purple}
                    className={isKeepVisible ? 'active' : ''}
                    style={{ fontSize: 40 }}
                    // style={{fontSize: 22}}
                    onClick={() => handleSelect(0, sliderRef)}
                >
                    Keep
                    {/* Продолжу */}
                </SelectorButton>
            ) : null}
            {stop ? (
                <SelectorButton
                    activecolor={colors.pink}
                    className={isStopVisible ? 'active' : ''}
                    style={{ fontSize: 40 }}
                    // style={{fontSize: 22}}
                    onClick={() => handleSelect(getPercentage(kssVisible, 'stop'), sliderRef)}
                >
                    Stop
                    {/* Прекращу */}
                </SelectorButton>
            ) : null}
            {start ? (
                <SelectorButton
                    activecolor={colors.lightGreen}
                    className={isStartVisible ? 'active' : ''}
                    style={{ fontSize: 40 }}
                    // style={{fontSize: 22}}
                    onClick={() => handleSelect(getPercentage(kssVisible, 'start'), sliderRef)}
                >
                    Start
                    {/* Начну */}
                </SelectorButton>
            ) : null}
        </SelectorWrap>
    );
};

export default Selector;
