import { Typography } from '@mui/material';
import { colors } from '../styles/globalStyles';

export const StatusBlock = ({ status }) => {
    if (!status) {
        return null;
    }
    return (
        <Typography
            sx={{
                color: status === 'SUCCESS' ? colors.green : colors.red,
            }}
        >
            {status === 'SUCCESS' ? 'Данные успешно сохранены' : null}
            {status === 'ERROR_VAL' ? 'Не все поля заполнены' : null}
            {status === 'ERROR_NET' ? 'Ошибка сети' : null}
        </Typography>
    );
};
