import React, { useState, useEffect } from 'react';
import { Typography, styled, Skeleton, Button } from '@mui/material';
import Dropdown from './dropdowns/dropdown';
import StarOrMessageChip from './chips/starOrMessageChip';
import FeedbackReviewsComment from './feedbackReviewsComment';
import { colors } from '../styles/globalStyles';

const Layout = styled('div')({
    display: 'flex',
    gap: 17,
    padding: 17,
    flexDirection: 'column',
    backgroundColor: '#E4ECFE',
    height: '100%',
});

const Header = styled('header')({
    padding: '10px 10px 0 10px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const TypographyHeader = styled(Typography)({
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '100%',
});

const ChipSection = styled('section')({
    display: 'flex',
    justifyContent: 'center',
    gap: 2,
});

const CommentSection = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 2,
    paddingBottom: 27,
    //
    // [theme.breakpoints.up('sm')]: {
    //     display: 'grid',
    //     gridTemplateColumns: '1fr 1fr',
    // },
});

export const Buttons = styled('nav')({
    marginBottom: 17,
    padding: 3,

    display: 'flex',

    background: colors.transparentMain,
    borderRadius: 23,
});

const StyledButton = styled(Button)({
    width: '100%',
    height: 52,

    fontSize: 12,
    fontWeight: 700,
    lineHeight: '100%',
    color: colors.main,

    borderRadius: 20,

    '&.active': {
        background: colors.main,
        color: colors.white,
        '&:hover': {
            backgroundColor: colors.main,
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0 0)',
        },
    },
    '&:hover': {
        background: colors.transparentMain,
    },
});

const FeedbackReviews = ({ reviews, dropdownData, setSelectedSession, myReviews }) => {
    const [collegeFeed, setCollegeFeed] = useState(true);
    const [reviewData, setReviewData] = useState({});
    useEffect(() => {
        if (reviews) {
            if (collegeFeed) {
                setReviewData({ ...reviews });
            } else {
                setReviewData({ ...myReviews });
            }
        }
    }, [reviews, myReviews]);

    return (
        <Layout>
            <Header>
                <TypographyHeader>Оценки</TypographyHeader>
                {dropdownData?.length > 0 ? (
                    <Dropdown options={dropdownData} setSelectedOption={setSelectedSession} />
                ) : (
                    <Skeleton variant={'rectangular'} />
                )}
            </Header>
            <Buttons
                onClick={() => {
                    setCollegeFeed(!collegeFeed);
                    !collegeFeed ? setReviewData({ ...reviews }) : setReviewData({ ...myReviews });
                }}
            >
                <StyledButton className={collegeFeed ? 'active' : null}>От коллег</StyledButton>
                <StyledButton className={collegeFeed ? null : 'active'}>Оценка себе и KSS</StyledButton>
            </Buttons>
            {reviewData && reviewData['avg-rating'] !== null ? (
                <>
                    <ChipSection>
                        {reviewData.length !== 0 ? (
                            <StarOrMessageChip
                                title="Средняя оценка:"
                                number={reviewData['avg-rating']}
                                background={colors.white}
                            />
                        ) : (
                            <Skeleton variant={'rectangular'} sx={{ height: 52, width: '50%', borderRadius: 27 }} />
                        )}
                        {reviewData.length !== 0 ? (
                            <StarOrMessageChip
                                title="Фидбэк:"
                                messageIcon
                                number={reviewData['feedback-num'] ?? 0}
                                background={colors.white}
                            />
                        ) : (
                            <Skeleton variant={'rectangular'} sx={{ height: 52, width: '50%', borderRadius: 27 }} />
                        )}
                    </ChipSection>
                    <CommentSection>
                        {reviewData.length !== 0 ? (
                            reviewData.score?.map((review, index) => (
                                <FeedbackReviewsComment key={index} {...review} isGray={index === 0 && !collegeFeed} />
                            ))
                        ) : (
                            <Skeleton
                                variant={'rectangular'}
                                sx={{ height: 124, width: '100%', borderRadius: '23px' }}
                            />
                        )}
                    </CommentSection>
                </>
            ) : (
                <Typography sx={{ textAlign: 'center', opacity: 0.5, fontSize: 20 }}>
                    {collegeFeed
                        ? `Вам еще не выставили оценки ваши коллеги, подождите еще немного`
                        : `Вы еще не поставили оценки по этому поведению`}
                </Typography>
            )}
        </Layout>
    );
};

export default FeedbackReviews;
