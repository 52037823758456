import React from 'react';
import { styled } from '@mui/system';
import { colors } from '../styles/globalStyles';
import { Chip, Typography } from '@mui/material';

const Layout = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '307px',
    background: colors.transparentMain,

    borderRadius: 40,

    color: colors.darkBlue,
    letterSpacing: '0.01em',

    overflow: 'hidden',
});

const Header = styled('header')({
    padding: 27,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const TypographyHeader = styled(Typography)({
    marginBottom: 6,

    fontSize: 18,
    fontWeight: 700,
    lineHeight: '100%',
});

const TypographyHeaderOpaque = styled(Typography)({
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '100%',
    opacity: 0.6,
});

const Sessions = styled('main')({
    maxHeight: 216,

    display: 'flex',
});

const NumbersColumn = styled(Typography)({
    paddingTop: 45,
    width: 27,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    fontSize: 14,
    fontWeight: 700,
    lineHeight: '128%',
    textAlign: 'center',

    '& span:last-child': {
        borderBottom: '28px solid rgba(59, 122, 246, 0.14)',
    },
});

const ColumnsWrap = styled('div')({
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    // Firefox scroll bar hidden
    scrollbarWidth: 'none',
});

const SessionColumn = styled('div')({
    paddingInline: 2,

    width: '100%',
    minWidth: 41,

    display: 'flex',
    flexDirection: 'column',

    borderLeft: '1px solid rgba(59, 122, 246, 0.14)',
});

const TypographySessionSection = styled('section')({
    marginBottom: 8,
    flexShrink: 0,
    textAlign: 'center',
    letterSpacing: '0.01em',
});

const TypographySessionNumber = styled(Typography)({
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '100%',
});

const TypographySessionWord = styled(Typography)({
    fontSize: 10,
});

const ColoredBlocks = styled('section')({
    height: 169,

    display: 'flex',
    alignItems: 'end',
    gap: 2,
});

const ColoredBlock = styled('div')({
    content: '""',
    width: '100%',
    borderRadius: '8px 8px 0 0',
});

const FlexSpan = styled('span')({
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    borderBottom: '1px solid rgba(59, 122, 246, 0.14)',
});

const ProgressSessionProgress = ({ type, data, maxScore }) => {
    const lineColors = {
        positive: 'linear-gradient(339.22deg, #1C9FC8 13.67%, #35D789 86.17%)',
        negative: 'linear-gradient(167.89deg, #E19259 8.89%, #E76161 91.22%)',
    };

    return (
        <Layout>
            <Header>
                <section>
                    <TypographyHeader>Все способы поведения</TypographyHeader>
                    <TypographyHeaderOpaque>Средние оценки / Сессия</TypographyHeaderOpaque>
                </section>
                <Chip variant="lightBlue" size="small" label={type === 'user' ? 'Себе' : 'От коллег'} />
            </Header>
            <Sessions>
                <NumbersColumn component="div">
                    {[4, 3, 2, 1].map((number) => (
                        <FlexSpan key={number}>{number}</FlexSpan>
                    ))}
                </NumbersColumn>
                <ColumnsWrap>
                    {data.map((item) => (
                        <SessionColumn key={item.session}>
                            <TypographySessionSection>
                                <TypographySessionNumber>{item.session}</TypographySessionNumber>
                                <TypographySessionWord>Сессия </TypographySessionWord>
                            </TypographySessionSection>
                            <ColoredBlocks>
                                <ColoredBlock
                                    sx={{
                                        background: lineColors.positive,
                                        height: `${(item.scores?.positive / maxScore) * 100}%`,
                                    }}
                                />
                                <ColoredBlock
                                    sx={{
                                        background: lineColors.negative,
                                        height: `${(item.scores?.negative / maxScore) * 100}%`,
                                    }}
                                />
                            </ColoredBlocks>
                        </SessionColumn>
                    ))}
                </ColumnsWrap>
            </Sessions>
        </Layout>
    );
};

export default ProgressSessionProgress;
