import { getScoresTimeLine } from '../api/stats';
import { userForAdminPanelAtom } from '../recoil/atoms/userAtom';

export const getKssTrackerData = async (userId) => {
    let response;

    if (typeof userId === 'number') {
        response = await getScoresTimeLine(userId || null);
    } else {
        const userIdForAdminPanel = localStorage.getItem('user-id-for-admin-panel');
        response = await getScoresTimeLine(userIdForAdminPanel || null);
    }

    if (response.data?.periods?.length === 0) {
        return [];
    }

    let periods = response.data.periods;
    let tempData;

    for (let i = periods.length - 1; i >= 0; i--) {
        tempData = periods[i];
        if (tempData.kss.length !== 0) break;
    }

    if (tempData.kss.length === 0) return [];

    let today = new Date().toISOString().slice(0, 10);
    let todayIndex = -1;

    tempData.days.map((item, idx) => {
        let dayDate = new Date(item.date).toISOString().slice(0, 10);

        if (today === dayDate) todayIndex = idx;
        return item;
    });

    let dateObj = todayIndex === -1 ? tempData.days[tempData.days.length - 1] : tempData.days[todayIndex];
    let newData = tempData.kss.slice().map((item) => {
        let newObj = { ...item };
        newObj.score = dateObj.scores[newObj.status];
        return newObj;
    });

    let sortingArr = ['keep', 'stop', 'start'];
    newData.sort((a, b) => sortingArr.indexOf(a.status) - sortingArr.indexOf(b.status));

    return newData;
};
