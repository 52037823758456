import { styled, Typography } from '@mui/material';
import React from 'react';
import Layout from '../components/Layout';
import SupportCenterFaq from '../components/supportCenterFaq';
import SupportCenterInstruction from '../components/supportCenterInstruction';
import { colors } from '../styles/globalStyles';
import Home from '../images/support-center/1.png';

import Arrow from '../images/support-center/arrow.svg';

import Sessions1 from '../images/support-center/2.1n.png';
import Sessions2 from '../images/support-center/2.2n.png';
import Sessions3 from '../images/support-center/2.3n.png';
import Sessions4 from '../images/support-center/2.4n.png';
import Sessions5 from '../images/support-center/2.5n.png';
import Sessions6 from '../images/support-center/2.6n.png';
import Sessions7 from '../images/support-center/2.7n.png';

import Tracker1 from '../images/support-center/3.1n.png';
import Tracker2 from '../images/support-center/3.1.png';
import Tracker3 from '../images/support-center/3.2.png';

import Progress1 from '../images/support-center/2.2.png';
import Progress2 from '../images/support-center/4.1n.png';
import Progress3 from '../images/support-center/4.2n.png';
import Progress4 from '../images/support-center/4.3n.png';
import Progress5 from '../images/support-center/4.4n.png';

const Title = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '24px',
    marginLeft: 10,
    '& .accent': {
        color: colors.main,
    },
});

const arrowStyles = 'display: block; opacity: .7; margin: 20px auto';

const SupportCenterPage = () => {
    const instructionData = [
        {
            titleText: 'Раздел «Домашний экран»',
            title: 'Раздел <span>«Домашний экран»</span>',
            text: `
                <p>Из этого раздела можно перейти в “Мой прогресс”, “Ежедневный Трекер” (появляется позже в процессе работы), “Сессии”, “Инструкция”.</p>
                <p>На плашке “Фидбек” будут отображаться средние оценки от коллег по положительным и отрицательным способам поведения.</p>
                <img src=${Home} alt='home' width='100%' height='100%' />
                `,
        },
        {
            titleText: 'Раздел «Сессии»',
            title: 'Раздел <span>«Сессии»</span>',
            text: `
                <p>Это самый главный раздел, в котором происходит вся командная работа. В этом разделе вы можете поставить оценки себе и коллегам перед командной встречей.</p>

                <br>
                <p><i>Плашка «Оцените поведение свое и коллег»</i></p>
                <p>Чтобы поставить оценки себе перед командной встречей</p>
                <img src=${Sessions1} alt='sessions' width='100%' height='100%' />
                <img src=${Arrow} alt='arrow' width='24px' height='24px' style='${arrowStyles}' />
                <img src=${Sessions2} alt='sessions' width='100%' height='100%' />

                <p>Чтобы поставить оценки коллегам перед командной встречей</p>
                <img src=${Sessions1} alt='sessions' width='100%' height='100%' />
                <img src=${Arrow} alt='arrow' width='24px' height='24px' style='${arrowStyles}' />
                <img src=${Sessions3} alt='sessions' width='100%' height='100%' />

                <br>
                <p><i>Плашка «Создайте новый KSS на неделю»</i></p>
                <p>В конце командной встречи каждому участнику необходимо составить личный фокус на следующую неделю в виде KSS.</p>
                <img src=${Sessions4} alt='sessions' width='100%' height='100%' />
                <img src=${Arrow} alt='arrow' width='24px' height='24px' style='${arrowStyles}' />
                <img src=${Sessions5} alt='sessions' width='100%' height='100%' />
                <img src=${Arrow} alt='arrow' width='24px' height='24px' style='${arrowStyles}' />
                <img src=${Sessions6} alt='sessions' width='100%' height='100%' />
                <img src=${Arrow} alt='arrow' width='24px' height='24px' style='${arrowStyles}' />
                <img src=${Sessions7} alt='sessions' width='100%' height='100%' />
                <p>После добавления KSS на “Домашнем экране” появляется раздел «Ежедневный Трекер».</p>
            `,
        },
        {
            titleText: 'Раздел «Ежедневный Трекер»',
            title: 'Раздел <span>«Ежедневный Трекер»</span>',
            text: `
                <p>Этот раздел появляется после того, как в конце командной встречи выбран KSS на неделю. “Ежедневный трекер” необходим для личной работы. В конце каждого дня вы оцениваете насколько удавалось сегодня соблюдать свой KSS, проставляя оценку от 0 до 4. Ведется до следующей командной встречи.</p>

                <img src=${Tracker1} alt='tracker' width='100%' height='100%' />
                <img src=${Arrow} alt='arrow' width='24px' height='24px' style='${arrowStyles}' />
                <img src=${Tracker2} alt='tracker' width='100%' height='100%' />
                <img src=${Arrow} alt='arrow' width='24px' height='24px' style='${arrowStyles}' />
                <img src=${Tracker3} alt='tracker' width='100%' height='100%' />
            `,
        },
        {
            titleText: 'Раздел «Мой прогресс»',
            title: 'Раздел <span>«Мой прогресс»</span>',
            text: `
                <p>Тут содержится вся информация о вашем прогрессе на данный момент.</p>
                <br>
                <p><i>“Трекер – KSS”</i></p>
                <p>Он показывает прогресс в работе по выбранным KSS. Разноцветные блоки появляются по мере того, как вы заполняете «Ежедневный Трекер».</p>
                <p>Синий блок – это то, что вы Продолжаете делать до следующей командной встречи.</p>
                <p>Оранжевый – что вы Прекращаете делать до следующей командной встречи.</p>
                <p>Зеленый – что вы Начинаете делать до следующей командной встречи.</p>
                <p>Оранжевые блоки должны быть как можно меньше, а Синие и Зеленые как можно больше.</p>
                <br>
                <img src=${Progress1} alt='progress' width='100%' height='100%' />
                <br>
                <br>

                <p>Нажав на любой блок можно получить всю информацию об этом способе поведения и посмотреть всю историю оценок по нему.</p>
                <img src=${Progress2} alt='progress' width='100%' height='100%' />
                <img src=${Arrow} alt='arrow' width='24px' height='24px' style='${arrowStyles}' />
                <img src=${Progress3} alt='progress' width='100%' height='100%' />
                <br>
                <br>

                <p><i>«Все способы поведения. Себе»</i></p>
                <p>Здесь вы увидите столбчатые диаграммы, которые показывают средние еженедельные оценки по всем положительным и отрицательным способам поведения, которые себе ставили вы.</p> 
                <img src=${Progress4} alt='progress' width='100%' height='100%' />
                <br>
                <br>

                <p><i>«Все способы поведения. От коллег»</i></p>
                <p>Здесь вы увидите столбчатые диаграммы, которые показывают средние еженедельные оценки по всем положительным и отрицательным способам поведения, которые поставили вам ваши коллеги.</p> 
                <img src=${Progress5} alt='progress' width='100%' height='100%' />

            `,
        },
    ];
    const faqData = [
        {
            titleText: 'Какая идея лежит в основе приложения iNTG101?',
            title: '<span>Какая идея лежит</span> в основе приложения iNTG101?',
            text: `
                <p>Улучшая взаимодействие между участниками команды улучшается результативность работы этой команды.</p>
            `,
        },
        {
            titleText: 'Как приложение помогает улучшить взаимодействие?',
            title: 'Как приложение помогает <span>улучшить взаимодействие</span>?',
            text: `
                <p>Взаимодействие улучшается путем самонаблюдения, личной работы над своим поведением и обратной связи со стороны коллег.</p>
            `,
        },
        {
            titleText: 'На основе чего дается обратная связь?',
            title: 'На основе чего дается <span>обратная связь</span>?',
            text: `
                <p>На основе сформулированных командой положительных и отрицательных способов поведения. Именно опираясь на них, участники команды дают друг другу обратную связь и формулируют фокусы личной работы над своим поведением.</p>
            `,
        },
        {
            titleText: 'А приведите примеры положительных способов поведения?',
            title: 'А приведите <span>примеры положительных</span> способов поведения?',
            text: `
                <ul>
                    <li>Говорим кратко и по сути;</li>
                    <li>Дослушиваем собеседника до конца;</li>
                    <li>Уточняем, когда что-то непонятно;</li>
                    <li>Развиваем идеи коллег, не отказываясь от них сразу.</li>
                </ul>
            `,
        },
        {
            titleText: 'А примеры отрицательных способов поведения?',
            title: 'А примеры <span>отрицательных способов</span> поведения?',
            text: `
                <ul>
                    <li>Перебивать друг друга;</li>
                    <li>Обесценивать идеи коллег;</li>
                    <li>Переходить на личности.</li>
                </ul>
            `,
        },
        {
            titleText: 'Вы хотите изменить меня, чтобы я стал удобным для своего босса?',
            title: 'Вы хотите изменить меня, чтобы я стал <span>удобным для своего босса</span>?',
            text: `
                <p>Нет. Наш подход основан на добровольности, осознанности и ненасильственности – ведь вряд ли можно заставить измениться другого без его желания. Мы предоставляем наши знания, опыт и инструменты для изменений, а меняться или нет – это выбор каждого. Причем, этот выбор делаете и вы и ваш босс.</p>
            `,
        },
        {
            titleText: 'Вы хотите поменять меня как личность?',
            title: 'Вы хотите поменять меня как <span>личность</span>?',
            text: `
                <p>Нет. Работа с приложением направлена на изменение поведения и коммуникативных привычек, а не личности. Например, на то, чтобы сильные эмоции перестали выливаться в оскорбления, сотрудники в команде меньше перебивали друг друга, начали подводить итоги обсуждений в конце встреч.</p>
            `,
        },
        {
            titleText: 'А в чем цель работы с приложением?',
            title: 'А в чем <span>цель работы</span> с приложением?',
            text: `
                <p>Научиться быстрее понимать друг друга, тратить меньше времени на принятие решений, а сами решения сделать более точными.</p>
            `,
        },
        {
            titleText: 'Как происходит процесс изменения поведения?',
            title: 'Как происходит <span>процесс изменения</span> поведения?',
            text: `
                <p>Алгоритм изменения мы называем Loops of Change Implementation (Циклы Реализации Изменений) или сокращенно LOCI – читается как имя бога из фильма «Тор»:).</p>

                <p>LOCI состоит из 3 циклов (loops). В целом работа занимает 7 недель.</p>
            `,
        },
        {
            titleText: 'Что такое KSS?',
            title: 'Что такое <span>KSS</span>?',
            text: `
                <p>KSS – позволяет сфокусировать изменения своих привычек на 3 самых необходимых способах поведения:</p>
                <p>K – я Keep (Продолжу) делать то, что у меня уже хорошо получается;</p>
                <p>S – я Stop (Прекращу) делать то, что мешает и мне и другим;</p>
                <p>S – я Start (Начну) делать то, чего мне пока не хватает или отсутствует в моем поведении.</p>
            `,
        },
        {
            titleText: 'Почему работа в командах продолжается 7 недель?',
            title: 'Почему работа в командах продолжается <span>7 недель</span>?',
            text: `
                <p>В среднем именно за этот срок сотруднику удается закрепить 1-2 положительных способа поведения и избавиться от 1-2 отрицательных. Когда такие изменения происходят у всех членов команды, меняется командное взаимодействие как система.</p>
            `,
        },
        {
            titleText: 'А что мы делаем в течение этих 7 недель?',
            title: 'А <span>что мы делаем</span> в течение этих 7 недель?',
            text: `
                <p>На еженедельных командных встречах участники обмениваются с коллегами обратной связью. Это делается, чтобы работать друг с другом стало эффективнее и приятнее. </p>

                <p>Далее участники формулируют личные фокусы до следующей встречи в виде KSS (Keep, Stop, Start).</p>

                <p>Ежедневно участники оценивают личный KSS, который сформулировали в результате встречи в команде. Для этого в конце каждого дня они заходят в приложение и оценивают, насколько сегодня удавалось проявлять поведение, зафиксированное в KSS.</p>
            `,
        },
        {
            titleText: 'Почему важно ставить себе оценки по KSS каждый день?',
            title: 'Почему важно ставить себе оценки по KSS <span>каждый день</span>?',
            text: `
                <p>Повторяемость и регулярность – залог любого успешного изменения. Что ежедневно делаешь, о чем чаще думаешь, тому обучаешься, то и закрепляется.</p>
            `,
        },
        {
            titleText: 'Зачем мне ставить оценки коллегам каждую неделю?',
            title: 'Зачем мне <span>ставить оценки коллегам</span> каждую неделю?',
            text: `
                <p>Оценки показывают насколько, по вашему мнению, коллеги успешно развивают положительные способы поведения и избавляются от отрицательных.</p> 

                <p>Опираясь на оценки и обратную связь от вас, коллега выбирает себе фокус в виде KSS на ежедневную работу в течение следующей недели. 
                </p>
            `,
        },
        {
            titleText: 'Как правильно давать обратную связь?',
            title: 'Как правильно <span>давать обратную связь</span>?',
            text: `
            <ul>
                <li>Говорите о фактах, а не о слухах или догадках;</li>
                <li>Говорите, как это повлияло на вас (“Я почувствовал, что…”); </li>
                <li>И как бы вы хотели, чтобы в будущем поступал коллега в подобных ситуациях;</li>
                <li>Говорите тактично, без обвинений и перехода на личности.</li>
            </ul>
            
            `,
        },
        {
            titleText: 'Остались вопросы?',
            title: '<span>Остались вопросы</span>?',
            text: `
            <p>Напишите в Телеграм: <a href='https://t.me/DmitryShiryaev' target='_blank'>@DmitryShiryaev</a></p>
            
            `,
        },
    ];

    return (
        <Layout link={'/'}>
            <Title>
                <span className="accent">Центр</span> Поддержки
            </Title>
            <SupportCenterInstruction data={instructionData} />
            <SupportCenterFaq data={faqData} />
        </Layout>
    );
};

export default SupportCenterPage;
