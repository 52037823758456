import { Box, styled, Typography } from '@mui/material';
import { colors } from '../../../../styles/globalStyles';

export const GuideContainer = styled(Box)(({ theme }) => ({
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    gap: 32,

    border: `2px solid ${colors.main}`,
    borderRadius: '0 0 40px 40px',

    padding: 12,
    paddingTop: 50,
    paddingBottom: 30,

    transform: 'translateY(-56px)',
    overflow: 'hidden',
    zIndex: 0,

    transition: 'all .7s cubic-bezier(.55,.01,.02,.99)',
}));

export const Caption = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    opacity: 0.8,
    lineHeight: 1.5,
    padding: '0 10px',
}));

export const AccentCaption = styled(Caption)(({ theme }) => ({
    backgroundColor: '#eee',
    borderRadius: 10,
    padding: '12px 20px',
    opacity: 1,
}));

export const GuideTitleBlock = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 12,
}));

export const GuideTitle = styled(Typography)(({ theme }) => ({
    lineHeight: 1.2,

    '& span': {
        color: colors.main,
    },
}));

export const GuideBlock = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
}));

export const GuideInnerBlock = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
}));

export const Coin = styled(Box)(({ theme }) => ({
    minWidth: 28,
    width: 28,
    height: 28,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: 'white',
    fontSize: 14,
    lineHeight: 1,

    backgroundColor: '#3B7AF6',
    borderRadius: 16,
}));
