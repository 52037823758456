import { styled } from '@mui/system';
import { colors } from '../styles/globalStyles';
import { useCallback, useEffect, useMemo } from 'react';
import { debounce } from '../utils';
import { Slider } from '@mui/material';

const SliderWrapper = styled('div')({
    width: '85%',
    margin: '0 auto',
    '.MuiSlider-markLabel': {
        fontSize: '16px',
    },
    '.MuiSlider-root': {
        color: colors.main,
    },
});

const QuestionAnswerSlider = ({ question, onAnswerSelect }) => {
    const { id, min = 0, max = 5 } = question;
    const marks = Array.from({ length: max - min + 1 }, (_, i) => ({ value: min + i, label: min + i }));

    function valuetext(value) {
        return `${value}`;
    }

    useEffect(() => {
        onAnswerSelect(id, min, question.question_type);
    }, [id, min]);

    const debouncedOnAnswerSelect = useMemo(() => debounce(onAnswerSelect, 500), [onAnswerSelect]);

    const handleChangeValue = useCallback(
        (event, value) => {
            debouncedOnAnswerSelect(id, value, question.question_type);
        },
        [debouncedOnAnswerSelect, id],
    );

    return (
        <SliderWrapper>
            <Slider
                aria-label="Always visible"
                defaultValue={min}
                min={min}
                max={max}
                getAriaValueText={valuetext}
                step={1}
                marks={marks}
                onChange={handleChangeValue}
            />
        </SliderWrapper>
    );
};

export default QuestionAnswerSlider;
