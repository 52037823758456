import { Box, styled } from '@mui/system';
import { List, ListItem, Typography } from '@mui/material';
import React, { useEffect } from 'react';

const Wrapper = styled('div')({
    flex: '0 0 100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '50px',
    alignItems: 'center',
    minHeight: '300px',
    width: '100%',
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 200px)',

    overflowY: 'auto',
});

const Title = styled(Typography)({
    fontSize: '30px',
});

export const Caption = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    opacity: 0.8,
    lineHeight: 1.5,
    padding: '0 10px',
}));

export const AccentCaption = styled(Caption)(({ theme }) => ({
    backgroundColor: '#eee',
    borderRadius: 10,
    padding: '12px 20px',
    opacity: 1,
}));

export const StepTitleBlock = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 12,
}));

export const StepTitle = styled(Typography)(({ theme }) => ({
    lineHeight: 1.2,
}));

export const StepBlock = styled(Box)(({ topSpace }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 32,

    marginTop: topSpace ? 32 : 0,
}));

export const StepInnerBlock = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
}));

export const Coin = styled(Box)(({ theme }) => ({
    minWidth: 28,
    width: 28,
    height: 28,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: 'white',
    fontSize: 14,
    lineHeight: 1,

    backgroundColor: '#3B7AF6',
    borderRadius: 16,
}));

const CustomListItem = ({ children }) => {
    return (
        <ListItem
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
            }}
        >
            <Box
                sx={{
                    minWidth: 12,
                    width: 12,
                    height: 12,
                    borderRadius: 10,
                    border: '2px solid #3B7AF6',
                    backgroundColor: 'rgb(238, 242, 252)',
                }}
            />
            {children}
        </ListItem>
    );
};

const QuizInstructions = ({ isLeader, isFirstInstruction, setInstructionSlidesNumber = null }) => {
    useEffect(() => {
        if (!setInstructionSlidesNumber) {
            return;
        }
        console.log('ЗАДАЮ InstructionSlidesNumber', typeof isLeader);

        if (isLeader && isFirstInstruction) {
            setInstructionSlidesNumber(5);
        } else {
            setInstructionSlidesNumber(1);
        }
    }, [isLeader, isFirstInstruction, setInstructionSlidesNumber]);

    if (!isLeader) {
        return isFirstInstruction ? <UserInstructions1 /> : <UserInstructions2 />;
    }

    return isFirstInstruction ? <AdminInstructions1 /> : <AdminInstructions2 />;
};

const UserInstructions1 = () => {
    return (
        <Wrapper>
            <Title variant={'h1'}>Привет!</Title>
            <Caption>
                Твой руководитель попросил тебя пройти небольшой опрос, который поможет нашей команде стать ещё
                эффективнее и создать классную рабочую атмосферу.
            </Caption>

            <AccentCaption>Удели, пожалуйста, пару минут, чтобы ответить&nbsp;на вопросы.</AccentCaption>

            <StepInnerBlock>
                <Caption sx={{ textAlign: 'left' }}>В этом опросе мы хотим узнать:</Caption>
                <List>
                    <CustomListItem>
                        <Caption sx={{ textAlign: 'left' }}>Какие сильные стороны есть у нашей команды.</Caption>
                    </CustomListItem>
                    <CustomListItem>
                        <Caption sx={{ textAlign: 'left' }}>В чём вы можете стать лучше.</Caption>
                    </CustomListItem>
                    <CustomListItem>
                        <Caption sx={{ textAlign: 'left' }}>
                            Что мешает команде работать еще эффективнее и чувствовать себя комфортно.
                        </Caption>
                    </CustomListItem>
                </List>
            </StepInnerBlock>

            <StepInnerBlock>
                <Caption>
                    <strong>Отвечай честно и подробно</strong>. Твои ответы будут <strong>анонимными</strong> и
                    останутся между нами. Твой вклад действительно важен для всей команды!
                </Caption>

                <Caption>
                    После того как все пройдут опрос, мы вместе обсудим результаты и выберем лучшие способы улучшить
                    нашу работу.
                </Caption>

                <AccentCaption>Нажми “Начать”, чтобы приступить к опросу.</AccentCaption>

                <Caption>Спасибо за участие! 😊</Caption>
            </StepInnerBlock>
        </Wrapper>
    );
};

const UserInstructions2 = () => {
    return (
        <Wrapper>
            <Title variant={'h1'}>Спасибо за твое участие! 🎉</Title>
            <Caption>Теперь нужно немного подождать, пока все твои коллеги тоже заполнят свои анкеты.</Caption>
            <List>
                <Caption sx={{ textAlign: 'left' }}>
                    <strong>Что дальше?</strong>
                </Caption>
                <CustomListItem>
                    <Caption sx={{ textAlign: 'left' }}>
                        По мере того как команда проходит опрос, статистика ниже будет обновляться.
                    </Caption>
                </CustomListItem>
                <CustomListItem>
                    <Caption sx={{ textAlign: 'left' }}>
                        Когда все ответят, мы соберём результаты и проведём <strong>командную встречу</strong>.
                    </Caption>
                </CustomListItem>
            </List>
            <Caption sx={{ width: '100%' }}>
                <strong>На встрече мы вместе выберем:</strong>
            </Caption>
            <Caption>
                ✔️ <strong>5-7 положительных способов поведения</strong> – которые помогут команде работать эффективнее.
            </Caption>
            <Caption>
                ❌️ <strong> 5-7 отрицательных способов поведения</strong> – от которых лучше избавиться.
            </Caption>
            <Caption>
                Твои ответы – важный шаг к тому, чтобы сделать нашу команду сильнее, атмосферу лучше, а работу –
                продуктивнее.
            </Caption>
            <Caption>Спасибо, что участвуешь в развитии команды! 💪</Caption>
        </Wrapper>
    );
};

const AdminInstructions1 = () => {
    return (
        <>
            <Wrapper>
                <Title variant={'h1'}>Привет!</Title>
                <Caption>
                    Итак, теперь мы попросим вас заполнить опросник, который поможет вам выбрать способы поведения –
                    привычки, над которыми будет полезно работать вашей команде.
                </Caption>

                <StepBlock>
                    <StepTitleBlock>
                        <Coin>1</Coin>
                        <StepTitle>Сначала пройдите опросник самостоятельно</StepTitle>
                    </StepTitleBlock>

                    <StepInnerBlock>
                        <List>
                            <Caption sx={{ textAlign: 'left' }}>
                                <strong>Отвечайте на вопросы честно и подробно</strong>. Опросник включает вопросы о:
                            </Caption>
                            <CustomListItem>
                                <Caption sx={{ textAlign: 'left' }}>Сильных сторонах вашей команды.</Caption>
                            </CustomListItem>
                            <CustomListItem>
                                <Caption sx={{ textAlign: 'left' }}>Зонах роста и возможностях улучшения.</Caption>
                            </CustomListItem>
                            <CustomListItem>
                                <Caption sx={{ textAlign: 'left' }}>
                                    Препятствиях, которые мешают команде работать более эффективно и создавать хорошую
                                    атмосферу.
                                </Caption>
                            </CustomListItem>
                        </List>
                        <Caption sx={{ textAlign: 'left' }}>
                            <strong>Завершив опрос</strong>, вы получите персональные рекомендации с предложенными
                            способами поведения, которые могут помочь вашей команде повысить эффективность и атмосферу.
                        </Caption>
                    </StepInnerBlock>
                </StepBlock>
            </Wrapper>

            <Wrapper>
                <StepBlock topSpace={true}>
                    <StepTitleBlock>
                        <Coin>2</Coin>
                        <StepTitle>Отправьте опросник членам команды</StepTitle>
                    </StepTitleBlock>

                    <StepInnerBlock>
                        <Caption sx={{ textAlign: 'left' }}>
                            После того, как вы пройдете опросник, вы получите ссылку, чтобы разослать{' '}
                            <strong>опросник</strong> всем участникам вашей команды.
                        </Caption>
                        <Caption sx={{ textAlign: 'left' }}>
                            <strong>Объясните команде цель опроса и установите разумный срок</strong> для прохождения
                            опроса.
                        </Caption>
                    </StepInnerBlock>
                </StepBlock>
            </Wrapper>
            <Wrapper>
                <StepBlock topSpace={true}>
                    <StepTitleBlock>
                        <Coin>3</Coin>
                        <StepTitle>Соберите и проанализируйте результаты</StepTitle>
                    </StepTitleBlock>

                    <StepInnerBlock>
                        <Caption sx={{ textAlign: 'left' }}>
                            <strong>Дождитесь завершения опроса всеми участниками.</strong>
                        </Caption>
                        <Caption sx={{ textAlign: 'left' }}>
                            После этого вы <strong>получите все ответы (анонимно) и рекомендации</strong>, основанные на
                            их ответах.
                        </Caption>
                        <List>
                            <Caption sx={{ textAlign: 'left' }}>Проанализируйте результаты:</Caption>
                            <CustomListItem>
                                <Caption sx={{ textAlign: 'left' }}>
                                    Ищите общие темы и повторяющиеся препятствия.
                                </Caption>
                            </CustomListItem>
                            <CustomListItem>
                                <Caption sx={{ textAlign: 'left' }}>
                                    Обратите внимание на совпадения в предложенных способах поведения.
                                </Caption>
                            </CustomListItem>
                        </List>
                    </StepInnerBlock>
                </StepBlock>
            </Wrapper>
            <Wrapper>
                <StepBlock topSpace={true}>
                    <StepTitleBlock>
                        <Coin>4</Coin>
                        <StepTitle>Проведите командную встречу</StepTitle>
                    </StepTitleBlock>

                    <StepInnerBlock>
                        <Caption sx={{ textAlign: 'left' }}>
                            <strong>Организуйте встречу</strong> со всей командой.
                        </Caption>
                        <List>
                            <Caption sx={{ textAlign: 'left' }}>
                                <strong>Подготовьте материалы</strong>:
                            </Caption>
                            <CustomListItem>
                                <Caption sx={{ textAlign: 'left' }}>Обобщенные результаты опроса.</Caption>
                            </CustomListItem>
                            <CustomListItem>
                                <Caption sx={{ textAlign: 'left' }}>
                                    Список рекомендуемых привычек – способов поведения.
                                </Caption>
                            </CustomListItem>
                        </List>
                        <List>
                            <Caption sx={{ textAlign: 'left' }}>
                                <strong>Обсудите предлагаемые способы поведения</strong>:
                            </Caption>
                            <CustomListItem>
                                <Caption sx={{ textAlign: 'left' }}>
                                    Какие из них наиболее актуальны для вашей команды?
                                </Caption>
                            </CustomListItem>
                            <CustomListItem>
                                <Caption sx={{ textAlign: 'left' }}>Какие принесут наибольшую пользу?</Caption>
                            </CustomListItem>
                        </List>
                        <Caption sx={{ textAlign: 'left' }}>
                            Есть ли идеи о собственных способах поведения или редактировании предложенных? Вы можете
                            внести свои способы поведения.
                        </Caption>
                    </StepInnerBlock>
                </StepBlock>
            </Wrapper>
            <Wrapper>
                <StepBlock topSpace={true}>
                    <StepTitleBlock>
                        <Coin>5</Coin>
                        <StepTitle>Утвердите финальный список способов поведения</StepTitle>
                    </StepTitleBlock>

                    <StepInnerBlock>
                        <List>
                            <CustomListItem>
                                <Caption sx={{ textAlign: 'left' }}>
                                    <strong>5-7 положительных привычек</strong>, которые вы будете внедрять
                                </Caption>
                            </CustomListItem>
                            <CustomListItem>
                                <Caption sx={{ textAlign: 'left' }}>
                                    <strong>5-7 отрицательных привычек</strong>, от которых вы будете избавляться.
                                </Caption>
                            </CustomListItem>
                        </List>

                        <Caption sx={{ textAlign: 'left' }}>
                            <strong>Убедитесь, что все члены команды согласны во сделанным выбором</strong> – коллеги
                            должны понимать и принимать выбранные способы поведения.
                        </Caption>
                    </StepInnerBlock>
                </StepBlock>
            </Wrapper>
        </>
    );
};

const AdminInstructions2 = () => {
    return (
        <Wrapper>
            <Title variant={'h1'}>Отличная работа! 🎉</Title>
            <Caption>
                Вы прошли опросник и теперь лучше понимаете, какие ключевые зоны роста есть у вашей команды и что мешает
                ей работать максимально эффективно.
            </Caption>
            <StepTitle sx={{ padding: '0 10px' }}>Следующий шаг – отправить опросник членам команды.</StepTitle>
            <Caption>
                Чтобы картина была полной, важно узнать, как ситуацию видят ваши коллеги. Их ответы помогут вам выбрать
                способы поведения, которые действительно принесут пользу всей команде.
            </Caption>
            <List>
                <Caption sx={{ textAlign: 'left' }}>
                    <strong>Что делать сейчас?</strong>
                </Caption>
                <CustomListItem>
                    <Caption sx={{ textAlign: 'left' }}>
                        <strong>Скопируйте ссылку на опросник</strong> и разошлите ее всей команде.
                    </Caption>
                </CustomListItem>
                <CustomListItem>
                    <Caption sx={{ textAlign: 'left' }}>
                        <strong>Расскажите участникам, зачем это нужно</strong> – это поможет получить честные и
                        осмысленные ответы.
                    </Caption>
                </CustomListItem>
                <CustomListItem>
                    <Caption sx={{ textAlign: 'left' }}>
                        <strong>Установите срок</strong> для прохождения опроса (например, несколько дней).
                    </Caption>
                </CustomListItem>
                <CustomListItem>
                    <Caption sx={{ textAlign: 'left' }}>
                        <strong>Подождите</strong> пока все пройдут опрос, <strong>контролируйте</strong> выполнение
                        сроков.
                    </Caption>
                </CustomListItem>
            </List>
            <Caption>Спасибо за ваше лидерство! 🚀 Вперед к более сильной и эффективной команде!</Caption>
        </Wrapper>
    );
};

export default QuizInstructions;
