export const getCaption = (value, maxScore) => {
    const captions1 = {
        1: 'никогда',
        2: 'иногда',
        3: '50/50',
        4: 'часто',
        5: 'всегда',
    };
    const captions2 = {
        1: 'никогда',
        2: 'никогда',
        3: 'иногда',
        4: 'иногда',
        5: '50/50',
        6: '50/50',
        7: 'часто',
        8: 'часто',
        9: 'всегда',
        10: 'всегда',
    };

    if (maxScore === 4) {
        return captions1[value + 1];
    } else if (maxScore === 5) {
        return captions1[value];
    } else if (maxScore === 9) {
        return captions2[value + 1];
    } else if (maxScore === 10) {
        return captions2[value];
    }
};
