import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../../../styles/globalStyles';
import { deleteScore, getRatesByBehavior } from '../../../api/score';
import RateButton from './rateButton';
import { createRate, updateRate } from '../../../api/stats';
import { maxScoreAtom } from '../../../recoil/atoms/rateGridAtom';
import { useRecoilValue } from 'recoil';
import CommentWindow from '../commentWindow';
import { createFeedback } from '../../../api/feedback';
import { getCaption } from '../../../util/scores';

const Question = styled(Typography)({
    color: colors.white,
    fontSize: 28,
    textAlign: 'center',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
});

export const GridWrap = styled('div')(({ maxScore }) => {
    const gridLayout =
        maxScore <= 5
            ? {
                  gridTemplateColumns: '1fr 1fr',
                  gridTemplateRows: '1fr 1fr 1fr',
              }
            : {
                  gridTemplateColumns: 'repeat(6,1fr)',
                  gridAutoRows: '1fr',
                  '& > button:not(:nth-last-of-type(2))': {
                      gridColumn: 'span 2',
                  },
                  '& > button:nth-last-of-type(-n+2)': {
                      gridColumn: 'span 3',
                  },
              };
    return {
        padding: '17px',
        display: 'grid',
        ...gridLayout,
        gap: 2,
        height: '460px',
        flexShrink: 0,
    };
});

const SaveButton = styled(Button)({
    background: colors.transparentWhite,
    borderRadius: 23,
    color: 'white',

    '&.light': {
        background: colors.transparentMain,
        color: colors.main,
    },
    '&.transparent': {
        background: colors.transparentWhite,
        color: colors.white,
    },

    '&:focus': {
        background: 'white',
        color: colors.main,
    },
    '&:active': {
        background: 'white',
        color: colors.main,
    },
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
});

export const Label = styled(Typography)({
    color: 'inherit',
    fontSize: 34,
    lineHeight: '34px',
});

const getRateMapping = (maxScore) => {
    switch (maxScore) {
        case 4:
            return [0, 1, 2, 3, 4];
        case 5:
            return [1, 2, 3, 4, 5];
        case 9:
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        case 10:
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        default:
            console.log('error I need maxScore');
    }
};

const RateGrid = ({ onClose, selectedType, setSelectedData }) => {
    const maxScore = useRecoilValue(maxScoreAtom);

    const maxScoreArray = getRateMapping(maxScore);
    const [behavior, setBehavior] = useState(selectedType);
    const [loading, setLoading] = useState(false);
    const [openComment, setOpenComment] = useState(false);
    const [inputValue, setInputValue] = useState(selectedType?.feedback_text || selectedType.score?.feedback_text);

    const handleRate = (value) => {
        if (behavior.score?.result !== value) {
            setBehavior({
                ...behavior,
                score: {
                    id: selectedType.score?.id ? selectedType.score.id : null,
                    result: value,
                },
                feedback_text: inputValue,
            });
        } else {
            // "mark rate to be deleted"
            setBehavior({
                ...behavior,
                score: {
                    id: selectedType.score?.id ? selectedType.score.id : null,
                    result: -1,
                },
            });
        }
    };

    const saveRate = (withFeedback) => {
        if (loading) return;

        setLoading(true);
        if (!selectedType.belong) selectedType = { ...selectedType, belong: selectedType.user };
        getRatesByBehavior(selectedType.behavior, selectedType.belong)
            .then((response) => {
                if (behavior.score?.result !== -1) {
                    // if rate already exists
                    if (response.data.result !== undefined) {
                        updateRate(behavior)
                            .then((response) => {
                                if (response.status === 200) {
                                    setSelectedData({ ...behavior, feedback_text: inputValue });
                                }
                                if (inputValue && withFeedback) {
                                    createFeedback(response.data.id, inputValue)
                                        .then(setInputValue(''))
                                        .catch((err) => {
                                            console.error('Ошибка создания обратной связи', err);
                                        });
                                }
                                onClose();
                                setLoading(false);
                            })
                            .catch((err) => {
                                console.error('Ошибка обновления оценок', err);
                            });
                    } else {
                        createRate(behavior)
                            .then((response) => {
                                if (response.status === 200)
                                    setSelectedData({
                                        ...behavior,
                                        feedback_text: inputValue,
                                        score: { id: response.data.id, result: behavior.score.result },
                                    });
                                if (inputValue && withFeedback) {
                                    createFeedback(response.data.id, inputValue)
                                        .then(setInputValue(''))
                                        .catch((err) => {
                                            console.error('Ошибка создания обратной связи', err);
                                        });
                                }
                                onClose();
                                setLoading(false);
                            })
                            .catch((err) => {
                                console.error('Ошибка создания оценки', err);
                            });
                    }
                } else {
                    deleteScore(response.data.id)
                        .then((response) => {
                            if (response.status === 204) setSelectedData({ ...behavior, score: null });
                            onClose();
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.error('Ошибка удаления оценки', err);
                        });
                }
            })
            .catch((err) => {
                console.error('Ошибка получения оценок по поведениям', err);
            });
    };

    return (
        <>
            {openComment ? (
                <CommentWindow
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    onClick={saveRate}
                    selfFeedback={true}
                />
            ) : (
                <GridWrap maxScore={maxScore}>
                    {maxScoreArray.map((item) => (
                        <RateButton
                            className={behavior?.score?.result === item ? 'selected' : ''}
                            variant={behavior?.status ? 'transparent' : 'light'}
                            value={item}
                            maxScore={maxScore}
                            onClick={() => handleRate(item)}
                        />
                    ))}
                    <SaveButton
                        disabled={loading}
                        className={behavior?.status ? 'transparent' : 'light'}
                        id={'save-button'}
                        name={'save-button'}
                        // onClick={saveRate}
                        onClick={(e) => setOpenComment(true)}
                    >
                        <Label style={{ fontSize: 25 }}>Сохранить</Label>
                    </SaveButton>
                </GridWrap>
            )}
        </>
    );
};

export const Rate = ({ value, maxScore }) => {
    return (
        <div>
            <Label variant={'h1'}>{value}</Label>
            <div>{getCaption(value, maxScore)}</div>
        </div>
    );
};

export default RateGrid;
