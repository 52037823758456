import React from 'react';
import { Avatar, Box, styled, Typography, Link, Card, CardActionArea } from '@mui/material';
import { colors } from '../styles/globalStyles';
import { Plot, PlotBar, Progress } from './mySessionsWeekProgress';
import { useNavigate } from 'react-router-dom';
import { getBarHeight } from '../util/render';

const Wrapper = styled('div')({
    height: 226,
    display: 'flex',
    flexDirection: 'column',

    background: 'rgba(59, 122, 246, 0.07)',
    borderRadius: 40,
    overflow: 'hidden',
});

const AvatarAndUsername = styled('div')({
    marginBottom: 10,

    display: 'flex',
    alignItems: 'center',
    gap: 8,

    color: colors.main,
});

const Username = styled(Typography)({
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '125%',
});

const TeamContainer = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 17,
});

const Team = styled(Typography)({
    // flexGrow: 1,
    padding: '4px 8px',
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    background: 'rgba(59, 122, 246, 0.14)',
    borderRadius: 23,
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '100%',
    color: colors.main,
});

const Percentage = styled(Typography)({
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '128%',
    color: colors.main,
});

const SearchUserProgress = ({ userData, admin = false, setOpen, setSelectedUser }) => {
    const navigate = useNavigate();
    const plotColors = [
        { color: colors.gradient.bg.keep, result: userData.score.keep },
        { color: colors.gradient.bg.stop, result: userData.score.stop },
        { color: colors.gradient.bg.start, result: userData.score.start },
    ];

    const handleCardClick = (e) => {
        e.preventDefault();
        if (admin) {
            setOpen(true);
            setSelectedUser({ ...userData });
        } else {
            navigate(`/teammate-behaviors/${userData.id}`);
        }
    };
    return (
        <Card
            onClick={(e) => {
                handleCardClick(e);
            }}
            style={{ borderRadius: 40, height: 226 }}
        >
            <CardActionArea>
                <Wrapper>
                    <Box padding={'17px 17px 0'} mb={'6px'}>
                        <AvatarAndUsername>
                            <Avatar src={`https://app.intg101.work${userData.avatar}`} alt={'avatar'} />
                            <Username>{userData.first_name + ' ' + userData.last_name}</Username>
                        </AvatarAndUsername>
                        <TeamContainer>
                            <Team>{userData.teams[0].name}</Team>
                        </TeamContainer>
                        <Progress>Прогресс по KSS</Progress>
                        <Percentage>{userData.score.progress + '%'}</Percentage>
                    </Box>
                    <Plot>
                        <>
                            {plotColors.map((plot, index) => (
                                <PlotBar
                                    key={index}
                                    sx={{
                                        height: getBarHeight(plot.result, userData.score.max, 8),
                                        background: plot.color,
                                    }}
                                />
                            ))}
                        </>
                    </Plot>
                </Wrapper>
            </CardActionArea>
        </Card>
    );
};

export default SearchUserProgress;
