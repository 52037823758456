import { axiosInstance } from './axiosInstance';
import axios from 'axios';
import { SERVER_URI_API } from './urls';

export const authUser = (username, password, telegramId) =>
    axios.post(
        `${SERVER_URI_API}/auth/login/`,
        {
            username: username,
            password: password,
            telegramId: telegramId,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

export const authTelegramUser = (telegram_id) =>
    axios.post(
        `${SERVER_URI_API}/auth/telegram/login/`,
        {
            telegram_id: telegram_id,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

export const registerUser = (userData) =>
    axios.post(
        `${SERVER_URI_API}/auth/telegram/register/`,
        {
            ...userData,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

export const getUserData = (userId) => {
    let options = {};

    if (userId) {
        options = { params: { user_id: userId } };
    }

    const response = axiosInstance.get(`auth/current_user/`, options);

    if (response.is_access_finished) {
        window.location.href = '/trial-end';
    }

    return response;
};
