import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Modal, styled, Typography } from '@mui/material';
import { Label, Rate, GridWrap } from './modals/rateGrid/rateGrid';
import { colors, bgColors } from '../styles/globalStyles';
import { ReactComponent as ModalInfoIcon } from '../images/modal_info.svg';
import { createScore, deleteScore, updateScore } from '../api/score';
import { createFeedback } from '../api/feedback';
import RootModal from './modals/rootModal';
import { maxScoreAtom } from '../recoil/atoms/rateGridAtom';
import { useRecoilState, useRecoilValue } from 'recoil';
import CommentWindow from './modals/commentWindow';
import FeedbackModal from './feedbackModal';
import { kssDataAtom } from '../recoil/atoms/userAtom';
import { behaviorsDataAtom } from '../recoil/atoms/mySessionPageAtoms';
import { getFeedbackValuesData } from '../api/user';

const Breadcrumbs = styled('nav')({
    padding: '0 0 17px 10px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const StyledModalInfoIcon = styled(ModalInfoIcon)({
    marginRight: 10,
    width: 36,
    height: 36,
    color: colors.main,
    cursor: 'pointer',
});

const UserInfo = styled('div')({
    marginBottom: 17,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
});

const Header = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'space-between',
});

const Username = styled(Typography)({
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '100%',
    color: colors.main,
});

const Behavior = styled(Typography)({
    marginBottom: 17,
    fontWeight: 800,
    // fontSize: 34,
    lineHeight: '120%',
    textAlign: 'center',
});

const RateButton = styled(Button)({
    background: colors.transparentMain,
    borderRadius: 23,
    color: colors.main,

    '&:active': {
        background: 'linear-gradient(0deg, rgba(246, 182, 59, 0.1), rgba(246, 182, 59, 0.1)), #FFFFFF',
        border: '4px solid rgba(246, 182, 59, 0.36)',
        color: colors.yellow,
    },
    '&:hover': {
        backgroundColor: colors.transparentMain,
    },

    '&.selected': {
        background: 'linear-gradient(0deg, rgba(246, 182, 59, 0.1), rgba(246, 182, 59, 0.1)), #FFFFFF',
        border: '4px solid rgba(246, 182, 59, 0.36)',
        color: colors.yellow,
    },
});

const StyledButton = styled(Button)({
    background: colors.main,
    color: colors.white,
    borderRadius: 23,

    '&:focus': {
        background: 'white',
        color: colors.main,
    },
    '&:active': {
        background: 'white',
        color: colors.main,
    },
});

const CommentInput = styled('textarea')({
    marginBottom: 2,
    padding: 17,
    width: '100%',
    height: '44.5vh',

    border: '2px solid rgba(59, 122, 246, 0.24)',
    borderRadius: 23,

    fontFamily: 'Onest',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '150%',
});

const TeammateBehaviorsModal = ({ open, onClose, behavior, belong, userData, fetchData }) => {
    const [selected, setSelected] = useState(behavior.score?.result);
    const [commentWindow, setCommentWindow] = useState(false);
    const [inputValue, setInputValue] = useState(behavior?.feedback_text);
    const [infoOpen, setInfoOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const maxScore = useRecoilValue(maxScoreAtom);
    const [, setUserKssData] = useRecoilState(kssDataAtom);
    const [, setBehaviorsData] = useRecoilState(behaviorsDataAtom);

    const getRateMapping = (maxScore) => {
        switch (maxScore) {
            case 4:
                return [0, 1, 2, 3, 4];
            case 5:
                return [1, 2, 3, 4, 5];
            case 9:
                return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            case 10:
                return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            default:
                console.log('error I need maxScore');
        }
    };

    const handleButtonClick = (withFeedback) => {
        // Если уже меняется оценка
        if (loading) return;
        setLoading(true);
        // Если не выбрана оценка вообще
        if (selected === null || selected === undefined) {
            onClose();
            return;
        }

        // Если оценки ещё нет
        if (behavior.score === null) {
            if (selected === -1) return;
            createScore(belong, behavior.id, selected)
                .then((r) => {
                    fetchData();
                    setCommentWindow(false);

                    if (inputValue && withFeedback) {
                        createFeedback(r.data.id, inputValue)
                            .then(setInputValue(''))
                            .catch((err) => {
                                console.error('Ошибка создания обратной связи', err);
                            });
                    }

                    setUserKssData((prev) =>
                        prev.map((userKSSDataItem) =>
                            userKSSDataItem.behavior === behavior.id
                                ? {
                                      ...userKSSDataItem,
                                      score: { result: selected },
                                      feedback_text: inputValue && withFeedback ? inputValue : '',
                                  }
                                : userKSSDataItem,
                        ),
                    );

                    onClose();
                    setLoading(false);

                    getFeedbackValuesData()
                        .then((feedbackValues) => {
                            setBehaviorsData(feedbackValues.data.values);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.error('Ошибка создания оценки', err);
                });
            return;
        }

        if (selected === -1) {
            // Если оценка удаляется
            deleteScore(behavior.score.id)
                .then((r) => {
                    fetchData();
                    setCommentWindow(false);

                    if (inputValue && withFeedback)
                        createFeedback(r.data.id, inputValue)
                            .then(setInputValue(''))
                            .catch((err) => {
                                console.error('Ошибка создания обратной связи', err);
                            });

                    onClose();
                    setLoading(false);

                    setUserKssData((prev) =>
                        prev.map((userKSSDataItem) =>
                            userKSSDataItem.behavior === behavior.id
                                ? {
                                      ...userKSSDataItem,
                                      score: { result: selected },
                                      feedback_text: inputValue && withFeedback ? inputValue : '',
                                  }
                                : userKSSDataItem,
                        ),
                    );

                    getFeedbackValuesData()
                        .then((feedbackValues) => {
                            setBehaviorsData(feedbackValues.data.values);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.error('Ошибка удаления оценки', err);
                });
        } else {
            // Если оценка обновляется
            updateScore(behavior.score.id, belong, behavior.id, selected)
                .then((r) => {
                    fetchData();
                    setCommentWindow(false);

                    if (inputValue && withFeedback) createFeedback(r.data.id, inputValue).then(setInputValue(''));

                    onClose();
                    setLoading(false);

                    setUserKssData((prev) =>
                        prev.map((userKSSDataItem) =>
                            userKSSDataItem.behavior === behavior.id
                                ? {
                                      ...userKSSDataItem,
                                      score: { result: selected },
                                      feedback_text: inputValue && withFeedback ? inputValue : '',
                                  }
                                : userKSSDataItem,
                        ),
                    );

                    getFeedbackValuesData()
                        .then((feedbackValues) => {
                            setBehaviorsData(feedbackValues.data.values);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.error('Ошибка обновления оценки', err);
                });
        }
    };

    const handleButtonToFeedback = (e) => {
        e.preventDefault();
        if (selected === null || selected === undefined) return;
        setCommentWindow(true);
    };

    const getFont = (text) => {
        if (!text) return;
        if (text.length < 65) return 30;
        if (text.length < 80) return 26;
        if (text.length < 120) return 24;
        if (text.length < 160) return 22;
        if (text.length < 200) return 20;
        return 16;
    };

    return (
        <RootModal open={open} onClose={onClose} info={true} infoOpen={infoOpen} setInfoOpen={setInfoOpen}>
            <Header>
                <UserInfo>
                    {userData?.avatar !== undefined ? (
                        <Avatar
                            src={`https://app.intg101.work${userData.avatar}`}
                            alt={'avatar'}
                            sx={{ width: 24, height: 24 }}
                        />
                    ) : null}
                    {userData && userData['first-name'] && userData['last-name'] ? (
                        <Username>{`${userData['first-name']} ${userData['last-name']}`}</Username>
                    ) : null}
                </UserInfo>
                <Behavior style={{ fontSize: getFont(behavior.title) }}>{behavior.title}</Behavior>
            </Header>
            {commentWindow ? (
                <CommentWindow inputValue={inputValue} setInputValue={setInputValue} onClick={handleButtonClick} />
            ) : (
                <GridWrap maxScore={maxScore}>
                    {getRateMapping(maxScore).map((value) => (
                        <RateButton
                            key={value}
                            className={value === selected ? 'selected' : ''}
                            onClick={() => {
                                if (selected !== value) {
                                    setSelected(value);
                                } else {
                                    setSelected(-1);
                                }
                            }}
                        >
                            <Rate value={value} maxScore={maxScore} />
                        </RateButton>
                    ))}
                    <StyledButton id={'save-button'} name={'save-button'} onClick={(e) => handleButtonToFeedback(e)}>
                        <Label style={{ fontSize: 25 }}>Сохранить</Label>
                    </StyledButton>
                </GridWrap>
            )}
            {infoOpen ? (
                <FeedbackModal
                    behaviorData={behavior}
                    open={infoOpen}
                    setIsOpen={setInfoOpen}
                    background={bgColors.blue}
                />
            ) : null}
        </RootModal>
    );
};

export default TeammateBehaviorsModal;
