import { axiosInstance } from './axiosInstance';

export const createClientLog = (error) =>
    axiosInstance({
        method: 'POST',
        url: '/logs/error/',
        data: {
            function: 'Client Error.',
            text: error,
        },
    });

export const getAllCompaniesData = () =>
    axiosInstance({
        method: 'GET',
        url: '/management/company/',
    });

export const createSessionForTeam = (data) =>
    axiosInstance({
        method: 'POST',
        url: '/management/sessions/',
        data: {
            start: new Date(data.start),
            end: new Date(data.end),
            next_date: new Date(data.next),
            meeting_date: new Date(data.meetingDate),
            meeting_link: data.meetingLink,
            team: data.teamId,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const updateSessionForTeam = (data) =>
    axiosInstance({
        method: 'PUT',
        url: '/management/sessions/',
        data: {
            id: data.id,
            start: new Date(data.start),
            end: new Date(data.end),
            next_date: new Date(data.next),
            meeting_date: new Date(data.meetingDate),
            meeting_link: data.meetingLink,
            team: data.teamId,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const getSessionHistory = (teamId) =>
    axiosInstance({
        method: 'GET',
        url: '/management/sessions/',
        params: {
            team_id: teamId,
        },
    });

export const getTeamStats = (teamId) =>
    axiosInstance({
        method: 'GET',
        url: '/management/team/',
        params: {
            team_id: teamId,
        },
    });

export const createUser = (data) =>
    axiosInstance({
        method: 'POST',
        url: '/management/user/',
        data: {
            username: data.username,
            password: data.password,
            first_name: data['first_name'],
            last_name: data['last_name'],
            // "email": data.email,
            team: data.team,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const changeUser = (data) =>
    axiosInstance({
        method: 'PUT',
        url: '/management/user/',
        data: {
            id: data.id,
            username: data.username,
            password: data.password,
            first_name: data['first_name'],
            last_name: data['last_name'],
            team: data.team,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const getMaxScore = (teamId) =>
    axiosInstance({
        method: 'GET',
        url: '/management/max_score',
        params: {
            team_id: teamId,
        },
    });

export const setMaxScore = (data) =>
    axiosInstance({
        method: 'POST',
        url: '/management/max_score/',
        params: {
            team_id: data.teamId,
        },
        data: {
            new_max_score: data.maxScoreValue,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const getTeamValues = (teamId) =>
    axiosInstance({
        method: 'GET',
        url: '/management/value',
        params: {
            team_id: teamId,
        },
    });

export const getTeamInfo = (teamId) =>
    axiosInstance({
        method: 'GET',
        url: '/management/team/info',
        params: {
            team_id: teamId,
        },
    });

export const updateTeamValues = (data, teamId) =>
    axiosInstance({
        method: 'POST',
        url: '/management/value/',
        params: {
            team_id: teamId,
        },
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const postTeamValues = (data) =>
    axiosInstance({
        method: 'POST',
        url: '/survey/apply_behaviors/',
        data: data,
    });

export const getTeamSurveyResults = (teamId) =>
    axiosInstance({
        method: 'GET',
        url: 'survey/user_answers/',
        params: {
            team_id: teamId,
        },
    });

export const getExcel = (teamId) =>
    axiosInstance({
        method: 'GET',
        url: 'management/excel-analytics',
        responseType: 'blob',
        params: {
            team_id: teamId,
        },
    });

export const disableUser = (userId) =>
    axiosInstance({
        method: 'PUT',
        url: '/management/user/disable',
        data: {
            user: userId,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const updateStep = (step) =>
    axiosInstance({
        method: 'POST',
        url: '/team/update_step/',
        data: {
            step: step,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
