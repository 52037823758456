import React from 'react';
import { Chip, styled, Typography, Button } from '@mui/material';
import Bg from '../../../images/bg.png';
import { colors } from '../../../styles/globalStyles';
import { ReactComponent as ArrowRight } from '../../../images/arrow_right.svg';

const Wrap = styled(Button)({
    padding: '38.5px 32px',
    borderRadius: 40,
    width: '100%',
    display: 'flex',
    gap: 10,
});

const StyledChip = styled(Chip)({
    minWidth: 95,
    cursor: 'pointer',
    background: colors.transparentWhite,
    borderRadius: 23,
    padding: 17,
    display: 'flex',
    justifyContent: 'center',
    height: 'auto',
    width: '100%',

    color: colors.white,
    fontSize: 16,
    lineHeight: '110%',
    fontWeight: 400,
    '& span': {
        textAlign: 'center',
        padding: 0,
    },
});

const TimeWrap = styled('div')({
    // cursor: 'pointer',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
});

const Label = styled(Typography)({
    textAlign: 'start',
    paddingLeft: 10,
    fontSize: 14,
    color: colors.white,
    opacity: 0.6,
});

const ArrowWrap = styled('div')({
    minWidth: 52,
    height: 52,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    background: colors.white,
    borderRadius: 23,

    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
});

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'short' };
    const formatter = new Intl.DateTimeFormat('ru-RU', options);
    return formatter.format(date);
};

const SessionCard = ({ data, active = false, onClick }) => {
    return (
        <Wrap
            style={{
                background: active
                    ? `url(${Bg}), linear-gradient(27.72deg, #3B4EF6 23.24%, #3BC9F6 100%)`
                    : `url(${Bg}), radial-gradient(140.07% 100% at 0% 100%, #9C17CB 53.17%, #CB1799 100%)`,
            }}
            onClick={(e) => onClick(e, data)}
        >
            <TimeWrap>
                <Label varianht={'h3'}>Начало</Label>
                <StyledChip label={formatDate(data.start)} />
            </TimeWrap>
            <TimeWrap>
                <Label>Конец</Label>
                <StyledChip label={formatDate(data.end)} />
            </TimeWrap>
            <ArrowWrap>
                <ArrowRight fill="#3BC9F6" />
            </ArrowWrap>
        </Wrap>
    );
};

export default SessionCard;
