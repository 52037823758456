import { atom, selector } from 'recoil';
import { getUserData } from '../../api/auth';

const AdminUserSelector = selector({
    key: 'adminUserSelector',
    get: async () => {
        try {
            const response = await getUserData();

            return {
                isStaff: response.data.is_staff,
                appAdminPanel: response.data.app_admin_panel,
                areValuesApproved: response.data.team.are_values_approved,
                isSessionCreated: response.data.team.is_session_created,
                avaliableSteps: response.data.team.avaliable_steps,
                currentUserNumber: response.data.team.current_users_number,
                allUserNumber: response.data.team.number_of_users,
            };
        } catch (err) {}
    },
});

export const AdminUserAtom = atom({
    key: 'adminUser',
    default: AdminUserSelector,
});
