import { Card, CardActionArea, IconButton, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ReactComponent as ArrowRight } from '../images/arrow_right.svg';
import Bg from '../images/bg_button_wide.png';
import { searchModalOpenAtom } from '../recoil/atoms/mySessionPageAtoms';
import { colors } from '../styles/globalStyles';
import MySessionsKssChoice from './mySessionsKSSChoice';
import SearchModal from './searchModal';

const Layout = styled(Card)({
    backgroundBlendMode: 'color-burn, normal',
    backgroundSize: 'cover',
    borderRadius: 40,
});

const Main = styled('main')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const TypographyMain = styled(Typography)({
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '110%',
    color: colors.white,
});

const WhiteRoundButton = styled(IconButton)({
    width: 52,
    height: 52,
    background: colors.white,
    borderRadius: 23,

    '&:hover': {
        background: 'rgba(255, 255, 255, 0.8)',
    },
});

const MySessionsTopButton = ({ KSS = false, update = false }) => {
    const KSStext = 'Создайте новый KSS на неделю';
    const KSSUpdatetext = 'Изменить KSS на неделю';
    const searchText = 'Оцените поведение свое и коллег';

    const [isModalOpen, setIsModalOpen] = useState(false);
    const setSearchModalOpen = useSetRecoilState(searchModalOpenAtom);

    return (
        <Layout
            style={{
                background: KSS
                    ? `url(${Bg}), radial-gradient(134.46% 100% at 12.22% 0%, #16C6ED 10.42%, #3B42F6 100%)`
                    : `url(${Bg}), radial-gradient(134.46% 100% at 12.22% 0%, #EDB116 10.42%, #F68A3B 100%)`,
            }}
            onClick={() => {
                KSS ? setIsModalOpen(true) : setSearchModalOpen(true);
            }}
        >
            <CardActionArea component="div" style={{ padding: 27, borderRadius: 40 }}>
                <Main>
                    <TypographyMain>{KSS ? (update ? KSSUpdatetext : KSStext) : searchText}</TypographyMain>
                    <WhiteRoundButton>
                        <ArrowRight fill={KSS ? '#3C43F6' : '#F68A3B'} />
                    </WhiteRoundButton>
                </Main>
                {KSS ? (
                    <MySessionsKssChoice
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        isUpdateModel={update}
                    />
                ) : (
                    <SearchModal />
                )}
            </CardActionArea>
        </Layout>
    );
};

export default MySessionsTopButton;
