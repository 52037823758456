import { axiosInstance } from './axiosInstance';

export const getUserBehaviors = (userId) =>
    axiosInstance.get('user/get_value_list', {
        params: {
            user: userId,
        },
    });

export const getFeedbackValuesData = (user, from) => {
    const userIdForAdminPanel = localStorage.getItem('user-id-for-admin-panel');
    const userId = localStorage.getItem('user-id');

    if (from) {
        return axiosInstance.get('user/get_value_list_with_feedback', {
            params: {
                user: user || userIdForAdminPanel || userId,
                from: from,
            },
        });
    } else {
        return axiosInstance.get('user/get_value_list_with_feedback', {
            params: {
                user: user || userIdForAdminPanel || userId,
            },
        });
    }
};

export const getAvatar = () => axiosInstance.get('user/get_avatar/');

export const updateAvatar = (data) => axiosInstance.post('user/update_avatar/', data);
