import { ReactComponent as Star0 } from '../images/0star.svg';
import { ReactComponent as Star1 } from '../images/1star.svg';
import { ReactComponent as Star2 } from '../images/2stars.svg';
import { ReactComponent as Star3 } from '../images/3stars.svg';
import { ReactComponent as Star4 } from '../images/4stars.svg';
import { ReactComponent as Star5 } from '../images/5stars.svg';
import { ReactComponent as Star6 } from '../images/6stars.svg';
import { ReactComponent as Star7 } from '../images/7stars.svg';
import { ReactComponent as Star8 } from '../images/8stars.svg';
import { ReactComponent as Star9 } from '../images/9stars.svg';
import { ReactComponent as Star10 } from '../images/10stars.svg';
const renderStars = (value) => {
    switch (value) {
        case -1:
            return null;
        case 0:
            return <Star0 />;
        case 1:
            return <Star1 />;
        case 2:
            return <Star2 />;
        case 3:
            return <Star3 />;
        case 4:
            return <Star4 />;
        case 5:
            return <Star5 />;
        case 6:
            return <Star6 style={{ width: '70%' }} />;
        case 7:
            return <Star7 style={{ width: '70%' }} />;
        case 8:
            return <Star8 style={{ width: '70%' }} />;
        case 9:
            return <Star9 style={{ width: '70%' }} />;
        case 10:
            return <Star10 style={{ width: '60%' }} />;
    }
};

export default renderStars;
