import { styled } from '@mui/system';
import { Loader } from './loaders/loader';
import { colors } from '../styles/globalStyles';

const Wrapper = styled('div')({
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
});

const Preloader = () => {
    return (
        <Wrapper>
            <Loader size="large" color={colors.main} />
        </Wrapper>
    );
};

export default Preloader;
