import { styled } from '@mui/material';
import { colors } from '../../styles/globalStyles';

export const LoaderWrapper = styled('div')(() => ({
    width: '100%',
    height: 300,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const Title = styled('div')({
    lineHeight: 1.5,
    letterSpacing: '0.01rem',
    fontWeight: 700,
    fontSize: 14,
    fontFamily: 'Onest',
    color: colors.darkBlue,
    padding: '10px 7px',
});

export const AnswerItem = styled('div')({
    padding: 20,
    borderRadius: 23,
    backgroundColor: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    overflowX: 'hidden',
});

export const AnswerText = styled('div')({
    lineHeight: 1,
    letterSpacing: '0.01rem',
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'Onest',
    color: colors.darkBlue,
});
