import { bgColors, colors, theme } from '../styles/globalStyles';
import { styled } from '@mui/system';
import { Button, TextField, Typography } from '@mui/material';
import { ReactComponent as ArrowLeft } from '../images/left_arrow.svg';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import intg101Logo from '../images/intg101_logo_color.svg';
import { getUserData, registerUser } from '../api/auth';
import { useSetRecoilState } from 'recoil';
import { userInfoAtomArr } from '../recoil/atoms/userAtom';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';

const Layout = styled('div')(({ background }) => ({
    padding: 17,
    minHeight: '100vh',

    display: 'flex',
    flexDirection: 'column',
    gap: '30px',

    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    borderRadius: 0,

    color: colors.white,

    [theme.breakpoints.up('xs')]: {
        maxWidth: 390,
        margin: '0 auto',
        borderRadius: 23,
    },
}));

const Header = styled('header')({
    paddingTop: 11,
    // paddingLeft: 11,

    display: 'flex',
    alignItems: 'center',
    gap: 11,
});

const RegistrationSection = styled('section')({
    display: 'flex',
    flexDirection: 'column',
    gap: '17px',
});

const Label = styled(Typography)({
    fontSize: 30,
    lineHeight: '120%',
});

const LabelCaption = styled(Typography)({
    fontSize: 12,
    lineHeight: 1.5,
    opacity: 0.7,
});

const Input = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 10,
});

const ContinueButton = styled(Button)({
    width: '100%',
    height: 64,
    padding: '14px 17.5px',
    borderRadius: 23,
});

const RegistrationPage = () => {
    let navigate = useNavigate();

    const bgColor = bgColors.purpleBlue;
    const color = colors.purple;

    const [companyName, setCompanyName] = useState('');
    const [name, setName] = useState(window.Telegram?.WebApp?.initDataUnsafe?.user?.first_name || '');
    const [role, setRole] = useState('');
    const [teamName, setTeamName] = useState('');
    const [participantsNumber, setParticipantsNumber] = useState('');

    const setUserInfo = useSetRecoilState(userInfoAtomArr);
    const setAdminUser = useSetRecoilState(AdminUserAtom);

    const [errorMessages, setErrorMessages] = useState({
        companyName: '',
        name: '',
        role: '',
        teamName: '',
        participantsNumber: '',
    });

    const [validating, setValidating] = useState(false);

    const [errors, setErrors] = useState({
        companyName: true,
        name: true,
        role: true,
        teamName: true,
        participantsNumber: true,
    });

    const resetErrorsMessages = () => {
        setErrorMessages({
            companyName: '',
            name: '',
            role: '',
            teamName: '',
            participantsNumber: '',
        });
    };

    const handleSubmit = () => {
        setValidating(true);

        const data = {
            telegram_id: window.Telegram?.WebApp?.initDataUnsafe?.user?.id,
            is_staff: true,
            companyName: companyName.trim().length !== 0 ? companyName : 'Invalid value (empty)',
            name: name.trim().length !== 0 ? name : 'Invalid value (empty)',
            role: role.trim().length !== 0 ? role : 'Invalid value (empty)',
            teamName: teamName.trim().length !== 0 ? teamName : 'Invalid value (empty)',
            participantsNumber: participantsNumber.trim().length !== 0 ? participantsNumber : 'Invalid value (empty)',
        };

        const newErrors = { ...errors };
        const newErrorMessages = { ...errorMessages };

        for (const key in data) {
            newErrors[key] =
                data[key] === 'Invalid value (empty)' ||
                data[key] === 'Invalid email' ||
                data[key] === 'Invalid phone number';
            if (data[key] === 'Invalid value (empty)') {
                newErrorMessages[key] = 'Поле не должно быть пустым';
            } else if (data[key] === 'Invalid email') {
                newErrorMessages[key] = 'Почта введена неверно';
            } else if (data[key] === 'Invalid phone number') {
                newErrorMessages[key] = 'Номер телефона введен неверно';
            } else {
                newErrorMessages[key] = '';
            }
        }

        setErrorMessages(newErrorMessages);
        setErrors(newErrors);

        let errorExist = Object.values(newErrors).includes(true);
        if (errorExist || !data.telegram_id) {
            setErrors({ ...newErrors });
            return;
        }
        setValidating(false);
        resetErrorsMessages();

        registerUser(data)
            .then(({ data }) => {
                localStorage.setItem('auth-token', data.access);
                localStorage.setItem('refresh-token', data.refresh);
                getUserData()
                    .then((res) => {
                        var newInfo = {
                            username: res.data.username,
                            firstName: res.data.first_name,
                            userId: res.data.id,
                            avatar: '',
                            teamName: res.data.team.name,
                            teamId: res.data.team.id,
                            allUserNumber: res.data.team.number_of_users,
                        };

                        setUserInfo(newInfo);
                        localStorage.setItem('user-id', res.data.id);
                        localStorage.setItem('team-id', res.data.team.id);
                        localStorage.setItem('username', res.data.username);

                        if (res.data.is_staff || res.data.app_admin_panel === true) {
                            setAdminUser({
                                isStaff: res.data.is_staff,
                                appAdminPanel: res.data.app_admin_panel,
                                areValuesApproved: res.data.team.are_values_approved,
                                avaliableSteps: res.data.team.avaliable_steps,
                            });
                            navigate(`/quiz?team=${res.data.team.sharing_url.slice(-6)}&isLeader=true`);
                            return;
                        }

                        navigate('/main');
                    })
                    .catch((error) => {
                        console.error('Ошибка получения пользователя', error);
                    });
            })
            .catch((err) => {
                console.error('Ошибка регистрации пользователя', err);
            });
    };

    return (
        <Layout background={bgColor}>
            <Header>
                <Button
                    startIcon={<ArrowLeft fill={color} />}
                    variant="coloredLight"
                    onClick={() => navigate('/auth')}
                    sx={{ color }}
                >
                    Назад
                </Button>
                <img src={intg101Logo} alt="intg101 logo" />
            </Header>
            <RegistrationSection>
                <Input>
                    <Label variant={'h1'}>Название компании</Label>
                    <TextField
                        value={companyName}
                        error={errors.companyName && validating}
                        onChange={(event) => setCompanyName(event.target.value)}
                        placeholder={'Введите название компании'}
                    />
                    {errorMessages.companyName && (
                        <Typography style={{ color: colors.red }}>{errorMessages.companyName}</Typography>
                    )}
                </Input>
                <Input>
                    <Label variant={'h1'}>Имя</Label>
                    <TextField
                        value={name}
                        error={errors.name && validating}
                        onChange={(event) => setName(event.target.value)}
                        placeholder={'Введите имя'}
                    />
                    {errorMessages.name && <Typography style={{ color: colors.red }}>{errorMessages.name}</Typography>}
                </Input>
                <Input>
                    <Label variant={'h1'}>Должность</Label>
                    <TextField
                        value={role}
                        error={errors.role && validating}
                        onChange={(event) => setRole(event.target.value)}
                        placeholder={'Введите должность'}
                    />
                    {errorMessages.role && <Typography style={{ color: colors.red }}>{errorMessages.role}</Typography>}
                </Input>
                <Input>
                    <Label variant={'h1'}>Название команды</Label>
                    <TextField
                        value={teamName}
                        error={errors.teamName && validating}
                        onChange={(event) => setTeamName(event.target.value)}
                        placeholder={'Введите название команды'}
                    />
                    {errorMessages.teamName && (
                        <Typography style={{ color: colors.red }}>{errorMessages.teamName}</Typography>
                    )}
                </Input>
                <Input>
                    <Label variant={'h1'}>Количество участников</Label>
                    <LabelCaption>* Количество участников команды, включая&nbsp;себя</LabelCaption>
                    <TextField
                        value={participantsNumber}
                        error={errors.participantsNumber && validating}
                        onChange={(event) => setParticipantsNumber(event.target.value)}
                        placeholder={'Введите количество участников'}
                    />
                    {errorMessages.participantsNumber && (
                        <Typography style={{ color: colors.red }}>{errorMessages.participantsNumber}</Typography>
                    )}
                </Input>
            </RegistrationSection>
            <ContinueButton variant="coloredLight" onClick={handleSubmit}>
                Продолжить
            </ContinueButton>
        </Layout>
    );
};

export default RegistrationPage;
