import React, { useState } from 'react';
import RootModal from '../../modals/rootModal';
import { Button, styled, TextField, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import dayjs from 'dayjs';
import { createSessionForTeam, updateSessionForTeam } from '../../../api/adminPanel';
import { colors } from '../../../styles/globalStyles';
import { deleteSession } from '../../../api/session';
import PatternedButton from '../../inputs/patternedButton';
import { SessionGuide } from './SessionGuide';

const Wrap = styled('div')({
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px 17px',
    gap: 17,

    scrollbarWidth: 'none',
    overflow: 'auto',
});
const Label = styled(Typography)({
    fontSize: 30,
    lineHeight: '120%',
});

const Input = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 10,
});

const ButtonsBar = styled('div')({
    display: 'flex',
    gap: 2,
    width: '100%',
    paddingBottom: 17,
});

const SaveButton = styled(Button)({
    width: '100%',
    height: 64,
    padding: '14px 17.5px',
    borderRadius: 23,
});

const SessionCreateModal = ({ teamId, open, onClose, onCreate, sessionData = {}, breadcrumbsData, onDelete }) => {
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [next, setNext] = useState(null);
    const [meetingDate, setMeetingDate] = useState(null);
    const [meetingLink, setMeetingLink] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDeleteButtonPressedOnce, setIsDeleteButtonPressedOnce] = useState(false);
    const [isGuideOpen, setIsGuideOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    React.useEffect(() => {
        if (Object.keys(sessionData).length !== 0) {
            setStart(dayjs(sessionData.start));
            setEnd(dayjs(sessionData.end));
            setNext(dayjs(sessionData.next_date));
            setMeetingDate(dayjs(sessionData.meeting_date));
            setMeetingLink(sessionData.meeting_link);
        }
    }, [sessionData]);

    React.useEffect(() => {
        setIsDeleteButtonPressedOnce(false);
    }, [open]);

    const [errors, setErrors] = useState({
        start: true,
        end: true,
        next: true,
        meetingDate: true,
        meetingLink: true,
    });

    const [validating, setValidating] = useState(false);

    const resetState = () => {
        setStart(null);
        setEnd(null);
        setNext(null);
        setMeetingDate(null);
        setMeetingLink('');
    };

    const handleSubmit = (e) => {
        setValidating(true);

        const pattern = /(http|https):\/\//g;
        const linkTest = meetingLink !== '' ? pattern.test(meetingLink) : true;

        const data = {
            start: dayjs(start).format('YYYY-MM-DD HH:mm'),
            end: dayjs(end).format('YYYY-MM-DD HH:mm'),
            next: dayjs(next).format('YYYY-MM-DD HH:mm'),
            meetingDate: dayjs(meetingDate).format('YYYY-MM-DD HH:mm'),
            meetingLink: linkTest ? meetingLink : 'Invalid URL',
        };

        const newErrors = { ...errors };

        for (const key in data) {
            newErrors[key] = data[key] === 'Invalid Date' || data[key] === 'Invalid URL';
            if (data[key] === 'Invalid Date') setErrorMessage('Неверно введена дата');
            if (data[key] === 'Invalid URL')
                setErrorMessage('Поле ссылки может быть пустым или содержать корректную ссылку');
        }
        let errorExist = Object.values(newErrors).includes(true);
        if (errorExist) {
            setErrors({ ...newErrors });
            return;
        }
        setErrorMessage('');
        setValidating(false);
        data.teamId = teamId;
        setLoading(true);
        if (Object.keys(sessionData).length !== 0) {
            data.id = sessionData.id;
            updateSessionForTeam(data)
                .then((response) => {
                    setLoading(false);
                    if (e.target.name === 'save-and-exit') {
                        onClose(response);
                    }
                })
                .catch((err) => {
                    console.error('Ошибка обновления сессии команды', err);

                    const errorData = err.response.data;
                    const errorMessage = errorData?.message || 'Ошибка обновления сессии команды';

                    setErrorMessage(errorMessage);
                    setLoading(false);
                });
        } else {
            createSessionForTeam(data)
                .then((response) => {
                    setLoading(false);
                    if (e.target.name === 'save-and-exit') {
                        onCreate(response);
                        resetState();
                    }
                })
                .catch((err) => {
                    console.error('Ошибка создания сессии команды', err);

                    const errorData = err.response.data;
                    const errorMessage = errorData?.message || 'Ошибка создания сессии команды';

                    setErrorMessage(errorMessage);
                    setLoading(false);
                });
        }
    };

    function handleDeleteSession() {
        if (isDeleteButtonPressedOnce) {
            deleteSession(sessionData.id)
                .then(() => {
                    onDelete(sessionData.id);
                })
                .catch((err) => {
                    console.error('Ошибка удаления сессии команды', err);
                });
        } else {
            setIsDeleteButtonPressedOnce(true);
        }
    }

    return (
        <RootModal open={open} onClose={onClose} breadcrumbsData={breadcrumbsData}>
            <Wrap>
                <PatternedButton label="Инструкция" variant="colored" onClick={() => setIsGuideOpen(!isGuideOpen)} />
                <SessionGuide isOpen={isGuideOpen} />

                <Input>
                    <Label variant={'h1'}>Начало</Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimeField
                            value={start}
                            error={errors.start && validating}
                            onChange={(value) => setStart(value)}
                            format={'YYYY-MM-DD HH:mm'}
                            placeholder={'2022-02-22 22:22'}
                        />
                    </LocalizationProvider>
                </Input>
                <Input>
                    <Label variant={'h1'}>Конец</Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimeField
                            value={end}
                            error={errors.end && validating}
                            onChange={(value) => setEnd(value)}
                            format={'YYYY-MM-DD HH:mm'}
                            placeholder={'2022-02-22 22:22'}
                        />
                    </LocalizationProvider>
                </Input>
                <Input>
                    <Label variant={'h1'}>Ближайшая встреча</Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimeField
                            value={next}
                            error={errors.next && validating}
                            onChange={(value) => setNext(value)}
                            format={'YYYY-MM-DD HH:mm'}
                            placeholder={'2022-02-22 22:22'}
                        />
                    </LocalizationProvider>
                </Input>
                <Input>
                    <Label variant={'h1'}>Следующая встреча</Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimeField
                            value={meetingDate}
                            error={errors.meetingDate && validating}
                            onChange={(value) => setMeetingDate(value)}
                            format={'YYYY-MM-DD HH:mm'}
                            placeholder={'2022-02-22 22:22'}
                        />
                    </LocalizationProvider>
                </Input>
                <Input>
                    <Label variant={'h1'}>Ссылка на комнату</Label>
                    <TextField
                        value={meetingLink}
                        error={errors.meetingLink && validating}
                        onChange={(event) => setMeetingLink(event.target.value)}
                        placeholder={'Вставьте ссылку'}
                    />
                </Input>
                <Typography style={{ color: colors.red }}>{errorMessage}</Typography>
                <ButtonsBar>
                    {Object.keys(sessionData).length !== 0 && (
                        <SaveButton
                            disabled={loading}
                            name={'delete-and-exit'}
                            variant={'coloredLight'}
                            onClick={handleDeleteSession}
                            sx={{
                                width: '100%',
                                background: 'rgb(245, 66, 111)',
                                boxShadow: isDeleteButtonPressedOnce ? '0 0 20px rgba(255, 45, 80, .6)' : 'none',

                                '&:hover': {
                                    boxShadow: isDeleteButtonPressedOnce ? '0 0 20px rgba(100, 100, 200, .6)' : 'none',
                                },
                            }}
                        >
                            {isDeleteButtonPressedOnce ? 'Подтвердить' : 'Удалить сессию'}
                        </SaveButton>
                    )}
                    <SaveButton
                        disabled={loading}
                        name={'save-and-exit'}
                        variant={'coloredLight'}
                        onClick={(e) => handleSubmit(e)}
                    >
                        Сохранить
                    </SaveButton>
                </ButtonsBar>
            </Wrap>
        </RootModal>
    );
};

export default SessionCreateModal;
