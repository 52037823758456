import { IconButton, InputBase, styled } from '@mui/material';
import { colors } from '../styles/globalStyles';
import Bg from '../images/bg.png';
import React from 'react';
import { ReactComponent as Tick } from '../images/tick.svg';

const ValueCard = styled('div')({
    padding: 10,
    borderRadius: 28,
    backgroundColor: colors.transparentMain,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});

const ListItem = styled('div')(() => ({
    cursor: 'pointer',
    padding: 17,
    minHeight: 72,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    gap: 10,

    backgroundColor: colors.white,
    borderRadius: 23,

    '& textarea': {
        padding: 5,
        borderRadius: 10,
        border: '2px solid transparent',
        color: colors.darkBlue,
    },

    '&.active': {
        background: `url(${Bg}), linear-gradient(267.39deg, #FFD952 -8.46%, #11D1A3 39.05%, #1198D1 92.96%)`,
        backgroundBlendMode: 'multiply',

        '& textarea': {
            color: colors.white,
        },

        '& textarea:focus': {
            border: `2px solid ${colors.white}`,
        },
    },

    '&:not(.active) textarea:focus': {
        border: `2px solid ${colors.darkBlue}`,
    },
}));

const StyledTextInput = styled(InputBase)({
    width: '100%',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '128%',

    '& textarea': {
        padding: 5,
        borderRadius: 10,
        border: '2px solid transparent',
        color: colors.darkBlue,

        '&:focus': {
            border: `2px solid ${colors.darkBlue}`,
        },
    },

    '&::has(.active) textarea': {
        color: colors.white,

        '&:focus': {
            border: `2px solid ${colors.white}`,
        },
    },
});

const TitleInput = styled(StyledTextInput)({
    fontSize: 20,
    padding: '10px 10px 0 10px',

    '& textarea': {
        padding: 2,
        paddingLeft: 5,
    },
});

const TickButton = styled(IconButton)({
    marginLeft: 10,
    width: 48,
    height: 48,
    background: colors.white,
    border: '2px solid rgba(59, 122, 246, 0.14)',
    borderRadius: 13,

    '&.active': {
        background: 'rgba(255, 255, 255, 0.14)',
        border: '2px solid rgba(59, 122, 246, 0.28)',
    },
});

const ResultsValue = ({ value, handleChange, checkedBehaviors, onValueNameChange, onBehaviorNameChange }) => {
    const behaviorsItems = value.behavior?.map((behavior, behaviorIndex) => (
        <Behavior
            onBehaviorTextFieldChange={(event) =>
                onBehaviorNameChange(behaviorIndex, event.target.value, event.target.name)
            }
            checkedBehaviors={checkedBehaviors}
            handleChange={handleChange}
            key={behavior.id}
            behavior={behavior}
        />
    ));

    return (
        <ValueCard name="value">
            <TitleInput name="name" multiline value={value.name} onChange={onValueNameChange} />
            {behaviorsItems}
        </ValueCard>
    );
};

export default ResultsValue;

const Behavior = ({ behavior, handleChange, checkedBehaviors, onBehaviorTextFieldChange }) => {
    const isChecked = checkedBehaviors[behavior.id];

    const handleClick = () => {
        handleChange(behavior.id);
    };

    return (
        <ListItem name="behavior" className={isChecked ? 'active' : null}>
            <StyledTextInput multiline name="title" value={behavior.title} onChange={onBehaviorTextFieldChange} />
            <TickButton onClick={handleClick} className={isChecked ? 'active' : null}>
                {isChecked && <Tick />}
            </TickButton>
        </ListItem>
    );
};
