import { Box, styled, Typography } from '@mui/material';
import { colors } from '../../../../../../styles/globalStyles';

export const Line = styled(Box)(({ lineHeight }) => ({
    position: 'absolute',
    bottom: lineHeight,
    left: 0,

    height: '2px',
    width: 'calc(100% + 10px)',

    zIndex: 0,

    background: `linear-gradient(270deg, ${colors.main} 0%, ${colors.main + '22'} 100%)`,
}));

export const AverageCaption = styled(Box)(({ lineHeight }) => ({
    position: 'absolute',
    right: -64,
    bottom: lineHeight,
    transform: 'translateY(50%)',
    padding: 0.5,
    borderRadius: 2,
    cursor: 'default',
}));

export const Caption = styled(Typography)({
    lineHeight: 1,
    fontSize: 22,
    fontWeight: 700,
});

export const CaptionSmall = styled(Caption)({
    fontSize: 11,
    fontWeight: 500,
    marginBottom: 0.25,
});
