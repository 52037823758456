import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import { Box, styled } from '@mui/system';
import { Alert, Breadcrumbs, Link, Snackbar, Typography } from '@mui/material';
import { colors } from '../styles/globalStyles';
import { ReactComponent as DotDivider } from '../images/dot_divider.svg';
import PatternedButton from '../components/inputs/patternedButton';
import SessionCreateModal from '../components/pages/adminPanel/sessionCreateModal';
import { getExcel, getTeamInfo, updateStep } from '../api/adminPanel';
import InstructionsModal from '../components/modals/instructionsModal';
import { AdminUserAtom } from './../recoil/atoms/adminPanelAtoms';
import { useRecoilState } from 'recoil';
import { getUserData } from '../api/auth';
import { userInfoAtomArr } from '../recoil/atoms/userAtom';

const StyledBreadcrumbs = styled(Breadcrumbs)({
    '& a.light': {
        opacity: 0.4,
        color: colors.darkBlue,
    },

    '& a.transparent': {
        opacity: 0.4,
        color: colors.white,
    },

    '& a.current.light': {
        opacity: 1,
        color: colors.main,
    },

    '& a.current.transparent': {
        opacity: 1,
        color: colors.white,
    },
});

const ButtonsWrap = styled('div')(({ gap = 17 }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: gap,
}));

const StepWrapper = styled(Box)(({ is_blocked = 'false' }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 17,
    opacity: is_blocked === 'true' ? 0.5 : 1,
}));

const StepTitleBlock = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 12,
}));

const StepTitle = styled(Typography)(({ theme }) => ({
    lineHeight: 1.2,
}));

const Coin = styled(Box)(({ theme }) => ({
    minWidth: 28,
    width: 28,
    height: 28,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: 'white',
    fontSize: 14,
    lineHeight: 1,

    backgroundColor: '#3B7AF6',
    borderRadius: 16,
}));

const Caption = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    opacity: 0.8,
    lineHeight: 1.5,
    padding: '0 10px',
}));

const TeamControlPage = () => {
    const navigate = useNavigate();
    const { teamId, companyId } = useParams();
    const [sessionCreateModal, setSessionCreateModal] = useState(false);
    const [instructionsModal, setInstructionsModal] = useState(false);
    const [sharingUrl, setSharingUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [breadcrumbsData, setBreadcrumbsData] = useState({});
    const [adminUser, setAdminUser] = useRecoilState(AdminUserAtom);
    const [userData, setUserData] = useRecoilState(userInfoAtomArr);
    const [currentUserNumber, setCurrentUserNumber] = useState(adminUser.currentUserNumber);
    const [allUserNumber, setAllUserNumber] = useState(adminUser.allUserNumber);

    const isSessionCreated = userData.isSessionCreated;

    const updateSteps = useCallback(
        (steps) => {
            setAdminUser((prev) => ({
                ...prev,
                avaliableSteps: steps,
            }));
        },
        [setAdminUser],
    );

    const updateUserData = useCallback(() => {
        getUserData()
            .then((res) => {
                var newInfo = {
                    username: res.data.username,
                    firstName: res.data.first_name,
                    userId: res.data.id,
                    avatar: '',
                    teamName: res.data.team.name,
                    teamId: res.data.team.id,
                    areValuesApproved: res.data.team.are_values_approved,
                    isSessionCreated: res.data.team.is_session_created,
                };

                setUserData(newInfo);

                updateSteps(res.data.team.avaliable_steps);
                setCurrentUserNumber(res.data.team.current_users_number);
                setAllUserNumber(res.data.team.number_of_users);

                localStorage.setItem('user-id', res.data.id);
                localStorage.setItem('team-id', res.data.team.id);
                localStorage.setItem('username', res.data.username);

                if (res.data.is_staff || res.data.app_admin_panel === true) {
                    setAdminUser({
                        isStaff: res.data.is_staff,
                        appAdminPanel: res.data.app_admin_panel,
                        areValuesApproved: res.data.team.are_values_approved,
                        isSessionCreated: res.data.team.is_session_created,
                        avaliableSteps: res.data.team.avaliable_steps,
                    });
                }
            })
            .catch((error) => {
                console.error('Ошибка получения пользователя', error);
            });
    }, [setUserData, setAdminUser, updateSteps]);

    const handleExcelButtonClick = (e) => {
        setLoading(true);
        e.preventDefault();
        getExcel(teamId)
            .then((res) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(res.data);
                const fileName = res.headers['content-disposition'].split('=')[1].replaceAll('"', '');

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                setLoading(false);
            })
            .catch((err) => {
                console.error('Ошибка получения excel файла', err);
            });
    };

    const breadcrumbs = [
        {
            label: breadcrumbsData?.team?.name,
        },
        {
            label: 'Новая сессия',
        },
    ];

    const handleSetThirdStep = () => {
        if (!adminUser?.avaliableSteps?.third) {
            updateStep('third')
                .then((res) => {
                    updateSteps(res.data?.team.avaliable_steps);
                })
                .catch((err) => {
                    console.error('Ошибка получения excel файла', err);
                });
        }
        navigate(`/admin/company/${companyId}/team/${teamId}/survey-results`);
    };

    useEffect(() => {
        getTeamInfo(teamId)
            .then((r) => {
                setBreadcrumbsData(r.data);

                setSharingUrl(r.data?.team?.sharing_url);
            })
            .catch((err) => {
                console.error('Ошибка получения информации о команде', err);
            });
    }, []);

    useEffect(() => {
        if (adminUser.areValuesApproved) {
            return;
        }
        
        updateUserData();

        const interval = setInterval(() => {
            updateUserData();
        }, 5000);

        return () => clearInterval(interval);
    }, [adminUser.areValuesApproved, updateUserData]);

    if (window.Telegram?.WebApp?.initDataUnsafe?.user && !adminUser?.areValuesApproved) {
        return (
            <Layout link={'/admin'}>
                <StyledBreadcrumbs separator={<DotDivider style={{ width: 2, height: 2 }} />}>
                    <Link className="light" onClick={() => navigate('/admin')}>
                        {breadcrumbsData?.company?.name}
                    </Link>
                    <Link>{breadcrumbsData?.team?.name}</Link>
                    <Link className="current">Профиль</Link>
                </StyledBreadcrumbs>
                <ButtonsWrap gap={40}>
                    <PatternedButton label={'Открыть гайд'} onClick={() => setInstructionsModal(true)} />
                    <StepWrapper>
                        <StepTitleBlock>
                            <Coin>1</Coin>
                            <StepTitle>
                                Теперь можно пригласить коллег присоединиться к команде и пройти опрос.
                            </StepTitle>
                        </StepTitleBlock>
                        <CopyButton
                            setInstructionsModal={setInstructionsModal}
                            sharingUrl={sharingUrl}
                            updateSteps={updateSteps}
                            isFirstStep={!adminUser?.avaliableSteps?.second}
                        />
                        <Caption>
                            Прошли опрос: {currentUserNumber || '0'} из {allUserNumber || '0'}
                        </Caption>
                    </StepWrapper>
                    <StepWrapper is_blocked={(!adminUser?.avaliableSteps?.second).toString()}>
                        <StepTitleBlock>
                            <Coin>2</Coin>
                            <StepTitle>Проанализировать ответы коллег.</StepTitle>
                        </StepTitleBlock>
                        <PatternedButton
                            isGrayWhenDisabled={true}
                            label={'Ответы на опрос'}
                            disabled={!adminUser?.avaliableSteps?.second}
                            onClick={handleSetThirdStep}
                        />
                    </StepWrapper>
                    <StepWrapper is_blocked={(!adminUser?.avaliableSteps?.third).toString()}>
                        <StepTitleBlock>
                            <Coin>3</Coin>
                            <StepTitle>
                                Обсудить предлагаемые способы поведения (привычки) и выбрать наиболее подходящие вам.
                            </StepTitle>
                        </StepTitleBlock>
                        <Caption>
                            Совместно с коллегами определите 5-7 положительных привычек, которые вы будете внедрять, и
                            5-7 отрицательных привычек, от которых вы будете избавляться.
                        </Caption>
                        <Caption>
                            После утверждения поведений — они будут закреплены за вашей командой и откроется возможность
                            создания сессии и выставления оценок по поведениям.
                        </Caption>
                        <PatternedButton
                            isGrayWhenDisabled={true}
                            label={'Ценности и Способы поведения '}
                            disabled={!adminUser?.avaliableSteps?.third}
                            onClick={() => navigate(`/admin/company/${companyId}/team/${teamId}/behaviors`)}
                        />
                    </StepWrapper>
                </ButtonsWrap>
                <InstructionsModal open={instructionsModal} onClose={() => setInstructionsModal(false)} />
            </Layout>
        );
    }

    return (
        <Layout link={'/admin'}>
            <StyledBreadcrumbs separator={<DotDivider style={{ width: 2, height: 2 }} />}>
                <Link className="light" onClick={() => navigate('/admin')}>
                    {breadcrumbsData?.company?.name}
                </Link>
                <Link>{breadcrumbsData?.team?.name}</Link>
                <Link className="current">Профиль</Link>
            </StyledBreadcrumbs>
            <ButtonsWrap>
                {window.Telegram?.WebApp?.initDataUnsafe?.user && (
                    <>
                        <CopyButton setInstructionsModal={setInstructionsModal} sharingUrl={sharingUrl} />
                    </>
                )}
                <PatternedButton label={'Задать сессию'} onClick={() => setSessionCreateModal(true)} />
                <PatternedButton
                    label={'Актуальные и прошлые сессии'}
                    onClick={() => navigate(`/admin/company/${companyId}/team/${teamId}/session-history`)}
                />
                {isSessionCreated && (
                    <>
                        <PatternedButton label={'Перейти в свой профиль'} onClick={() => navigate(`/main`)} />
                        <PatternedButton
                            label={'Пользователи'}
                            onClick={() => navigate(`/admin/company/${companyId}/team/${teamId}/users`)}
                        />
                    </>
                )}
                <PatternedButton
                    label={'Ценности и Способы поведения '}
                    onClick={() => navigate(`/admin/company/${companyId}/team/${teamId}/behaviors`)}
                />

                {window.Telegram?.WebApp?.initDataUnsafe?.user && (
                    <PatternedButton
                        label={'Ответы на опрос'}
                        onClick={() => navigate(`/admin/company/${companyId}/team/${teamId}/survey-results`)}
                    />
                )}

                {isSessionCreated && (
                    <>
                        <PatternedButton
                            label={'Шкала оценки '}
                            onClick={() => navigate(`/admin/company/${companyId}/team/${teamId}/max-score`)}
                        />
                        <PatternedButton
                            label={loading ? 'Идет загрузка...' : ' Выгрузить результаты в Excel'}
                            onClick={handleExcelButtonClick}
                            disabled={loading}
                        />
                    </>
                )}
            </ButtonsWrap>
            <SessionCreateModal
                open={sessionCreateModal}
                teamId={teamId}
                breadcrumbsData={breadcrumbs}
                onClose={() => setSessionCreateModal(false)}
            />
        </Layout>
    );
};

export default TeamControlPage;

const CopyButton = ({ setInstructionsModal, sharingUrl, isFirstStep, updateSteps }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        copyToClipboard();

        setIsOpen(true);

        if (isFirstStep) {
            updateStep('second')
                .then((res) => {
                    updateSteps(res.data?.team.avaliable_steps);
                })
                .catch((err) => {
                    console.error('Ошибка получения excel файла', err);
                });
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsOpen(false);
    };

    //Копирование в буфер обмена
    const copyToClipboard = async () => {
        if (!navigator.clipboard) {
            console.error('Clipboard API не поддерживается в этом браузере');
            return;
        }

        try {
            await navigator.clipboard.writeText(
                `Привет! Приглашаю тебя присоединиться к нашей команде в трекере командных изменений INTG101. Для этого переходи по ссылке: \n\n` +
                    sharingUrl,
            );
        } catch (err) {
            console.error('Ошибка копирования: ', err);
        }
    };

    return (
        <>
            <PatternedButton
                disabled={sharingUrl?.trim().length === 0}
                endIcon={null}
                label={'Скопировать приглашение'}
                onClick={handleClick}
            />
            <Snackbar open={isOpen} autoHideDuration={2000} onClose={handleClose}>
                <Alert severity="success" variant="filled" sx={{ width: '100%', background: '#12CDA7' }}>
                    Скопировано!
                </Alert>
            </Snackbar>
        </>
    );
};
