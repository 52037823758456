import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import { Avatar, Button, Chip, styled, Typography } from '@mui/material';
import { colors } from '../styles/globalStyles';
import { ReactComponent as ArrowDown } from '../images/arrow_down.svg';
import { ReactComponent as ColoredDot } from '../images/colored_dot.svg';
import { ReactComponent as ArrowRight } from '../images/arrow_right.svg';
import { ReactComponent as OneStar } from '../images/1star.svg';
import { ReactComponent as TwoStars } from '../images/2stars.svg';
import { ReactComponent as ThreeStars } from '../images/3stars.svg';
import { ReactComponent as FourStars } from '../images/4stars.svg';
import { ReactComponent as FiveStars } from '../images/5stars.svg';
import { ReactComponent as SixStars } from '../images/6stars.svg';
import { ReactComponent as SevenStars } from '../images/7stars.svg';
import { ReactComponent as EightStars } from '../images/8stars.svg';
import { ReactComponent as NineStars } from '../images/9stars.svg';
import { ReactComponent as TenStars } from '../images/10stars.svg';
import { Behavior, BehaviorItems, Buttons, CardsSection, StyledButton, Value } from './progressBehaviors';
import TeammateBehaviorsModal from './teammateBehaviorsModal';
import { getUserBehaviors } from '../api/user';

const UserInfo = styled('div')({
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
});

const HeadingTypography = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '100%',
    color: colors.main,
});

const Behaviors = styled('div')({
    padding: 17,
    background: colors.transparentMain,
    borderRadius: 40,
    overflowY: 'auto',
});

const BehaviorItem = styled('article')({
    padding: 10,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    background: colors.white,
    borderRadius: 13,

    '&.active': {
        background: colors.main,
    },
});

const TypographyBehaviorItem = styled(Typography)({
    padding: 10,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '128%',

    '&.active': {
        color: colors.white,
    },
});

const StarsOrRate = styled(Button)({
    width: 52,
    height: 52,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    background: colors.transparentMain,
    borderRadius: 6,

    '&.active': {
        background: colors.transparentWhite,
    },
    '&:hover': {
        backgroundColor: colors.transparentMain,
    },
});

const TypographyStarsOrRate = styled(Typography)({
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '128%',
    color: colors.main,
});

const SaveSection = styled('div')({
    padding: 17,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,

    background: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '40px 40px 0px 0px',
    borderWidth: '0.33px 0.33px 0px 0.33px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.3)',
});

const SessionAndDate = styled('div')({
    marginBottom: 17,
    paddingInline: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
});

const Session = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
});

const TeammateBehaviors = ({ userId }) => {
    const [progressData, setProgressData] = useState(null);
    const [userData, setUserData] = useState(null);
    const getStars = {
        1: <OneStar style={{ color: colors.yellow }} />,
        2: <TwoStars style={{ color: colors.yellow }} />,
        3: <ThreeStars style={{ color: colors.yellow }} />,
        4: <FourStars style={{ color: colors.yellow }} />,
        5: <FiveStars style={{ color: colors.yellow }} />,
        6: <SixStars style={{ color: colors.yellow }} />,
        7: <SevenStars style={{ color: colors.yellow }} />,
        8: <EightStars style={{ color: colors.yellow }} />,
        9: <NineStars style={{ color: colors.yellow }} />,
        10: <TenStars style={{ color: colors.yellow }} />,
    };

    const [positive, setPositive] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedBehavior, setSelectedBehavior] = useState(null);

    const fetchData = () => {
        getUserBehaviors(userId)
            .then((r) => {
                setProgressData(r.data.values);
                setUserData(r.data.user);
            })
            .catch((err) => {
                console.error('Ошибка получения поведений', err);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRateButtonClick = (behavior, value) => {
        setSelectedBehavior({ ...behavior, value: value });
        setOpen(true);
    };

    return (
        <Layout link={'/my-sessions'} openModal={'search'}>
            <UserInfo>
                <HeadingTypography sx={{ color: 'unset' }}>Поведения</HeadingTypography>
                {userData?.avatar ? (
                    <Avatar
                        src={`https://app.intg101.work${userData.avatar}`}
                        alt={'avatar'}
                        sx={{ width: 24, height: 24 }}
                    />
                ) : null}
                {userData && userData['first-name'] ? (
                    <HeadingTypography>{userData['first-name']}</HeadingTypography>
                ) : null}
            </UserInfo>
            <Behaviors>
                <Buttons onClick={() => setPositive(!positive)}>
                    <StyledButton className={positive ? 'active' : null}>Положительные</StyledButton>
                    <StyledButton className={positive ? null : 'active'}>Отрицательные</StyledButton>
                </Buttons>
                <CardsSection>
                    <>
                        {progressData &&
                            progressData.length !== 0 &&
                            progressData
                                .filter((value) => value['is_positive'] === positive)
                                .map((value, index) => (
                                    <Behavior key={`behavior-${index}`}>
                                        <BehaviorItems>
                                            <Value>
                                                <ColoredDot fill={value['is_positive'] ? colors.green : colors.red} />
                                                {value.value}
                                            </Value>
                                            {value.behaviors.map((behavior, behIndex) => (
                                                <BehaviorItem key={`behavior-${behavior.id}`}>
                                                    <TypographyBehaviorItem>{behavior.title}</TypographyBehaviorItem>
                                                    <StarsOrRate
                                                        onClick={() => handleRateButtonClick(behavior, value.value)}
                                                    >
                                                        {behavior.score ? (
                                                            <>
                                                                <TypographyStarsOrRate>
                                                                    {behavior.score.result}
                                                                </TypographyStarsOrRate>
                                                                {getStars[behavior.score.result]}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ArrowRight fill={colors.main} />
                                                                {/* <TypographyStarsOrRate>
                                                                    Оценить
                                                                </TypographyStarsOrRate> */}
                                                            </>
                                                        )}
                                                    </StarsOrRate>
                                                </BehaviorItem>
                                            ))}
                                        </BehaviorItems>
                                    </Behavior>
                                ))}
                    </>
                </CardsSection>
            </Behaviors>
            {selectedBehavior !== null && (
                <TeammateBehaviorsModal
                    open={open}
                    onClose={() => {
                        setSelectedBehavior(null);
                        setOpen(false);
                    }}
                    userData={userData}
                    behavior={selectedBehavior}
                    belong={userId}
                    fetchData={fetchData}
                />
            )}
        </Layout>
    );
};

export default TeammateBehaviors;
