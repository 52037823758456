import { getCardsData } from '../api/session';

export const getKssData = async (user) => {
    try {
        let response;

        if (typeof user === 'number') {
            response = await getCardsData(user);
        } else {
            const userIdForAdminPanel = localStorage.getItem('user-id-for-admin-panel');
            response = await getCardsData(userIdForAdminPanel || null);
        }

        if (response.data.length === 0) {
            return [];
        }

        const getItemByStatus = (status) => response.data.filter((i) => i.status === status)[0];
        const kssData = [getItemByStatus('keep'), getItemByStatus('stop'), getItemByStatus('start')];

        return kssData;
    } catch {
        return [];
    }
};
