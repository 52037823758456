import React from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { colors } from '../../../styles/globalStyles';
import { Label } from './rateGrid';
import { getCaption } from '../../../util/scores';

const ButtonStyle = styled(Button)({
    borderRadius: 23,
    display: 'flex',
    gap: 4,
    flexDirection: 'column',

    '&.light': {
        background: colors.transparentMain,
        color: colors.main,
    },
    '&.transparent': {
        background: colors.transparentWhite,
        color: colors.white,
    },

    '&:active': {
        background: 'linear-gradient(0deg, rgba(246, 182, 59, 0.1), rgba(246, 182, 59, 0.1)), #FFFFFF',
        border: '4px solid rgba(246, 182, 59, 0.36)',
        color: colors.yellow,
    },
    '&:hover': {
        background: 'rgba(0, 0, 0, 0.1)',
    },

    '&.selected': {
        background: 'linear-gradient(0deg, rgba(246, 182, 59, 0.1), rgba(246, 182, 59, 0.1)), #FFFFFF',
        border: '4px solid rgba(246, 182, 59, 0.36)',
        color: colors.yellow,
    },
});

const RateButton = ({ value, className, onClick, variant, maxScore }) => {
    const resultVariant = variant === 'light' ? 'light' : 'transparent';
    const buttonClassName = `${className} ${resultVariant}`;

    return (
        <ButtonStyle className={buttonClassName} onClick={onClick}>
            <Label variant={'h1'}>{value}</Label>
            <div>{getCaption(value, maxScore)}</div>
        </ButtonStyle>
    );
};

export default RateButton;
