import React from 'react';
import { Chip, styled, Typography } from '@mui/material';
import { colors } from '../../styles/globalStyles';
import { ReactComponent as MessagesIcon } from '../../images/messages_icon.svg';
import renderStars from '../../util/renderStars';

const Layout = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const TypographyAboveChip = styled(Typography)({
    marginLeft: 10,
    marginBottom: 4,

    fontSize: 9,
    fontWeight: 500,
    lineHeight: '122%',
});

const StyledChip = styled(Chip)(({ height, background, text_color, radius, isGray }) => ({
    height: height || 52,

    background: isGray ? colors.gray : background,
    color: text_color,

    borderRadius: radius || 27,
}));

const TypographyChip = styled(Typography)({
    display: 'flex',
    alignItems: 'center',

    fontSize: 24,
    fontWeight: 500,
    lineHeight: '129%',

    '& svg': {
        marginLeft: 10,
        color: colors.yellow,
    },
});

const StarOrMessageChip = ({ title, number, messageIcon, height, background, text_color, radius, isGray }) => {
    return (
        <Layout>
            {title && <TypographyAboveChip>{title}</TypographyAboveChip>}
            <StyledChip
                label={
                    <TypographyChip>
                        {number}
                        {messageIcon ? <MessagesIcon /> : renderStars(Math.round(number))}
                    </TypographyChip>
                }
                height={height}
                background={background}
                text_color={text_color}
                radius={radius}
                isGray={isGray}
            />
        </Layout>
    );
};

export default StarOrMessageChip;
