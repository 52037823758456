import { AverageCaption, Caption, CaptionSmall, Line } from './styled.css';

export const AverageValue = ({ avgValue, maxQuestionValue }) => {
    const lineHeight = (avgValue / maxQuestionValue) * 65 + 15 + '%';

    return (
        <>
            <Line lineHeight={lineHeight} />
            <AverageCaption lineHeight={lineHeight}>
                <CaptionSmall>среднее</CaptionSmall>
                <Caption>{avgValue}</Caption>
            </AverageCaption>
        </>
    );
};
