import { styled } from '@mui/material';
import { colors } from '../../../../styles/globalStyles';

export const AnswerList = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});

export const AnswerSliderList = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '10px',
});

export const QuestionCard = styled('div')({
    padding: 16,
    borderRadius: 40,
    backgroundColor: colors.transparentMain,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
});
