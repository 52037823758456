import { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import { onlyShortMonth, onlyYear } from '../../../functions/nextSessionFormat';
import { colors } from '../../../styles/globalStyles';

const TimeLineChip = ({ isVisible }) => {
    const todayDate = new Date().toISOString().slice(0, 10);
    const [activeMonth, setActiveMonth] = useState(onlyShortMonth(todayDate));
    const [activeYear, setActiveYear] = useState(onlyYear(todayDate));

    useEffect(() => {
        if (isVisible?.target) {
            setActiveMonth(onlyShortMonth(isVisible.target.id));
            setActiveYear(onlyYear(isVisible.target.id));
        }
    }, [isVisible]);

    return (
        <Chip
            label={`${activeMonth}, ${activeYear}`}
            variant={'lightBlue'}
            size={'small'}
            sx={{ color: colors.darkBlue }}
        />
    );
};

export default TimeLineChip;
