import { Avatar, Box, Breadcrumbs, Button, Link, styled, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { updateAvatar } from '../api/user';
import { ReactComponent as DotDivider } from '../images/dot_divider.svg';
import { ReactComponent as Telegram } from '../images/telegram.svg';
import { userInfoAtomArr } from '../recoil/atoms/userAtom';
import { colors } from '../styles/globalStyles';
import getUserDataWithAvatar from '../util/user';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';

const StyledBreadcrumbs = styled(Breadcrumbs)({
    marginInline: 10,

    '& a.light': {
        opacity: 0.4,
        color: colors.darkBlue,
        cursor: 'pointer',
    },
    '& a.current': {
        color: colors.main,
    },
});

const ProfileSection = styled('section')({
    padding: 17,
    minHeight: 223,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    background: 'rgba(59, 122, 246, 0.14)',
    borderRadius: 40,
});

const StyledAvatar = styled(Avatar)({
    width: '100px',
    height: '100px',

    '& img': {
        padding: 2,
        background:
            'linear-gradient(white, white) padding-box, linear-gradient(225deg, #6C59FF 13.54%, #EF6E77 48.96%, #FCD13A 71.35%, #12CDA7 100%) border-box',
        border: '2px solid transparent',
        borderRadius: 100,
    },
});

const StyledButton = styled(Button)({
    height: 58,
    backgroundColor: colors.main,
    color: colors.white,
    borderRadius: 23,

    '&.logout': {
        backgroundColor: colors.transparentMain,
        color: colors.main,
    },
    '&.disabled': {
        pointerEvents: 'none',
        cursor: 'default',
    },
});

const TypographySmall = styled(Typography)({
    marginBottom: 4,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '105%',
});

const TypographyLarge = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '105%',
});

const Profile = () => {
    const [userInfo, setUserInfo] = useRecoilState(userInfoAtomArr);
    const [, setAdminUser] = useRecoilState(AdminUserAtom);
    const ref = useRef();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    React.useEffect(() => {
        getUserDataWithAvatar().then((userInfo) => {
            setUserInfo(userInfo);
        });
    }, []);

    const handleUpdateAvatar = (e) => {
        const avatar = e.target.files[0];
        if (avatar) {
            setLoading(true);
            const data = new FormData();
            data.append('avatar', avatar);
            updateAvatar(data)
                .then(({ data }) => {
                    const avatar = `https://app.intg101.work${data}`;
                    localStorage.setItem('avatar', avatar);
                    setUserInfo({ ...userInfo, avatar: avatar });
                    setLoading(false);
                })
                .catch(() =>
                    setError('Что-то пошло не так. Возможно, изображение слишком большое (максимальный размер 10 MB)'),
                );
        }
    };
    const handleLogout = () => {
        localStorage.clear();
        setUserInfo({});
        setAdminUser({});
        navigate('/auth');
    };

    return (
        <>
            <StyledBreadcrumbs separator={<DotDivider style={{ width: 2, height: 2 }} />}>
                <Link className="light" onClick={() => navigate('/')}>
                    Главная
                </Link>
                <Link className="current">Профиль</Link>
            </StyledBreadcrumbs>
            <ProfileSection>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                    <StyledAvatar src={userInfo.avatar} />
                    <div>
                        {!userInfo.firstName && !userInfo.secondName && (
                            <>
                                <TypographySmall>Логин</TypographySmall>
                                <TypographyLarge>{userInfo.username}</TypographyLarge>
                            </>
                        )}
                        {userInfo.firstName && (
                            <>
                                <TypographySmall>Имя</TypographySmall>
                                <TypographyLarge sx={{ marginBottom: '14px' }}>{userInfo.firstName}</TypographyLarge>
                            </>
                        )}
                        {userInfo.secondName && (
                            <>
                                <TypographySmall>Фамилия</TypographySmall>
                                <TypographyLarge>{userInfo.secondName}</TypographyLarge>
                            </>
                        )}
                    </div>
                </Box>
                {error && (
                    <TypographySmall
                        sx={{
                            padding: '5px',
                            color: 'red',
                            textAlign: 'center',
                            lineHeight: '150%',
                        }}
                    >
                        {error}
                    </TypographySmall>
                )}
                <StyledButton
                    variant="large"
                    className={loading ? 'disabled' : null}
                    onClick={() => ref.current.click()}
                >
                    <input
                        ref={ref}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => handleUpdateAvatar(e)}
                    />
                    {loading ? 'Загрузка...' : 'Изменить фото'}
                </StyledButton>
            </ProfileSection>
            <StyledButton
                startIcon={<Telegram />}
                variant="large"
                sx={{ fontSize: '14px' }}
                onClick={() => window.open('https://t.me/intg101Bot', '_blank')}
            >
                Telegram-бот, чтобы присылал напоминания
            </StyledButton>
            <StyledButton variant="large" className="logout" sx={{ marginTop: 'auto' }} onClick={handleLogout}>
                Выйти из профиля
            </StyledButton>
        </>
    );
};

export default Profile;
