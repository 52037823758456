import { Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import LayoutSkeleton from './layoutSkeleton';

const TrackerWrap = styled('div')({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'flex-start',
    gap: 17,
});

const Header = styled('div')({
    height: 90,
    padding: '0 10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
});

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
});

const TimeLineSkeleton = styled(Skeleton)({
    maxWidth: 390,
    height: 145,
    width: '100%',
    borderRadius: '40px 40px 0 0',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: '0 auto',
});

const TrackerPageSkeleton = ({ link }) => {
    return (
        <LayoutSkeleton link={link}>
            <TrackerWrap>
                <Header>
                    <Skeleton variant={'text'} />
                    <Container>
                        <Skeleton
                            variant={'rectangular'}
                            sx={{ width: '100%', fontSize: 40, height: '56px', borderRadius: 40 }}
                        />
                        <Skeleton
                            variant={'rectangular'}
                            sx={{ width: '100%', fontSize: 40, height: '56px', borderRadius: 40 }}
                        />
                        <Skeleton
                            variant={'rectangular'}
                            sx={{ width: '100%', fontSize: 40, height: '56px', borderRadius: 40 }}
                        />
                    </Container>
                </Header>
                <Skeleton
                    variant={'rectangular'}
                    sx={{ width: '100%', fontSize: 40, height: '75vh', borderRadius: '40px 40px 0 0' }}
                />
            </TrackerWrap>
            <TimeLineSkeleton variant={'rectangular'} />
        </LayoutSkeleton>
    );
};

export default TrackerPageSkeleton;
