import React, { useEffect, useState } from 'react';
import { Button, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { LoaderWrapper } from '../sessionHistory';
import { getTeamSurveyResults } from '../../api/adminPanel';
import { Loader } from '../../components/loaders/loader';
import Layout from '../../components/Layout';
import { colors } from '../../styles/globalStyles';
import { Question } from './elements/Question';

const LargeButton = styled(Button)({
    padding: 22,
    borderRadius: 23,
    transition: 'all 0.3s ease',
});

export const TeamSurveyResultsPage = () => {
    const navigate = useNavigate();
    const { teamId, companyId } = useParams();
    const [questions, setQuestions] = useState([]);
    const [areResultsLoading, setAreResultsLoading] = useState(true);

    useEffect(() => {
        setAreResultsLoading(true);

        getTeamSurveyResults(teamId)
            .then((res) => {
                setQuestions(res.data.questions);
                setAreResultsLoading(false);
            })
            .catch((err) => {
                console.error('Ошибка получения ценностей команды', err);
                setAreResultsLoading(false);
            });
    }, []);

    if (areResultsLoading) {
        return (
            <Layout link={`/admin/company/${companyId}/team/${teamId}`}>
                <LoaderWrapper>
                    <Loader size="large" color={colors.main} />
                </LoaderWrapper>
            </Layout>
        );
    }

    const questionItems = questions?.map((question) => <Question key={question.id} question={question} />) || null;

    return (
        <Layout link={`/admin/company/${companyId}/team/${teamId}`}>
            {questionItems}
            <LargeButton variant={'colored'} onClick={() => navigate(`/admin/company/${companyId}/team/${teamId}`)}>
                Перейти к следующему шагу
            </LargeButton>
        </Layout>
    );
};

export default TeamSurveyResultsPage;
