import React from 'react';
import Layout from '../components/Layout';
import Profile from '../components/profile';
import { useRecoilValue } from 'recoil';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';

const ProfilePage = () => {
    const adminUser = useRecoilValue(AdminUserAtom);
    const backToAdmin = !Boolean(adminUser.userIdForAdminPanel);

    return (
        <Layout link={backToAdmin ? '/admin' : '/'}>
            <Profile />
        </Layout>
    );
};

export default ProfilePage;
