import { axiosInstance } from './axiosInstance';

export const getCardsData = (user, team) =>
    axiosInstance.get(`session/get_with_team_and_user/`, {
        params: {
            user: user || localStorage.getItem('user-id'),
            team: team || localStorage.getItem('team-id'),
        },
    });

export const createSessionUser = (postData) =>
    axiosInstance({
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        url: `session/`,
        data: { body: postData },
    });

export const updateSessionUser = (postData) =>
    axiosInstance({
        headers: { 'Content-Type': 'application/json' },
        method: 'PATCH',
        url: `session/`,
        data: { body: postData },
    });

export const deleteSession = (id) =>
    axiosInstance({
        headers: { 'Content-Type': 'application/json' },
        method: 'DELETE',
        url: `session/`,
        data: {
            body: {
                session_id: id,
            },
        },
    });
