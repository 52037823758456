import { useEffect, useRef, useState } from 'react';
import { Caption, Coin, GuideContainer, GuideBlock, GuideInnerBlock, GuideTitle, GuideTitleBlock } from './styles.css';

export const SessionGuide = ({ isOpen }) => {
    const ref = useRef();
    const [guideStyles, setGuideStyles] = useState(null);

    useEffect(() => {
        const computedStyle = getComputedStyle(ref.current);
        setGuideStyles({
            minHeight: ref.current.scrollHeight + 'px',
            padding: computedStyle.padding,
        });
    }, []);

    useEffect(() => {
        if (isOpen && guideStyles) {
            ref.current.style.minHeight = guideStyles.minHeight;
            ref.current.style.maxHeight = guideStyles.minHeight;
            ref.current.style.padding = guideStyles.padding;
        } else {
            ref.current.style.minHeight = 0;
            ref.current.style.maxHeight = 0;
            ref.current.style['padding-top'] = 0;
            ref.current.style['padding-bottom'] = 0;
        }
    }, [isOpen, guideStyles]);

    return (
        <GuideContainer ref={ref}>
            <GuideBlock>
                <GuideTitleBlock>
                    <Coin>1</Coin>
                    <GuideTitle>
                        Дата и время <span>начала сессии</span>
                    </GuideTitle>
                </GuideTitleBlock>

                <GuideInnerBlock>
                    <Caption sx={{ textAlign: 'left' }}>
                        Начиная с этой даты твоя команда будет отслеживать способы поведения в рамках следующей сессии
                    </Caption>
                </GuideInnerBlock>
            </GuideBlock>
            <GuideBlock>
                <GuideTitleBlock>
                    <Coin>2</Coin>
                    <GuideTitle>
                        Дата и время <span>конца сессии</span>
                    </GuideTitle>
                </GuideTitleBlock>

                <GuideInnerBlock>
                    <Caption sx={{ textAlign: 'left' }}>
                        До этой даты включительно сессия будет активна, и коллеги смогут оценивать друг друга по
                        выбранным способам поведения
                    </Caption>
                </GuideInnerBlock>
            </GuideBlock>
            <GuideBlock>
                <GuideTitleBlock>
                    <Coin>3</Coin>
                    <GuideTitle>Ближайшая встреча</GuideTitle>
                </GuideTitleBlock>

                <GuideInnerBlock>
                    <Caption sx={{ textAlign: 'left' }}>
                        Дата и время встречи с коллегами для обсуждения результатов или вопросов по текущей сессии
                    </Caption>
                </GuideInnerBlock>
            </GuideBlock>
            <GuideBlock>
                <GuideTitleBlock>
                    <Coin>4</Coin>
                    <GuideTitle>Следующая встреча</GuideTitle>
                </GuideTitleBlock>

                <GuideInnerBlock>
                    <Caption sx={{ textAlign: 'left' }}>
                        Дата и время встречи с коллегами для обсуждения результатов или вопросов по текущей сессии
                    </Caption>
                </GuideInnerBlock>
            </GuideBlock>
            <GuideBlock>
                <GuideTitleBlock>
                    <Coin>5</Coin>
                    <GuideTitle>Ссылка на комнату</GuideTitle>
                </GuideTitleBlock>

                <GuideInnerBlock>
                    <Caption sx={{ textAlign: 'left' }}>
                        В этом поле можно разместить ссылку на конференцию, к которой все будут подключаться для
                        ежедневного созвона по сессии
                    </Caption>
                </GuideInnerBlock>
            </GuideBlock>
        </GuideContainer>
    );
};
