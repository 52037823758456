import { atom, selector } from 'recoil';
import { getTeamMembers } from '../../api/search';

const teamMembersSelector = selector({
    key: 'teamMembersSelector',
    get: async () => {
        try {
            const userIdForAdminPanel = localStorage.getItem('user-id-for-admin-panel');
            const teamMembers = await getTeamMembers(userIdForAdminPanel || null);
            return teamMembers.data.map((teamMember) => ({
                ...teamMember,
                name: `${teamMember.first_name} ${teamMember.last_name[0]}.`,
            }));
        } catch {}
    },
});

export const teamMembersAtom = atom({
    key: 'teamMembersAtom',
    default: teamMembersSelector,
});

export const teamMembersForAdminPanelAtom = atom({
    key: 'teamMembersForAdminPanelAtom',
    default: [],
});
